import React, { useState, useContext } from 'react';
import { UserContext } from './UserContext';
import { styled } from '@mui/system';
import { Avatar, Button, TextField, Grid, Typography, Paper } from '@mui/material';
import { LockOutlined } from '@mui/icons-material';
import { useNavigate, Link } from 'react-router-dom';
import ApiUrl from './ApiConfig';

import logo from './images/logo_white_large_p2c.png';

const Background = styled('div')({
  backgroundColor: '#DAECF3',
  height: '100vh',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  maxWidth: '400px',  // Set a max-width for the form
  width: '100%',  // Ensure it takes full width on smaller screens
  elevation: 3,
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2),
  },
}));

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  margin: theme.spacing(1),
  backgroundColor: theme.palette.secondary.main,
}));

const Form = styled('form')(({ theme }) => ({
  width: '100%',
  marginTop: theme.spacing(3),
}));

const SubmitButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(3, 0, 2),
  backgroundColor: "#FE424D",
  color: "white",
  '&:hover': {
    backgroundColor: "#022D41",
  },
}));

const DisclaimerText = styled('p')(({ theme }) => ({
  textAlign: 'center',
  fontSize: '0.95rem',
  color: '#888888',
  fontFamily: 'Helvetica Neue, sans-serif',
  margin: theme.spacing(2, 0, 1, 0),
  paddingLeft: '15%',
  paddingRight: '15%',
}));

const StyledLink = styled('a')({
  color: '#000000',
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'underline',
  }
});

function SignIn() {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { setUser } = useContext(UserContext);

  const handleLogin = () => {
    fetch(`${ApiUrl}/api/login`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, password })
    })
    .then(res => res.json())
    .then(data => {
        if (data.access_token) {
            const decodedToken = decodeJwtToken(data.access_token);
            const currentTimestamp = Math.floor(Date.now() / 1000);
            if (decodedToken.exp >= currentTimestamp) {
                localStorage.setItem('token', data.access_token);
                localStorage.setItem('first_name', data.first_name);
                localStorage.setItem('last_name', data.last_name);
                setUser(data.access_token);
                navigate('/dashboard');
            } else {
                alert('Login failed: Token expired. Please try again.');
            }
        } else {
            alert('Login failed. Please try again.');
        }
    });
  };

  const decodeJwtToken = (token) => {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map(c => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
        .join('')
    );
    return JSON.parse(jsonPayload);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    handleLogin();
  };

  return (
    <Background>
      <StyledPaper>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <img src={logo} alt="Logo" style={{ width: '100%', maxWidth: '300px', height: 'auto' }} /> {/* Responsive logo */}
          <Typography component="h1" variant="h5" style={{ marginTop: '20px', textAlign: 'center' }}>
            Sign in
          </Typography>
        </div>
        <Form onSubmit={handleSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={e => setEmail(e.target.value)}
            InputLabelProps={{
              sx: {
                '&.Mui-focused': {
                  color: '#FE424D',
                },
              },
            }}
            sx={{
              '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: '#FE424D',
              },
            }}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={e => setPassword(e.target.value)}
            InputLabelProps={{
              sx: {
                '&.Mui-focused': {
                  color: '#FE424D',
                },
              },
            }}
            sx={{
              '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: '#FE424D',
              },
            }}
          />

          <SubmitButton
            type="submit"
            fullWidth
            variant="contained"
          >
            Sign In
          </SubmitButton>

          <Grid container>
            <Grid item xs>
              <Link
                to="/forgotten-password"
                variant="body2"
                style={{
                  fontFamily: 'sans-serif',
                  color: 'black',
                  textDecoration: 'none',
                  '&:hover': {
                    textDecoration: 'underline',
                  },
                }}
              >
                {"Forgot password?"}
              </Link>
            </Grid>
            <Grid item>
              <a
                href="https://app.powertocompare.ie/signup/"
                variant="body2"
                style={{
                  fontFamily: 'sans-serif',
                  color: 'black',
                  textDecoration: 'none',
                  '&:hover': {
                    textDecoration: 'underline',
                  },
                }}
              >
                {"Want to try? Register for free."}
              </a>
            </Grid>
          </Grid>

          <DisclaimerText>
            By continuing, you agree to the{" "}
            <StyledLink href="https://powertocompare.ie/terms-of-use/" target="_blank" rel="noopener noreferrer">Terms of Use</StyledLink>{" "} 
            and confirm that you have read the{" "}
            <StyledLink href="https://powertocompare.ie/privacy-policy/" target="_blank" rel="noopener noreferrer">Privacy Policy</StyledLink>.
          </DisclaimerText>
        </Form>
      </StyledPaper>
    </Background>
  )
}

export default SignIn;
