import React, { useEffect, useState } from "react";
import {
  BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer
} from "recharts";

/* LOGO IMPORTS */
import BordGaisEnergyLogo from './images/bordgais_50.png';
import FlogasLogo from './images/flogas_50.png';
import SSEAirtricityLogo from './images/sseairtricity_50.png';
import ElectricIrelandLogo from './images/electricireland_50.png';
import EnergiaLogo from './images/energia_50.png';
import PinergyLogo from './images/pinergy_50.png';
import PrepayPowerLogo from './images/prepaypower_50.png';
import EcoPowerLogo from './images/ecopower_50.png';
import YunoEnergyLogo from './images/yunoenergy_50.png';
import CommunityPowerLogo from './images/communitypower_50.png';

function BarChartEVCosts({
  data,
  currentPlan,
  currentSupplier,
  smartTariffs,
  selectedGraphComparisonTab,
}) {
  
  const [containerHeight, setContainerHeight] = useState(
    selectedGraphComparisonTab === 0 ? 1000 : 1300
  );
  
  useEffect(() => {
    // Adjust the container height if the selectedGraphComparisonTab changes
    setContainerHeight(selectedGraphComparisonTab === 0 ? 1000 : 1300);
  }, [selectedGraphComparisonTab]);

  const [sortedChartData, setSortedChartData] = useState([]);

  const supplierLogos = {
    Flogas: FlogasLogo,
    "SSE Airtricity": SSEAirtricityLogo,
    "Electric Ireland": ElectricIrelandLogo,
    Energia: EnergiaLogo,
    Pinergy: PinergyLogo,
    Ecopower: EcoPowerLogo,
    "Community Power": CommunityPowerLogo,
    "Bord Gais Energy": BordGaisEnergyLogo,
    "Prepay Power": PrepayPowerLogo,
    "Yuno Energy": YunoEnergyLogo,
  };

  const getColumnIndex = (rawData, columnName) => {
    return rawData.columns.indexOf(columnName);
  }

  useEffect(() => {
    const chartData = data.values.map(entry => {
      const supplier = entry[getColumnIndex(data, 'Supplier')];
      const tariff = entry[getColumnIndex(data, 'Tariff')];
      const evCostRaw = entry[getColumnIndex(data, 'EvCost')];
      const evCostFormatted = parseFloat(evCostRaw).toFixed(2);

      return {
          supplier,
          tariff,
          evCost: parseFloat(evCostRaw),
          tooltipLabel: `${supplier} - ${tariff}`,
          displayEvCost: evCostFormatted, // for displaying purposes
      };
    });

    const smartChartData = smartTariffs
      ? chartData.filter(({ supplier, tariff }) =>
        smartTariffs.some(t => t.Supplier === supplier && t.Tariff === tariff) ||
        (supplier === currentSupplier && tariff === currentPlan)
      )
      : chartData;

    setSortedChartData(smartChartData.sort((a, b) => b.evCost - a.evCost));
  }, [data, smartTariffs, currentSupplier, currentPlan]);

  const CustomTick = ({ x, y, payload }) => {
    const supplierTariff = payload.value.split(" - ");
    const supplierName = supplierTariff[0];
    const isCurrentPlan = payload.value === `${currentSupplier} - ${currentPlan}`;

    const logo = supplierLogos[supplierName];
    const imageWidth = 50;

    return (
      <g transform={`translate(${x},${y})`}>
        {logo && (
          <image
            x={0 - imageWidth - 0}
            y={0 - 15}
            width={imageWidth}
            height="30"
            xlinkHref={logo}
          />
        )}
        <text
          x={logo ? -20 - imageWidth : 0}
          y={0}
          dy={3}
          textAnchor="end"
          fill={isCurrentPlan ? "black" : "grey"}
          fontWeight={isCurrentPlan ? "bold" : "normal"}
        >
          {payload.value}
        </text>
      </g>
    );
  };

  const tooltipContent = ({ payload }) => payload?.[0]?.payload && (
    <div style={{ backgroundColor: "white", padding: "5px" }}>
      <table>
        <tbody>
          <tr>
            <td style={{ fontWeight: "bold", fontSize: "1.2em" }}>
              {payload[0].payload.tariff} - {payload[0].payload.supplier}
            </td>
          </tr>
          <tr><td colSpan={2}><hr /></td></tr>
          <tr>
            <td>EV Cost:</td>
            <td>€ {payload[0].payload.displayEvCost}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );

  return sortedChartData.length ? (
    <div style={{ width: "100%", height: containerHeight }}>
      <ResponsiveContainer>
        <BarChart
          data={sortedChartData}
          margin={{ top: 5, right: 30, left: 40, bottom: 60 }}
          layout="vertical"
        >
          <CartesianGrid strokeDasharray="3 3" horizontal={false} />
          <YAxis dataKey="tooltipLabel" type="category" width={450} tick={CustomTick} />
          <XAxis type="number" domain={[0, "auto"]} />
          <Tooltip
            formatter={(value, name) => [value, name]}
            labelFormatter={() => ""}
            cursor={{ fill: "rgba(0, 0, 0, 0.2)" }}
            contentStyle={{ backgroundColor: "#f0f0f0", borderRadius: "5px" }}
            content={tooltipContent}
          />
          <Bar dataKey="evCost" name="EV Cost" barSize={40}>
            {sortedChartData.map(({ supplier, tariff, evCost }, index) => (
              <Cell
                key={`cell-${index}`}
                fill={supplier === currentSupplier && tariff === currentPlan
                  ? "#8884d8"
                  : evCost >= 0
                    ? "#1AA6B7"
                    : "#FE424D"}
              />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  ) : null;
}

export default BarChartEVCosts;
