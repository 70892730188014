import React, { useEffect } from 'react';
import {
  Box,
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import BlockIcon from '@mui/icons-material/Block';

// Import supplier logos
import BordGaisEnergyLogo from './images/bordgais_50.png';
import FlogasLogo from './images/flogas_50.png';
import SSEAirtricityLogo from './images/sseairtricity_50.png';
import ElectricIrelandLogo from './images/electricireland_50.png';
import EnergiaLogo from './images/energia_50.png';
import PinergyLogo from './images/pinergy_50.png';
import PrepayPowerLogo from './images/prepaypower_50.png';
import EcoPowerLogo from './images/ecopower_50.png';
import YunoEnergyLogo from './images/yunoenergy_50.png';
import CommunityPowerLogo from './images/communitypower_50.png';

function ComparisonTableMobile({ data, dfSelectedPlan, smartTariffs, reportRef, hasEV, energyExported }) {
  const columns = data.columns;
  const supplierColumnIndex = columns.indexOf('Supplier');
  const tariffColumnIndex = columns.indexOf('Tariff');

  // Debug logging for checking the incoming props
  console.log("ComparisonTableMobile hasEV:", hasEV);
  console.log("ComparisonTableMobile energyExported:", energyExported);
  console.log("ComparisonTableMobile columns:", columns);

  // Determine the correct column index for savings based on EV and Export data presence
  const savingsColumnIndex = energyExported 
    ? columns.indexOf("Total savings including export payments") 
    : hasEV 
      ? columns.indexOf("Total savings including simulated EV charging") 
      : columns.indexOf("Total savings based on your smart meter usage data");

  // Debug logging to verify which column is selected for total savings
  console.log("Selected Savings Column Index:", savingsColumnIndex);
  console.log("Selected Savings Column Name:", columns[savingsColumnIndex]);

  // Mapping suppliers to logos
  const supplierLogos = {
    'Bord Gais Energy': BordGaisEnergyLogo,
    'Flogas': FlogasLogo,
    'SSE Airtricity': SSEAirtricityLogo,
    'Electric Ireland': ElectricIrelandLogo,
    'Energia': EnergiaLogo,
    'Pinergy': PinergyLogo,
    'Prepay Power': PrepayPowerLogo,
    'Ecopower': EcoPowerLogo,
    'Yuno Energy': YunoEnergyLogo,
    'Community Power': CommunityPowerLogo,
  };

  const sortedData = [...data.values].sort((a, b) => parseFloat(b[savingsColumnIndex]) - parseFloat(a[savingsColumnIndex]));

  useEffect(() => {
    // Log the sorted data for debugging purposes
    console.log("Sorted Data:", sortedData);
  }, [sortedData]);

  return (
    <>
      <div ref={reportRef} style={{ height: '1px', paddingBottom: "10px" }} />
      <Box sx={{ width: '100%', marginBottom: '20px', paddingTop: '20px', backgroundColor: '#DAECF3' }}>
        <Card style={{ marginTop: '20px', backgroundColor: '#DAECF3' }}>
          <CardContent>
            <Typography variant="h5" component="h1" style={{ marginBottom: '20px', display: 'flex', alignItems: 'center', fontSize: '1.0em' }}>
              Price Comparison with your Current Plan
            </Typography>
            <TableContainer sx={{ overflowX: 'auto' }}>
              <Table size="small">
                <TableHead>
                  <TableRow style={{ backgroundColor: '#1AA6B7' }}>
                    <TableCell style={{ fontWeight: 'bold', color: 'white', padding: '6px' }}>Smart</TableCell>
                    <TableCell style={{ fontWeight: 'bold', color: 'white', padding: '6px' }}>Supplier</TableCell>
                    <TableCell style={{ fontWeight: 'bold', color: 'white', padding: '6px' }}>Tariff</TableCell>
                    <TableCell style={{ fontWeight: 'bold', color: 'white', padding: '6px' }}>Total Savings</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sortedData.map((row, index) => (
                    <TableRow key={index} style={{
                      backgroundColor: dfSelectedPlan.selected_supplier === row[supplierColumnIndex] && dfSelectedPlan.selected_plan === row[tariffColumnIndex] ? '#FFFF99' : index % 2 ? '#E8F1F7' : 'none'
                    }}>
                      <TableCell style={{ textAlign: 'center', padding: '6px' }}>
                        {smartTariffs.some(tariff => tariff.Supplier === row[supplierColumnIndex] && tariff.Tariff === row[tariffColumnIndex]) ? <CheckCircleIcon style={{ color: 'green' }} /> : <BlockIcon color="disabled" />}
                      </TableCell>
                      <TableCell style={{ padding: '6px' }}>
                        <img src={supplierLogos[row[supplierColumnIndex]] || ""} alt={row[supplierColumnIndex]} style={{ height: '30px' }} />
                      </TableCell>
                      <TableCell style={{ padding: '6px' }}>{row[tariffColumnIndex]}</TableCell>
                      <TableCell style={{ padding: '6px' }}>{parseFloat(row[savingsColumnIndex]).toFixed(2)}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
        </Card>
      </Box>
    </>
  );
}

export default ComparisonTableMobile;
