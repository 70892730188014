import React, { createContext, useState, useEffect } from 'react';
import { jwtDecode } from 'jwt-decode';

export const UserContext = createContext(null);

export function UserContextProvider({ children }) {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    console.log('UserContext.js: Initializing user context...');
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        const currentTimestamp = Math.floor(Date.now() / 1000);
        if (decodedToken.exp >= currentTimestamp) {
          console.log('UserContext.js: Token valid, setting user context');
          setUser(decodedToken); // Set user with decoded token data
        } else {
          console.log('UserContext.js: Token expired');
          localStorage.removeItem('token'); // Clear expired token
        }
      } catch (e) {
        console.error("UserContext.js: Error decoding token:", e);
        localStorage.removeItem('token'); // Clear invalid token
      }
    }
    setLoading(false); // Set loading to false once processing is complete
  }, []);

  return (
    <UserContext.Provider value={{ user, setUser, loading }}>
      {children}
    </UserContext.Provider>
  );
}
