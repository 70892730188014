import React, { useState } from 'react';
import { Button, TextField, Typography } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

function InviteForm({ apiUrl, onAllInvitesSent, senderName, numberOfPlans, savingsPerYear }) {
  const [invites, setInvites] = useState([
    { email: '', name: '', sent: false, error: false },
    { email: '', name: '', sent: false, error: false },
    { email: '', name: '', sent: false, error: false }
  ]);

  const handleSendInvite = async (index) => {
    const currentInvite = invites[index];
    if (!currentInvite.email || !currentInvite.name) {
      const newInvites = [...invites];
      newInvites[index].error = true;
      setInvites(newInvites);
      return;
    }

    try {
      // Retrieve the JWT token from localStorage
      const token = localStorage.getItem('token');

      const response = await fetch(`${apiUrl}/api/send-invite`, {
        method: 'POST',
        headers: { 
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          email: currentInvite.email,
          name: currentInvite.name,
          senderName,
          numberOfPlans,
          savingsPerYear
        }),
      });

      const data = await response.json();
      if (response.ok) {
        const newInvites = [...invites];
        newInvites[index].sent = true;
        newInvites[index].error = false;
        setInvites(newInvites);
        checkAllInvitesSent(newInvites);
      } else {
        throw new Error(data.message || 'Failed to send invite');
      }
    } catch (error) {
      console.error(error);
      const newInvites = [...invites];
      newInvites[index].error = true;
      setInvites(newInvites);
    }
  };

  const checkAllInvitesSent = (invites) => {
    const allSent = invites.every(invite => invite.sent);
    if (allSent) {
      onAllInvitesSent(true);
    }
  };

  const handleChange = (index, field, value) => {
    const newInvites = [...invites];
    newInvites[index][field] = value;
    setInvites(newInvites);
  };

  return (
    <div style={{ maxWidth: '100%', margin: 'auto' }}>
      {invites.map((invite, index) => (
        <div key={index} style={{
          display: 'flex',
          flexDirection: 'column', // Always use column layout for mobile-friendly design
          alignItems: 'stretch', // Align items to stretch to full width
          marginBottom: 10
        }}>
          <TextField
            label="Name"
            value={invite.name}
            onChange={(e) => handleChange(index, 'name', e.target.value)}
            disabled={invite.sent}
            fullWidth // Ensure the input takes full width
            style={{ marginBottom: 10 }}
          />
          <TextField
            label="Email"
            value={invite.email}
            onChange={(e) => handleChange(index, 'email', e.target.value)}
            disabled={invite.sent}
            fullWidth // Ensure the input takes full width
            style={{ marginBottom: 10 }}
          />
          {invite.sent ? (
            <CheckCircleOutlineIcon color="success" style={{ height: '36px', display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: 10 }} />
          ) : (
            <Button
              onClick={() => handleSendInvite(index)}
              variant="contained"
              color="primary"
              style={{ backgroundColor: "#FE424D", color: "#FFFFFF", height: '36px' }}
              fullWidth // Button takes full width
            >
              Send Invite
            </Button>
          )}
          {invite.error && <ErrorOutlineIcon color="error" style={{ marginLeft: 10 }} />}
        </div>
      ))}
    </div>
  );
}

export default InviteForm;
