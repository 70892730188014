import React, { useState } from 'react';
import { styled } from '@mui/system';
import { Avatar, Button, Box, TextField, Grid, Typography, MenuItem, Container, Checkbox, FormControlLabel } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import logo from './images/title4.png'; // Adjust this image path if needed
import ReCAPTCHA from "react-google-recaptcha";
import ApiUrl from "./ApiConfig";

const SITE_KEY = "6LcYRAcqAAAAAFBVPiDqGAUaVYG6RYZKZq4xove7";

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
    },
  },
}));

const Paper = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(8),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));

const Form = styled('form')(({ theme }) => ({
  width: '100%',
  marginTop: theme.spacing(1),
}));

const SubmitButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(3, 0, 2),
}));

const DisclaimerText = styled('p')(({ theme }) => ({
  textAlign: 'center',
  fontSize: '0.95rem',
  color: '#888888',
  fontFamily: 'Helvetica Neue, sans-serif',
  margin: theme.spacing(0.5, 0, 0.5, 0),
  paddingLeft: '15%',
  paddingRight: '15%',
}));

const StyledLink = styled('a')({
  color: '#000000',
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'underline',
  },
});

const PasswordRule = styled('div')(({ theme, valid }) => ({
  display: 'flex',
  alignItems: 'center',
  color: valid ? 'green' : 'grey',
  marginTop: theme.spacing(1),
}));

const counties = [
  'Carlow', 'Cavan', 'Clare', 'Cork', 'Donegal', 'Dublin', 'Galway', 'Kerry', 'Kildare',
  'Kilkenny', 'Laois', 'Leitrim', 'Limerick', 'Longford', 'Louth', 'Mayo', 'Meath',
  'Monaghan', 'Offaly', 'Roscommon', 'Sligo', 'Tipperary', 'Waterford', 'Westmeath',
  'Wexford', 'Wicklow',
];

function Signup() {
  const navigate = useNavigate();
  const classes = useStyles();

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [county, setCounty] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [captchaResponse, setCaptchaResponse] = useState(null);
  const [receiveNewsletter, setReceiveNewsletter] = useState(false);

  const [passwordRules, setPasswordRules] = useState({
    length: false,
    numbersAndLetters: false,
    uppercase: false,
  });

  const handleSignup = () => {
    if (!captchaResponse) {
      alert("Please complete the CAPTCHA.");
      return;
    }

    if (!passwordRules.length || !passwordRules.numbersAndLetters || !passwordRules.uppercase) {
      alert("Password does not meet complexity requirements.");
      return;
    }

    fetch(`${ApiUrl}/api/signup`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        firstName, lastName, county, email, password, confirmPassword, captchaResponse, receiveNewsletter
      }),
    })
      .then(res => res.json())
      .then(data => {
        if (data.status === 'success') {
          navigate(`/survey/${data.user_id}`);
        } else {
          alert('Signup failed. Please try again.');
        }
      });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    handleSignup();
  };

  const handleCaptcha = (value) => {
    setCaptchaResponse(value);
  };

  const handlePasswordChange = (e) => {
    const { value } = e.target;
    setPassword(value);

    setPasswordRules({
      length: value.length > 8,
      numbersAndLetters: /[a-zA-Z]/.test(value) && /[0-9]/.test(value),
      uppercase: /[A-Z]/.test(value),
    });
  };

  const handleNewsletterChange = (e) => {
    setReceiveNewsletter(e.target.checked);
  };

  return (
    <>
      <header className="header">
        <div className="logo-container" style={{ display: "flex", justifyContent: "center" }}>
        <img
  src={logo}
  alt="Logo"
  className="logo"
  style={{
    width: '80%',  // Adjust width to 80% for better responsiveness
    height: 'auto',  // Auto height to maintain aspect ratio
    maxWidth: '300px',  // Limit the max width for larger screens
    maxHeight: '80px',  // Set the maximum height
  }}
/>

        </div>
      </header>
      <Container component="main" maxWidth="sm" style={{ marginBottom: "20px", backgroundColor: "#DAECF3" }}>
      <Paper elevation={6} style={{ marginTop: '10px', padding: '20px' }}>

          <Typography component="h1" variant="h4" marginTop={3}>
            Sign up for free
          </Typography>
          <Typography component="h1" variant="h6" marginTop="20px" marginBottom="20px">
            Welcome to the Beta version of PowerToCompare.ie.
          </Typography>

          <Form onSubmit={handleSubmit} className={classes.root}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  name="firstName"
                  variant="outlined"
                  required
                  fullWidth
                  id="firstName"
                  label="First Name"
                  autoFocus
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  className={classes.root}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="lastName"
                  variant="outlined"
                  required
                  fullWidth
                  id="lastName"
                  label="Last Name"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  className={classes.root}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="county"
                  variant="outlined"
                  select
                  required
                  fullWidth
                  id="county"
                  label="County"
                  value={county}
                  onChange={(e) => setCounty(e.target.value)}
                >
                  {counties.map((county) => (
                    <MenuItem key={county} value={county}>
                      {county}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="email"
                  variant="outlined"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  autoComplete="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className={classes.root}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="password"
                  variant="outlined"
                  required
                  fullWidth
                  id="password"
                  label="Password"
                  type="password"
                  autoComplete="new-password"
                  value={password}
                  onChange={handlePasswordChange}
                  className={classes.root}
                />
                <PasswordRule valid={passwordRules.length}>
                  {passwordRules.length ? <CheckCircleIcon /> : <CancelIcon />} 
                  &nbsp;Must be more than 8 characters long.
                </PasswordRule>
                <PasswordRule valid={passwordRules.numbersAndLetters}>
                  {passwordRules.numbersAndLetters ? <CheckCircleIcon /> : <CancelIcon />} 
                  &nbsp;Must be a combination of both numbers and letters.
                </PasswordRule>
                <PasswordRule valid={passwordRules.uppercase}>
                  {passwordRules.uppercase ? <CheckCircleIcon /> : <CancelIcon />} 
                  &nbsp;Must have at least one uppercase letter.
                </PasswordRule>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="confirmPassword"
                  variant="outlined"
                  required
                  fullWidth
                  id="confirmPassword"
                  label="Confirm Password"
                  type="password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  className={classes.root}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={receiveNewsletter}
                      onChange={handleNewsletterChange}
                      color="primary"
                    />
                  }
                  label="I want to receive newsletter and promotions via email."
                />
              </Grid>
              <Grid item xs={12}>
                <ReCAPTCHA
                  sitekey={SITE_KEY}
                  onChange={handleCaptcha}
                />
              </Grid>
              <Grid item xs={12}>
                <SubmitButton
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                >
                  Sign Up
                </SubmitButton>
              </Grid>
              <Grid container justifyContent="flex-end">
                <Grid item>
                  <Typography variant="body2" color="textSecondary">
                    Already have an account?{' '}
                    <StyledLink href="/login">
                      Sign in
                    </StyledLink>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Form>
          <DisclaimerText>
            By signing up, you agree to our&nbsp;
            <StyledLink href="/terms">Terms of Service</StyledLink>&nbsp;and&nbsp;
            <StyledLink href="/privacy">Privacy Policy</StyledLink>.
          </DisclaimerText>
        </Paper>
      </Container>
    </>
  );
}

export default Signup;

