// LineChartWeekPriceSchedule.js
import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

// Custom tooltip formatting function
const customTooltip = (value) => value.toFixed(2);

// Function to format the day of the week based on the index of the data point
const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
// Function to format the x-axis labels to show the day of the week
const formatXAxis = (tickItem, index) => {
  // There are 48 half-hour periods in a day, so index 23 would be the middle of the first day
  // and every subsequent 48 ticks is the middle of the next day.
  if (index % 48 === 23) {
    const dayIndex = Math.floor(index / 48);
    return days[dayIndex % 7];
  }
  return '';
};

const tickPositions = [];
for (let i = 23; i < 7 * 48; i += 48) {
  tickPositions.push(i); // Add the index position for the middle of each day
}

const LineChartWeekPriceSchedule = ({ data }) => {
  // Log the data prop to the console for debugging
  console.log('LineChartWeekPriceSchedule data:', data);

  if (data && Array.isArray(data) && data.length > 0) {
    return (
      <ResponsiveContainer width="100%" height={450}>
        <LineChart data={data} margin={{ top: 5, right: 50, bottom: 20, left: 30 }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="Time"
            tickFormatter={formatXAxis} // Pass the tickFormatter function
            interval={0} // Ensure that every tick is rendered so the formatter can decide which to label
            label={{ value: 'Day of the week', position: 'insideBottom', offset: -10 }}
          />
          <YAxis yAxisId="left" orientation="left" stroke="#FF0000"
            label={{ value: 'Price (cents)', angle: -90, position: 'insideLeft', fill: '#FF0000', offset: 20, dy: 40 }} />
          <YAxis yAxisId="right" orientation="right" stroke="rgb(26, 166, 183)"
            label={{ value: 'Energy used (kWh)', angle: -90, position: 'insideLeft', fill: 'rgb(26, 166, 183)', offset: 50, dy: 140 }} />
          <Tooltip formatter={customTooltip} labelFormatter={(label) => `Day: ${label}`} />

          <Line yAxisId="left" type="monotone" dataKey="Price" stroke="#FF0000" activeDot={{ r: 8 }} dot={false} />
          <Line yAxisId="right" type="monotone" dataKey="Energy" stroke="rgb(26, 166, 183)" activeDot={{ r: 8 }} dot={false} />
        </LineChart>
      </ResponsiveContainer>
    );
  }

  return <div>No data available</div>;
};

export default LineChartWeekPriceSchedule;
