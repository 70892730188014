import React, { useState, useEffect } from 'react';
import {
  Typography,
  Button,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Grid,
  OutlinedInput
} from '@mui/material';

function PropertyDetails({ ApiUrl, token }) {
  // Initialize state for each form field
  const [propertyType, setPropertyType] = useState("");
  const [propertyLocation, setPropertyLocation] = useState("");
  const [homeType, setHomeType] = useState("");
  const [homeSize, setHomeSize] = useState("");
  const [homeBER, setHomeBER] = useState("");
  const [occupants, setOccupants] = useState("");
  const [heatingType, setHeatingType] = useState("");
  const [solar, setSolar] = useState("");
  const [homeBattery, setHomeBattery] = useState("");
  const [electricVehicle, setElectricVehicle] = useState("");
  const [workFromHome, setWorkFromHome] = useState("");
  const [workFromHomeCount, setWorkFromHomeCount] = useState("");
  // Add any additional states you need for the rest of your survey fields

  const [saveSuccessful, setSaveSuccessful] = useState(false);


  // Fetch property details from the backend on component mount
  if (token) {
    useEffect(() => {
      const fetchPropertyDetails = async () => {
        const response = await fetch(`${ApiUrl}/api/get-meter-data`, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });
        if (response.ok) {
          const data = await response.json();

          // console.log("Received data:", data);
          // Populate the state with the data fetched
          setPropertyType(data.hasOwnProperty('property_type') ? data.property_type : '');
          setPropertyLocation(data.hasOwnProperty('property_location') ? data.property_location : '');
          setHomeType(data.hasOwnProperty('home_type') ? data.home_type : '');
          setHomeSize(data.hasOwnProperty('home_size') ? data.home_size : '');
          setHomeBER(data.hasOwnProperty('home_BER') ? data.home_BER : '');
          setOccupants(data.hasOwnProperty('occupants') ? data.occupants : '');
          setHeatingType(data.hasOwnProperty('heating_type') ? data.heating_type : '');
          setSolar(data.hasOwnProperty('solar') ? data.solar : '');
          setHomeBattery(data.hasOwnProperty('home_battery') ? data.home_battery : '');
          setElectricVehicle(data.hasOwnProperty('electric_vehicle') ? data.electric_vehicle : '');
          setWorkFromHome(data.hasOwnProperty('work_from_home') ? data.work_from_home : '');
          setWorkFromHomeCount(data.hasOwnProperty('work_from_home_count') ? data.work_from_home_count : '');
          // Set any additional states for the rest of your survey fields

          
        } else {
          console.error('Failed to fetch property details');
        }
      };

      fetchPropertyDetails();
    }, [ApiUrl, token]);
  }

  if (!token) {
    // Handle the case where there is no token. Maybe redirect to login page?
    console.log("No token found. User should be logged in first.");
    // return <Redirect to="/login" /> or some other error handling
  }

  const handleSave = async () => {
    const payload = {
      property_type: propertyType,
      property_location: propertyLocation,
      home_type: homeType,
      home_size: homeSize,
      home_BER: homeBER,
      occupants: occupants,
      heating_type: heatingType,
      solar: solar,
      home_battery: homeBattery,
      electric_vehicle: electricVehicle,
      work_from_home: workFromHome,
      work_from_home_count: workFromHomeCount
      // Include any other fields you need to update
    };

    try {
      const response = await fetch(`${ApiUrl}/api/update-meter-data`, { // Adjust the endpoint as necessary
        method: 'PUT', // or 'POST', depending on your API setup
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`, // Ensure you're sending the token
        },
        body: JSON.stringify(payload)
      });

      if (response.ok) {
        console.log("Update successful");
        // Optionally update UI or notify user
        setSaveSuccessful(true);

        setTimeout(() => setSaveSuccessful(false), 3000);
      } else {
        throw new Error('Failed to update meter data');
      }
    } catch (error) {
      console.error('Error updating meter data:', error);
      // Optionally handle errors in UI
    }
  };


  return (
    <Grid container spacing={2} style={{ marginTop: '20px', marginBottom: '20px' }}>
      {/* Property Type Dropdown */}
      <Grid item xs={12}>
        <FormControl fullWidth>
          <InputLabel id="propertyType-label">Property Type</InputLabel>
          <Select
            labelId="propertyType-label"
            id="propertyType"
            value={propertyType}
            onChange={(e) => setPropertyType(e.target.value)}
            label="Property Type"
            style={{ maxWidth: "600px" }}
          >
            <MenuItem value="domestic">Domestic</MenuItem>
            <MenuItem value="commercial">Commercial</MenuItem>
          </Select>
        </FormControl>
      </Grid>

      {/* Property Location Dropdown */}
      <Grid item xs={12}>
        <FormControl fullWidth>
          <InputLabel id="propertyLocation-label">Property Location</InputLabel>
          <Select
            labelId="propertyLocation-label"
            id="propertyLocation"
            value={propertyLocation}
            onChange={(e) => setPropertyLocation(e.target.value)}
            label="Property Location"
            style={{ maxWidth: "600px" }}
          >
            <MenuItem value="urban">Urban</MenuItem>
            <MenuItem value="rural">Rural</MenuItem>
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={12}>
        <FormControl fullWidth variant="outlined">
          <InputLabel id="homeType-label">Home Type</InputLabel>
          <Select
            labelId="homeType-label"
            id="homeType"
            value={homeType}
            onChange={(e) => setHomeType(e.target.value)}
            label="Home Type"
            style={{ maxWidth: "600px" }}
          >
            <MenuItem value="detachedBungalow">Detached Bungalow</MenuItem>
            <MenuItem value="detached2Storey">Detached 2 Storey</MenuItem>
            <MenuItem value="semiD">Semi-D</MenuItem>
            <MenuItem value="townhouse">Townhouse</MenuItem>
            <MenuItem value="apartment">Apartment</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth variant="outlined">
          <InputLabel id="homeSize-label">Home Size</InputLabel>
          <Select
            labelId="homeSize-label"
            id="homeSize"
            value={homeSize}
            onChange={(e) => setHomeSize(e.target.value)}
            label="Home Size"
            style={{ maxWidth: "600px" }}
          >
            <MenuItem value="<120">{'< 120 sqM'}</MenuItem>
            <MenuItem value="120-180">120 - 180</MenuItem>
            <MenuItem value="180-250">180 - 250</MenuItem>
            <MenuItem value="250+">250 +</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth variant="outlined">
          <InputLabel id="homeBER-label">Please select the BER rating from the list.</InputLabel>
          <Select
            labelId="homeBER-label"
            id="homeBER"
            value={homeBER}
            onChange={(e) => setHomeBER(e.target.value)}
            label="Home BER Rating"
            style={{ maxWidth: "600px" }}
          >
            <MenuItem value="A">A</MenuItem>
            <MenuItem value="B">B</MenuItem>
            <MenuItem value="C">C</MenuItem>
            <MenuItem value="D">D</MenuItem>
            <MenuItem value="E">E</MenuItem>
            <MenuItem value="F">F</MenuItem>
            <MenuItem value="G">G</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth variant="outlined">
          <InputLabel id="homeOccupants-label">How many occupants?</InputLabel>
          <Select
            labelId="homeOccupants-label"
            value={occupants}
            onChange={(e) => setOccupants(e.target.value)}
            displayEmpty
            input={<OutlinedInput label="How many occupants?" />}
            style={{ maxWidth: "600px" }}
          >
            <MenuItem value="1">1</MenuItem>
            <MenuItem value="2">2</MenuItem>
            <MenuItem value="3">3</MenuItem>
            <MenuItem value="4">4</MenuItem>
            <MenuItem value="5">5</MenuItem>
            <MenuItem value="6+">6+</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth variant="outlined">
          <InputLabel id="homeHeating-label">What is the propety heating type?</InputLabel>
          <Select
            labelId="homeHeating-label"
            value={heatingType}
            onChange={(e) => setHeatingType(e.target.value)}
            displayEmpty
            input={<OutlinedInput label="What is the property heating type?" />}
            style={{ maxWidth: "600px" }}
          >
            <MenuItem value="oilGas">Oil / Gas</MenuItem>
            <MenuItem value="heatPump">Heat pump (Geothermal / Air to Water)</MenuItem>
            <MenuItem value="woodStove">Wood stove</MenuItem>
            <MenuItem value="woodPelletStove">Wood pellet stove</MenuItem>
            <MenuItem value="otherElectrical">Other Electrical (e.g. Storage heater)</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth variant="outlined">
          <InputLabel id="homeSolar-label">Does the property have Solar?</InputLabel>
          <Select
            labelId="homeSolar-label"
            value={solar}
            onChange={(e) => setSolar(e.target.value)}
            displayEmpty
            input={<OutlinedInput label="Does the property have Solar?" />}
            style={{ maxWidth: "600px" }}
          >
            <MenuItem value="solarThermal">Solar thermal (Water heating)</MenuItem>
            <MenuItem value="photovoltaic">Photovoltaic (PV)</MenuItem>
            <MenuItem value="notSure">I’m not sure</MenuItem>
            <MenuItem value="no">No</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth variant="outlined">
          <InputLabel id="homeBattery-label">Do you have a home battery?</InputLabel>
          <Select
            labelId='homeBattery-label'
            value={homeBattery}
            onChange={(e) => setHomeBattery(e.target.value)}
            displayEmpty
            input={<OutlinedInput label="Do you have a home battery?" />}
            style={{ maxWidth: "600px" }}
          >
            <MenuItem value="yes">Yes</MenuItem>
            <MenuItem value="no">No</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth variant="outlined">
          <InputLabel id="homeEV-label">Do you have an Electric Vehicle?</InputLabel>
          <Select
            labelId='homeEV-label'
            value={electricVehicle}
            onChange={(e) => setElectricVehicle(e.target.value)}
            displayEmpty
            input={<OutlinedInput label="Do you have an Electric Vehicle?" />}
            style={{ maxWidth: "600px" }}
          >
            <MenuItem value="yes">Yes</MenuItem>
            <MenuItem value="no">No</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth variant="outlined">
          <InputLabel id="homeWorkFromHome-label">Do any occupants work from home?</InputLabel>
          <Select
            labelId='homeWorkFromHome-label'
            value={workFromHome}
            onChange={(e) => setWorkFromHome(e.target.value)}
            displayEmpty
            input={<OutlinedInput label="Do any occupants work from home?" />}
            style={{ maxWidth: "600px" }}
          >
            <MenuItem value="yes">Yes</MenuItem>
            <MenuItem value="no">No</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth variant="outlined">
          <InputLabel id="homeWorkFromHomeNumber-label">If yes, how many people work from home?</InputLabel>
          <Select
            labelId='homeWorkFromHomeNumber-label'
            value={workFromHomeCount}
            onChange={(e) => setWorkFromHomeCount(e.target.value)}
            displayEmpty
            input={<OutlinedInput label="If yes, how many people work from home?" />}
            style={{ maxWidth: "600px" }}
          >
            <MenuItem value="1">1</MenuItem>
            <MenuItem value="2">2</MenuItem>
            <MenuItem value="3">3</MenuItem>
            <MenuItem value="4">4</MenuItem>
            <MenuItem value="5+">5+</MenuItem>
          </Select>
        </FormControl>
      </Grid>



      <Grid item xs={12}>
        <Button variant="contained" style={{ backgroundColor: 'navy', color: 'white' }} onClick={handleSave}>Save Changes</Button>
        {saveSuccessful && (
          <Typography variant="body1" style={{ color: 'green', marginTop: '20px' }}>
            Your changes have been saved. Thank you!
          </Typography>
        )}
      </Grid>
    </Grid>
  );
}

export default PropertyDetails;
