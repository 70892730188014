import { Routes, Route } from 'react-router-dom';
import { UserContextProvider } from './UserContext';
import SignIn from './SignIn'; // Import SignIn component
import Dashboard from './Dashboard'; // Import Dashboard component
import Signup from './Signup';
import Survey from './Survey';
import ForgotPassword from './ForgotPassword';
import UpdatePassword from './UpdatePassword';
import ProtectedRoute from './ProtectedRoute'; // Import ProtectedRoute component
import Subscribe from './Subscribe';
import Admin from './Admin';

function App() {
  return (
    <UserContextProvider>
      <Routes>
        <Route path="/" element={<SignIn />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/survey/:userId" element={<Survey />} />
        <Route path="/admin" element={
            <ProtectedRoute>
              <Admin />
            </ProtectedRoute>} />
        <Route path="/dashboard" element={
          <ProtectedRoute>
            <Dashboard />
          </ProtectedRoute>} />
        <Route path="/forgotten-password" element={<ForgotPassword />} />
        <Route path="/update-password/:token" element={<UpdatePassword />} />
        <Route path="/subscribe" element={<Subscribe />} />
      </Routes>
    </UserContextProvider>
  );
}

export default App;
