import React, { useState,useContext  } from 'react';
import {
  Container,
  Typography,
  TextField,
  Grid,
  Avatar,
  FormControl,
  Select,
  MenuItem,
  Button,
  OutlinedInput
} from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/system';
import logo from './images/title4.png'; // import the logo image
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { UserContext } from './UserContext';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DateTimePicker, DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import ApiUrl from "./ApiConfig";


const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
    },
  },
}));

const Paper = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(8),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  backgroundColor: '#DAECF3',
  padding: theme.spacing(4),
}));

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  margin: theme.spacing(1),
  backgroundColor: theme.palette.secondary.main,
}));

const Form = styled('form')(({ theme }) => ({
  width: '100%',
  marginTop: theme.spacing(3),
}));

export default function Survey() {
  const navigate = useNavigate();
  const [eircode, setEircode] = useState("");
  const [propertyType, setPropertyType] = useState("");
  const [propertyLocation, setPropertyLocation] = useState("");
  const [homeType, setHomeType] = useState("");
  const [homeSize, setHomeSize] = useState("");
  const [homeBER, setHomeBER] = useState("");
  const [knowsBER, setKnowsBER] = useState("");
  const [occupants, setOccupants] = useState("");
  const [heatingType, setHeatingType] = useState("");
  const [solar, setSolar] = useState("");
  const [homeBattery, setHomeBattery] = useState("");
  const [electricVehicle, setElectricVehicle] = useState("");
  const [chargesAtHome, setChargesAtHome] = useState("");
  const [workFromHome, setWorkFromHome] = useState("");
  const [workFromHomeCount, setWorkFromHomeCount] = useState("");
  const [expiryKnowsDate, setExpiryKnowsDate] = useState("");
  const [expiryDate, setExpiryDate] = useState(null);

  const { userId } = useParams();

  const { setUser } = useContext(UserContext);

  const setLoggedInUser = (token) => {
    localStorage.setItem('token', token);
    setUser(token);
  };

  const handleSurvey = () => {
    console.log('Eircode:', eircode);

    const surveyData = {
      userId,
      eircode,
      propertyType,
      propertyLocation,
      homeType,
      homeSize,
      homeBER,
      occupants,
      heatingType,
      solar,
      homeBattery,
      electricVehicle,
      workFromHome,
      expiryKnowsDate
    };

    if (electricVehicle !== 'no') {
      surveyData.chargesAtHome = chargesAtHome;
    }
    if (workFromHome !== 'no') {
      surveyData.workFromHomeCount = workFromHomeCount;
    }
    if (expiryKnowsDate !== 'no') {
      console.log("expiryKnowsDate is YES");
      surveyData.expiryDate = expiryDate;
    }

    fetch(`${ApiUrl}/api/meter`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(surveyData),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === 'success') {
          setLoggedInUser(data.user_token);
          navigate(`/dashboard`);
        } else {
            alert('Adding Meter data failed. Please try again.');
        }
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Process the form data here and perform the necessary actions
    handleSurvey();
  };

  return (
    <>
      <header className="header">
        <div className="logo-container" style={{ display: "flex", justifyContent: "center" }}>
          <img src={logo} alt="Logo" className="logo" />
        </div>
      </header>
      <Container component="main" maxWidth="sm" style={{ marginBottom: "20px", backgroundColor: "#DAECF3" }}>
        <Paper>
          <Typography component="h1" variant="h4" marginTop={3} marginBottom="20px">
            Smart Meter Questionnaire
          </Typography>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  name="eircode"
                  variant="outlined"
                  required
                  fullWidth
                  id="eircode"
                  label="Eircode"
                  value={eircode}
                  sx={{
                    width: '100%',
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: '#777B7D',
                      },
                      '&:hover fieldset': {
                        borderColor: '#FE424D',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: '#FE424D',
                      },
                    },
                    '& .MuiInputLabel-root.Mui-focused': {
                      color: '#FE424D',
                    },
                    '& .MuiInputBase-input': {
                      display: 'block',
                    },
                  }}
                  onChange={(e) => setEircode(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel
                    id="propertyType-label"
                    sx={{
                      '&.Mui-focused': {
                        color: '#FE424D',
                      },
                    }}
                  >
                    Property Type
                  </InputLabel>
                  <Select
                    labelId="propertyType-label"
                    id="propertyType"
                    value={propertyType}
                    onChange={(e) => setPropertyType(e.target.value)}
                    input={
                      <OutlinedInput
                        sx={{
                          '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#777B7D',
                          },
                          '&:hover .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#FE424D',
                          },
                          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#FE424D',
                          },
                        }}
                        label="Property Type"
                      />
                    }
                  >
                    <MenuItem value="domestic">Domestic</MenuItem>
                    <MenuItem value="commercial">Commercial</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              {propertyType === "domestic" && (
                <>
                  <Grid item xs={12}>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel id="propertyLocation-label">Property Location</InputLabel>
                      <Select
                        labelId="propertyLocation-label"
                        id="propertyLocation"
                        value={propertyLocation}
                        onChange={(e) => setPropertyLocation(e.target.value)}
                        label="Property Location"
                      >
                        {/* Step 3: MenuItems for the dropdown */}
                        <MenuItem value="urban">Urban</MenuItem>
                        <MenuItem value="rural">Rural</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel id="homeType-label">Home Type</InputLabel>
                      <Select
                        labelId="homeType-label"
                        id="homeType"
                        value={homeType}
                        onChange={(e) => setHomeType(e.target.value)}
                        label="Home Type"
                      >
                        <MenuItem value="detachedBungalow">Detached Bungalow</MenuItem>
                        <MenuItem value="detached2Storey">Detached 2 Storey</MenuItem>
                        <MenuItem value="semiD">Semi-D</MenuItem>
                        <MenuItem value="townhouse">Townhouse</MenuItem>
                        <MenuItem value="apartment">Apartment</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel id="homeSize-label">Home Size</InputLabel>
                      <Select
                        labelId="homeSize-label"
                        id="homeSize"
                        value={homeSize}
                        onChange={(e) => setHomeSize(e.target.value)}
                        label="Home Size"
                      >
                        <MenuItem value="<120">{'< 120 sqM'}</MenuItem>
                        <MenuItem value="120-180">120 - 180</MenuItem>
                        <MenuItem value="180-250">180 - 250</MenuItem>
                        <MenuItem value="250+">250 +</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel id="knowsBER-label">Do you know your property BER rating?</InputLabel>
                      <Select
                        labelId="knowsBER-label"
                        value={knowsBER}
                        onChange={(e) => setKnowsBER(e.target.value)}
                        displayEmpty
                        input={<OutlinedInput label="Do you know your property BER rating?" />}
                      >
                        <MenuItem value="yes">Yes</MenuItem>
                        <MenuItem value="no">No</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  {knowsBER === "yes" && (
                    <Grid item xs={12}>
                      <FormControl fullWidth variant="outlined">
                        <InputLabel id="homeBER-label">Please select the BER rating from the list.</InputLabel>
                        <Select
                          labelId="homeBER-label"
                          id="homeBER"
                          value={homeBER}
                          onChange={(e) => setHomeBER(e.target.value)}
                          label="Home BER Rating"
                        >
                          <MenuItem value="A">A</MenuItem>
                          <MenuItem value="B">B</MenuItem>
                          <MenuItem value="C">C</MenuItem>
                          <MenuItem value="D">D</MenuItem>
                          <MenuItem value="E">E</MenuItem>
                          <MenuItem value="F">F</MenuItem>
                          <MenuItem value="G">G</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel id="homeOccupants-label">How many occupants?</InputLabel>
                      <Select
                        labelId="homeOccupants-label"
                        value={occupants}
                        onChange={(e) => setOccupants(e.target.value)}
                        displayEmpty
                        input={<OutlinedInput label="How many occupants?" />}
                      >
                        <MenuItem value="1">1</MenuItem>
                        <MenuItem value="2">2</MenuItem>
                        <MenuItem value="3">3</MenuItem>
                        <MenuItem value="4">4</MenuItem>
                        <MenuItem value="5">5</MenuItem>
                        <MenuItem value="6+">6+</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel id="homeHeating-label">What is the propety heating type?</InputLabel>
                      <Select
                        labelId="homeHeating-label"
                        value={heatingType}
                        onChange={(e) => setHeatingType(e.target.value)}
                        displayEmpty
                        input={<OutlinedInput label="What is the property heating type?" />}
                      >
                        <MenuItem value="oilGas">Oil / Gas</MenuItem>
                        <MenuItem value="heatPump">Heat pump (Geothermal / Air to Water)</MenuItem>
                        <MenuItem value="woodStove">Wood stove</MenuItem>
                        <MenuItem value="woodPelletStove">Wood pellet stove</MenuItem>
                        <MenuItem value="otherElectrical">Other Electrical (e.g. Storage heater)</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel id="homeSolar-label">Does the property have Solar?</InputLabel>
                      <Select
                        labelId="homeSolar-label"
                        value={solar}
                        onChange={(e) => setSolar(e.target.value)}
                        displayEmpty
                        input={<OutlinedInput label="Does the property have Solar?" />}
                      >
                        <MenuItem value="solarThermal">Solar thermal (Water heating)</MenuItem>
                        <MenuItem value="photovoltaic">Photovoltaic (PV)</MenuItem>
                        <MenuItem value="notSure">I’m not sure</MenuItem>
                        <MenuItem value="no">No</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel id="homeBattery-label">Do you have a home battery?</InputLabel>
                      <Select
                        labelId='homeBattery-label'
                        value={homeBattery}
                        onChange={(e) => setHomeBattery(e.target.value)}
                        displayEmpty
                        input={<OutlinedInput label="Do you have a home battery?" />}
                      >
                        <MenuItem value="yes">Yes</MenuItem>
                        <MenuItem value="no">No</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel id="homeEV-label">Do you have an Electric Vehicle?</InputLabel>
                      <Select
                        labelId='homeEV-label'
                        value={electricVehicle}
                        onChange={(e) => setElectricVehicle(e.target.value)}
                        displayEmpty
                        input={<OutlinedInput label="Do you have an Electric Vehicle?" />}
                      >
                        <MenuItem value="yes">Yes</MenuItem>
                        <MenuItem value="no">No</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  {electricVehicle === "yes" && (
                    <Grid item xs={12}>
                      <FormControl fullWidth variant="outlined">
                        <InputLabel id="homeChargeAtHome-label">If yes, do you charge at this property?</InputLabel>
                        <Select
                          labelId='homeChargeAtHome-label'
                          value={chargesAtHome}
                          onChange={(e) => setChargesAtHome(e.target.value)}
                          displayEmpty
                          input={<OutlinedInput label="If yes, do you charge at this property?" />}
                        >
                          <MenuItem value="yes">Yes</MenuItem>
                          <MenuItem value="no">No</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel id="homeWorkFromHome-label">Do any occupants work from home?</InputLabel>
                      <Select
                        labelId='homeWorkFromHome-label'
                        value={workFromHome}
                        onChange={(e) => setWorkFromHome(e.target.value)}
                        displayEmpty
                        input={<OutlinedInput label="Do any occupants work from home?" />}
                      >
                        <MenuItem value="yes">Yes</MenuItem>
                        <MenuItem value="no">No</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  {workFromHome === "yes" && (
                    <Grid item xs={12}>
                      <FormControl fullWidth variant="outlined">
                        <InputLabel id="homeWorkFromHomeNumber-label">If yes, how many people work from home?</InputLabel>
                        <Select
                          labelId='homeWorkFromHomeNumber-label'
                          value={workFromHomeCount}
                          onChange={(e) => setWorkFromHomeCount(e.target.value)}
                          displayEmpty
                          input={<OutlinedInput label="If yes, how many people work from home?" />}
                        >
                          <MenuItem value="1">1</MenuItem>
                          <MenuItem value="2">2</MenuItem>
                          <MenuItem value="3">3</MenuItem>
                          <MenuItem value="4">4</MenuItem>
                          <MenuItem value="5+">5+</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  )}
                </>
              )}
              <Grid item xs={12}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel id="expiryKnows-label">Do you know your plans expiry date?</InputLabel>
                  <Select
                    labelId="expiryKnows-label"
                    value={expiryKnowsDate}
                    onChange={(e) => setExpiryKnowsDate(e.target.value)}
                    displayEmpty
                    input={<OutlinedInput label="Do you know your plans expiry date?" />}
                  >
                    <MenuItem value="yes">Yes</MenuItem>
                    <MenuItem value="no">No</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              {expiryKnowsDate === 'yes' && (
                <Grid item xs={12}>
                  <FormControl fullWidth variant="outlined">
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        label="Please select the expiry date"
                        inputFormat="dd/MM/yyyy"
                        value={expiryDate}
                        onChange={(newValue) => setExpiryDate(newValue)}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </LocalizationProvider>
                  </FormControl>
                </Grid>
              )}

              <Grid item xs={12}>
                <Button type="submit" fullWidth variant="contained" color="primary">
                  Complete Registration
                </Button>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Container>
    </>
  );
}

