import React from 'react';
import { Card, CardContent, Button, Typography } from '@mui/material';

function EVCard({ isMobile }) {
  return (
    <Card style={{ marginBottom: "20px", position: "relative", backgroundColor: "#DAECF3" }}>
      <div
        style={{
          position: "absolute",
          top: "10px",
          left: "10px",
          width: "50px",
          height: "50px",
          border: "5px solid #FE424D",
          borderRadius: "50%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: "#E74B18",
          fontSize: "24px",
        }}
      >
        EV
      </div>
      <CardContent style={{ marginTop: "60px", marginLeft: isMobile ? "0" : "80px" }}>
        <Typography variant="h6" component="h2">
          EV Cost Calculator
        </Typography>
        <Typography variant="body1" component="p" sx={{ fontFamily: 'sans-serif' }}>
          Estimate the costs of using and charging an Electric Vehicle.
        </Typography>
        <div style={{ display: "flex", flexDirection: isMobile ? "column" : "row", alignItems: "center", justifyContent: "center", marginTop: "60px" }}>
          <Button
            variant="contained"
            style={{ backgroundColor: "#022D41", color: "white" }}
          >
            Calculate
          </Button>
        </div>
      </CardContent>
    </Card>
  );
}

export default EVCard;
