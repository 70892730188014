import React from "react";
import { BarChart, Bar, CartesianGrid, XAxis, YAxis, Tooltip, ResponsiveContainer, Label } from "recharts";

const AverageLoadByWeekdayBarChart = ({ data }) => {
  const daysOrder = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
  
  const orderedData = daysOrder.map(day => data.find(d => d.Weekday === day)).filter(Boolean);

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip" style={{ backgroundColor: '#fff', padding: '5px', border: '1px solid #ccc' }}>
          {/* Access the `Weekday` property from the payload */}
          <p className="label">{`${payload[0].payload.Weekday}: ${payload[0].value.toFixed(2)} kWh`}</p>
        </div>
      );
    }
  
    return null;
  };

  return (
    <div style={{ width: "100%", height: 550 }}>
      <ResponsiveContainer>
        <BarChart data={orderedData}  margin={{ top: 5, right: 20, bottom: 20, left: 0 }}>
          <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
          <XAxis dataKey="Weekday">
            <Label value="Kilowatt hours (kWh)" offset={-10} position="insideBottom" style={{ fill: '#FE424D', fontWeight: "bold" }} />
          </XAxis>
          <YAxis>
            <Label value="Average Energy Consumed (kWh)" angle={-90} position="insideLeft" style={{ textAnchor: "middle", fontWeight: "bold" }} />
          </YAxis>
          <Tooltip content={<CustomTooltip />} />
          <Bar dataKey="Energy" fill="#FE424D" />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default AverageLoadByWeekdayBarChart;
