import { useState, useEffect } from 'react';

const UseTypewriter = (texts, isActive, speed = 3000, onComplete = () => {}, resetTrigger) => {
    const [typedLines, setTypedLines] = useState([]);
    const [currentTextIndex, setCurrentTextIndex] = useState(0);
    const [isComplete, setIsComplete] = useState(false);

    useEffect(() => {
        if (!isActive || isComplete) return;

        const timeoutId = setTimeout(() => {
            if (currentTextIndex < texts.length) {
                // Check if the text already exists in typedLines
                if (!typedLines.includes(texts[currentTextIndex])) {
                    setTypedLines(prev => [...prev, texts[currentTextIndex]]);
                }
                setCurrentTextIndex(currentTextIndex + 1);
            } else {
                setIsComplete(true);
                onComplete();
            }
        }, speed);

        return () => clearTimeout(timeoutId);
    }, [texts, currentTextIndex, speed, isActive, isComplete, onComplete, typedLines]);

    // Reset effect based on resetTrigger
    useEffect(() => {
        setTypedLines([]);
        setCurrentTextIndex(0);  // This function resets the index
        setIsComplete(false);
    }, [resetTrigger]);

    return typedLines;
};

export default UseTypewriter;
