import React from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from 'recharts';

function formatDate(dateString) {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // January is 0
  const year = date.getFullYear();
  return `${day}-${month}-${year}`;
}

function LineChartImportFilePlot({ importData, exportData }) {
  // Format import data
  const formattedImportData = importData.map(item => ({
    ...item,
    importEnergy: item['Daily Energy'],
    Date: formatDate(item.Date),
  }));

  // Format export data
  let formattedExportData = [];
  if (exportData) {
    formattedExportData = exportData.map(item => ({
      ...item,
      exportEnergy: item['Daily Energy'],
      Date: formatDate(item.Date),
    }));
  }

  // Merge import and export data by Date
  const combinedData = formattedImportData.map(importItem => {
    const exportItem = formattedExportData.find(exportItem => exportItem.Date === importItem.Date);
    return {
      ...importItem,
      exportEnergy: exportItem ? exportItem.exportEnergy : null,
    };
  });

  // Custom Tooltip formatter
  const tooltipFormatter = (value, name) => {
    const label = name.includes('Import') ? 'Import Energy' : 'Export Energy';
    return [`${label}: ${value} kWh`];
  };

  // Custom Axis Tick Component
  const renderCustomAxisTick = ({ x, y, payload, index, visibleTicksCount }) => {
    let dy = 16;
    let textAnchor = index === 0 ? 'start' : 'end';
    
    if (index === 0) {
      // Only adjust the first tick
      dy = 60;
    }

    // Render only first and last ticks
    if (index === 0 || index === visibleTicksCount - 1) {
      return (
        <g transform={`translate(${x},${y})`}>
          <text 
            x={0}
            y={0}
            dy={dy}
            textAnchor={textAnchor}
            fill="#666"
            fontSize="12px"
            transform="rotate(-45)"
          >
            {payload.value}
          </text>
        </g>
      );
    }
    return null;
  };

  return (
    <div style={{ width: '100%', height: 300 }}>
      <ResponsiveContainer>
        <LineChart
          data={combinedData}
          margin={{ top: 5, right: 20, left: 20, bottom: 70 }} // Adjusted for label positioning
        >
          <CartesianGrid strokeDasharray="3 3" vertical={false} />
          <XAxis 
            dataKey="Date"
            axisLine={false}
            tickLine={false}
            tick={renderCustomAxisTick}
            interval={0} // Ensures all ticks are considered
            tickCount={2} // Only two ticks should be shown
          />
          <YAxis axisLine={false} tickLine={false} tick={false} mirror />
          <Tooltip formatter={tooltipFormatter} />
          <Legend />
          <Line type="monotone" dataKey="importEnergy" stroke="rgb(254, 66, 77)" dot={false} activeDot={{ r: 8 }} name="Import Energy" />
          {exportData && <Line type="monotone" dataKey="exportEnergy" stroke="rgb(0, 128, 128)" dot={false} activeDot={{ r: 8 }} name="Export Energy" />}
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
}

export default LineChartImportFilePlot;
