import React, { useState } from 'react';
import {
  Card,
  CardContent,
  Typography,
  TextField,
  Button,
  Grid,
  Container,
  Alert,
} from '@mui/material';
import ReCAPTCHA from 'react-google-recaptcha';
import ApiUrl from './ApiConfig';
import logo from './images/title4.png'; // import the logo image

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [captchaValue, setCaptchaValue] = useState('');
  const [message, setMessage] = useState(null);
  const [error, setError] = useState(null);

  const handleResetPassword = async (e) => {
    e.preventDefault();
    setMessage(null);
    setError(null);

    if (!captchaValue) {
      setError('Please verify that you are not a robot.');
      return;
    }

    try {
      const response = await fetch(`${ApiUrl}/api/forgotten-password`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, captchaValue }),
      });

      const responseData = await response.json();
      if (response.ok) {
        setMessage(responseData.message);
      } else {
        setError(responseData.message || 'An error occurred. Please try again.');
      }
    } catch (error) {
      console.error('Error:', error);
      setError('Network error. Please try again.');
    }
  };

  return (
    <>
    <header className="header">
      <div className="logo-container" style={{ display: "flex", justifyContent: "center" }}>
        <img src={logo} alt="Logo" className="logo" style={{ width: '100%', maxWidth: '300px', height: 'auto' }} /> {/* Responsive logo */}
      </div>
    </header>
    <Container maxWidth="sm"> {/* Changed from md to sm for better scaling */}
      <Card style={{ marginTop: "20px", backgroundColor: "#DAECF3" }}>
        <CardContent>
          <Typography variant="h5" component="h2" style={{ marginBottom: "20px", textAlign: "center" }}>
            Forgotten Password
          </Typography>
          <Typography variant="body1" style={{ marginTop: "10px" }}>
            To recover your account, please enter the email address that you signed up with below. You will receive an email with instructions to reset your password. Be sure to check your spam folder if you do not see the email. If you continue to have issues, please <a href="https://powertocompare.ie/contact/" target="_blank" rel="noopener noreferrer">contact us</a>.
          </Typography>
          <form onSubmit={handleResetPassword} style={{ marginTop: "20px" }}>
            <Grid container spacing={2} alignItems="flex-end">
              <Grid item xs={12}>
                <TextField
                  label="Email Address"
                  variant="outlined"
                  fullWidth
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <ReCAPTCHA
                  sitekey="6LcYRAcqAAAAAFBVPiDqGAUaVYG6RYZKZq4xove7"  // Replace with your actual site key
                  onChange={(value) => setCaptchaValue(value)}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  type="submit"
                  variant="contained"
                  fullWidth
                  sx={{ backgroundColor: "#FE424D", color: "white" }}
                >
                  Send Password Reset
                </Button>
              </Grid>
            </Grid>
          </form>
          {message && (
            <Alert severity="info" style={{ marginTop: "20px" }}>
              {message}
            </Alert>
          )}
          {error && (
            <Alert severity="error" style={{ marginTop: "20px" }}>
              {error}
            </Alert>
          )}
        </CardContent>
      </Card>
    </Container>
    </>
  );
};

export default ForgotPassword;
