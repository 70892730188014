import React, { useState, useEffect, useRef, useMemo, Suspense } from "react";
import { useHistory } from 'react-router-dom';


import {
  Alert,
  AppBar,
  Button,
  Card,
  CardContent,
  CardMedia,
  TextField,
  Tab,
  Tabs,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
  CardActions,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Switch,
  Stepper,
  Step,
  StepLabel,
  Tooltip,
} from "@mui/material";

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';



// import { LocalizationProvider } from '@mui/lab';
// // import AdapterDateFns from '@mui/lab/AdapterDateFns';
// import DateFnsAdapter from '@date-io/date-fns';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DateTimePicker, DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import enGB from 'date-fns/locale/en-GB';

import DesktopDatePicker from '@mui/lab/DesktopDatePicker';


import Info from "@mui/icons-material/Info";
import DateRangeIcon from '@mui/icons-material/DateRange';
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import BlockIcon from '@mui/icons-material/Block';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import ElectricCarOutlinedIcon from '@mui/icons-material/ElectricCarOutlined';
import InputAdornment from "@mui/material/InputAdornment";
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import Chip from '@material-ui/core/Chip';
import ArrowBackIos from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIos from '@mui/icons-material/ArrowForwardIos';



import { styled } from '@mui/system';
import DateRangePicker from "@mui/lab/DateRangePicker";

import LineChart from "./LineChart";
import BarChartComponent from "./BarChart";
import BarChartEVCosts from "./BarChartEVCosts";
import DailyEnergyGraph from "./DailyEnergyGraph";
import WeeklyEnergyGraph from "./WeeklyEnergyGraph";
import WeeklyEnergyGraphBarChart from "./WeeklyEnergyGraphBarChart";
import MonthlyEnergyUsage from './MonthlyEnergyUsage';
import MonthlyEnergyUsageBarChart from "./MonthlyEnergyUsageBarChart";
import AverageLoadByWeekdayBarChart from "./AverageLoadByWeekdayBarChart";
import PriceComparisonDataTable from "./PriceComparisonDataTable";
import ComparisonTableMobile from "./ComparisonTableMobile";
import LineChartImportFilePlot from "./LineChartImportFilePlot";
import LineChartPriceSchedule from "./LineChartPriceSchedule";
import LineChartWeekPriceSchedule from "./LineChartWeekPriceSchedule";
import LineChartWeekPriceScheduleAllPlans from "./LineChartWeekPriceScheduleAllPlans";
import PropertyDetails from "./PropertyDetails";
import UseTypewriter from "./UseTypewriter";


import ShareCard from "./ShareCard";

const RenderSubscriptionPaymentHistory = React.lazy(() => import('./RenderSubscriptionPaymentHistory'));

import EVCard from "./EVCard";
import Header from './Header';
import '@mui/material/styles';
import { Box } from "@mui/system";

import carsData from "./cars.json"; // Use carsData instead of cars for the import
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

import ApiUrl from "./ApiConfig";

import MiniDrawer from "./MiniDrawer";
import Hidden from "@mui/material/Hidden";
import Drawer from "@mui/material/Drawer";
import MenuIcon from "@mui/icons-material/Menu";

import HomeIcon from '@mui/icons-material/Home';
import CompareIcon from '@mui/icons-material/Compare';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import HelpIcon from '@mui/icons-material/Help';
import LogoutIcon from '@mui/icons-material/Logout';

import { jwtDecode } from 'jwt-decode';



import { format } from 'date-fns';

// how to step screens
import how_to_step_1_screen from "./images/how_to_step_1.png"
import how_to_step_2_screen from "./images/how_to_step_2.png"
import how_to_step_3_screen from "./images/how_to_step_3.png"
import how_to_step_4_new_screen from "./images/how_to_step_4_new.png"
import how_to_step_4_new_b_screen from "./images/how_to_step_4_new_b.png"
import how_to_step_5_screen from "./images/how_to_step_5.png"


// import { DataGrid } from '@mui/data-grid';
import logo from './images/title4.png';
import InviteForm from "./InviteForm";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}


function Dashboard() {
  const tableContainerRef = useRef(null);

  // Define the scrollLeft and scrollRight functions
  const scrollLeft = () => {
    if (tableContainerRef.current) {
      tableContainerRef.current.scrollLeft -= 150; // Adjust the scroll distance as needed
    }
  };

  const scrollRight = () => {
    if (tableContainerRef.current) {
      tableContainerRef.current.scrollLeft += 150; // Adjust the scroll distance as needed
    }
  };
  // Define the state for the drawer

  // Example URL and title to share
  const shareWdigetUrl = "https://www.PowerToCompare.ie";
  const shareWdigetTitle = "Check out PowerToCompare.ie. It actually works out in detail which electricity plan is best for your usage!";

  const [open, setOpen] = useState(false);

  // Function to handle the drawer open
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  // Function to handle the drawer close
  const handleDrawerClose = () => {
    setOpen(false);
  };


  // File upload error dialog
  const [fileErrorDialogOpen, setFileErrorDialogOpen] = useState(false);

  // JWT
  const [jwtExpiryDialogOpen, setJwtExpiryDialogOpen] = useState(false);

  function isTokenExpired(token) {
    try {
      const decoded = jwtDecode(token);
      const isExpired = decoded.exp < Date.now() / 1000;

      // Log the current time and the token expiration details
      // console.log("Checking token at:", new Date().toISOString());
      // console.log("Token expiration time:", new Date(decoded.exp * 1000).toISOString());
      // console.log("Token is expired:", isExpired);

      return isExpired;
    } catch (e) {
      console.error("Error decoding token:", e);
      return true; // Assume the token is expired if there's an error
    }
  }


  function checkJwtTokenAndDisplayExpiryDialog() {
    const token = localStorage.getItem('token');
    if (!token || isTokenExpired(token)) {
      setJwtExpiryDialogOpen(true);
    }
  }

  useEffect(() => {
    // Run the check on component mount
    checkJwtTokenAndDisplayExpiryDialog();

    // Optionally set an interval to check the token regularly
    const interval = setInterval(checkJwtTokenAndDisplayExpiryDialog, 60000); // checks every minute

    // Clear interval on component unmount
    return () => clearInterval(interval);
  }, []);




  const [file, setFile] = useState(null);
  const [dfData, setDfData] = useState(null);
  const [dfDailyUsage, setDfDailyUsage] = useState(null);

  const token = localStorage.getItem('token');
  const senderFirstName = localStorage.getItem('first_name');
  const senderLastName = localStorage.getItem('last_name');
  const senderFullName = `${senderFirstName} ${senderLastName}`;


  // Typerwriter

  const [loadingReport, setLoadingReport] = useState(false);
  const [loadingReportNewSubscriber, setLoadingReportNewSubscriber] = useState(false);
  const [startTyping, setStartTyping] = useState(false);
  const [resetKey, setResetKey] = useState(0);
  const [reportLines, setReportLines] = useState([
    "Meter data begins on: 2021-11-29 00:00:00",
    "Meter data ends on: 2023-11-28 02:30:00",
    "Days of meter data available: 729",
    "Selected meter data begins on: 2022-11-28 02:30:00",
    "Energy consumption over the Selected Period (kWh): 6372.0",
    "This is 152.0% of the Irish household average.",
    "Energy exported to the grid over the Selected Period (kWh): 528.0",
    "Highest energy consumption recorded in a single day (kWh): 55.0",
    "This occurred on: 18/10/2023",
    "Total Night time electricity consumption: 3583.0",
    "56.0% of your electricity consumption was at night (between 11pm and 8am)"
  ]);

  const [showReportReadyLoading, setShowReportReadyLoading] = useState(false);  // To track loading state for spinner
  const [showReportReadyButton, setShowReportReadyButton] = useState(false);

  const handleTypingComplete = () => {
    console.log('All text has been typed out.');
    // setShowReportReadyButton(true);
  };

  const typedLines = UseTypewriter(reportLines, loadingReport && startTyping, 20, handleTypingComplete, resetKey);


  const handleClose = () => {
    setLoadingReport(false);  // Set this to close the dialog
    setResetKey(prevKey => prevKey + 1);
    setStartTyping(false);
    reportRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  // function updateReportLines(metadata) {
  //   const newLines = [
  //     // `Meter data begins on: ${metadata["Meter data begins on"]}`,
  //     // `Meter data ends on: ${metadata["Meter data ends on"]}`,
  //     `Days of meter data available: ${metadata["Days of meter data available"]}`,
  //     // Formatting dates with date-fns
  //     `Selected meter data begins on: ${format(new Date(metadata["Selected meter data begins on"]), 'dd/MM/yyyy')}`,
  //     `Selected meter data ends on: ${format(new Date(metadata["Selected meter data ends on"]), 'dd/MM/yyyy')}`,
  //     `Number of days that will be shown in your report is ${metadata["SelectedNumberOfDays"]}`,
  //     // `Energy consumption over the Selected Period (kWh): ${metadata["Energy consumption over the Selected Period (kWh)"]}`,
  //     `Highest energy consumption recorded in a single day (kWh): ${metadata["Highest energy consumption recorded in a single day (kWh)"]}`,
  //     `This occurred on: ${metadata["Date of highest energy consumption"]}`,
  //     // `Total Nighttime electricity consumption: ${metadata["Total Nighttime electricity consumption"]}`,
  //     `${metadata["Percentage used at night"]}`,
  //     // `Energy exported to the grid over the Selected Period (kWh): ${metadata["Energy exported to the grid over the Selected Period (kWh)"]}`
  //   ];

  //   // Add the percentage of average household consumption line if it is defined
  //   if (metadata["Percentage of average household consumption"] !== undefined) {
  //     newLines.push(`Your electricity consumption is ${metadata["Percentage of average household consumption"]}`);
  //   }

  //   setReportLines(newLines);
  // }

  function updateReportLines(fileSummary) {
    const newLines = [
      `Days of meter data available: ${fileSummary.MeteredNumberOfDays}`,
      `Selected meter data begins on: ${fileSummary.MeterStartDate}`,
      `Selected meter data ends on: ${fileSummary.MeterEndDate}`,
      `Number of days that will be shown in your report is ${fileSummary.MeteredNumberOfDays}`,
      `Highest energy consumption recorded in a single day (kWh): ${fileSummary.MaxImportDayEnergy}`,
      `This occurred on: ${fileSummary.strMaxImportDate}`,
      `Your energy consumption during the selected period (kWh): ${fileSummary.EnergyImportedSelectedPeriod}`
    ];

    // No need to check for 'Percentage used at night' or other fields as they are not returned from your current backend file_summary response.

    // Example of adding more lines later if other data points are returned:
    // if (fileSummary.someOtherField) {
    //   newLines.push(`Some other info: ${fileSummary.someOtherField}`);
    // }

    // Update the report lines state
    setReportLines(newLines);
  }



  // New state for handling the dialog visibility
  const [showNewPlanDialog, setShowNewPlanDialog] = useState(false);

  // for charts
  const [dfAverageLoadByWeekday, setDfAverageLoadByWeekday] = useState(null);
  // Import and Export details tables
  const [dfMonthlyImportCost, setDfMonthlyImportCost] = useState(null);
  const [dfMonthlyExportPayments, setDfMonthlyExportPayments] = useState(null);

  const [loading, setLoading] = useState(false);
  const [dateRange, setDateRange] = useState([null, null]);
  const [dfMonthlyEnergyUsage, setDfMonthlyEnergyUsage] = useState([]);
  // Show all plans
  const [showAllPlans, setShowAllPlans] = useState(false);
  const [showAllImportPlans, setShowAllImportPlans] = useState(false);
  const [showAllExportPlans, setShowAllExportPlans] = useState(false);

  // Duel Fuel
  const [showPlanTypeCard, setShowPlanTypeCard] = useState(true); // Assume the user is new
  const [dualFuel, setDualFuel] = useState(false); // Default value
  const [showSelectSupplier, setShowSelectSupplier] = useState(false);

  // Add a state to track which plan type is selected
  const [planTypeSelected, setPlanTypeSelected] = useState(null);

  const handlePlanTypeSelection = (selection) => {
    console.log("handlePlanTypeSelection() --- selectoion :", selection);

    const isDualFuel = selection === 'dualFuel';
    setDualFuel(isDualFuel); // Set the dualFuel state based on selection
    setPlanTypeSelected(selection); // Set the plan type selected
    setShowSelectSupplier(true);

    // Reset the selected supplier and plan to their defaults
    setSelectedSupplier('');
    setSelectedPlan('');

    // setPlanDropdownEnabled(false); 
    console.log("handlePlanTypeSelection() --- before fetchTariffs :", isDualFuel);

    fetchTariffs(isDualFuel);

    console.log("handlePlanTypeSelection() --- after fetchTariffs :");

    // Do not hide the card, so setShowPlanTypeCard is not needed
  };

  const [connectionClassificationSelected, setConnectionClassificationSelected] = useState(null);

  const handleConnectionClassificationSelection = (selection) => {
    setConnectionClassificationSelected(selection); // Update the selected classification
    console.log("Connection classification selected:", selection);

    // Add any additional logic needed after selecting the classification
    // For example, saving it to localStorage, API calls, or triggering other actions
    // localStorage.setItem('connectionClassification', selection);

    // Example of additional action, like triggering recalculations or loading data
    if (selection === 'urban') {
      console.log('Urban connection selected, triggering relevant action...');
      // Perform any specific action needed when Urban is selected
    } else if (selection === 'rural') {
      console.log('Rural connection selected, triggering relevant action...');
      // Perform any specific action needed when Rural is selected
    }
  };



  const [showAllSuppliers, setShowAllSuppliers] = useState(false);
  const [dfCostDiffData, setDfCostDiffData] = useState({ columns: [], values: [] });
  const [dfImpExpDiffData, setDfImpExpDiffData] = useState({ columns: [], values: [] });

  /* state for suppler and plan selection */
  const [selectedSupplier, setSelectedSupplier] = useState('');
  const [selectedPlan, setSelectedPlan] = useState("");
  const [currentDiscount, setCurrentDiscount] = useState(0);

  /* Get tarrifs on first page load dropdowns, supplier and plans */

  const [suppliers, setSuppliers] = useState([]);
  const [plans, setPlans] = useState([]);
  const [planOptions, setPlanOptions] = useState([]);

  /* EV Costs values */
  const [evCosts, setEVCosts] = useState([]);

  // Add a new state variable for EV card visibility
  const [showEVCard, setShowEVCard] = useState(false);

  const handleToggleEVCard = () => {
    setShowEVCard(!showEVCard);
  };

  /* returned from backend for use */
  const [dfSelectedPlan, setDfSelectedPlan] = useState({
    selected_supplier: "",
    selected_plan: "",
    selected_discount: "",
  });

  const priceDiffTableColumnOrder = [
    "Supplier",
    "Tariff",
    "Discount %",
    "Unit savings based on your smart meter usage data",
    "EV charging savings",
    "Total savings based on your smart meter usage data",
    "Total savings including simulated EV charging",
    "Export Payment Difference",
    "Total savings including export payments",
    "Total savings including export payments and simulated EV charging"
  ]

  const [energyExported, setEnergyExported] = useState(false);

  /* EV variables */

  const [electricVehicleToggle, setElectricVehicleToggle] = useState(false);
  const [selectedMake, setSelectedMake] = useState("");
  const [selectedModel, setSelectedModel] = useState("");
  const [efficiency, setEfficiency] = useState("");
  const [cars, setCars] = useState([{ make: "", model: "", annualMileage: "50000" }]);
  // Determine hasEV based on the presence of any cars in the cars array
  const hasEV = cars.length > 0;

  // Filter the columns if energyExported is false
  // Now placed at the top level of the Dashboard component
  // Filter the columns if energyExported is false or no EV is selected
  const filteredPriceDiffTableColumnOrder = useMemo(() => {
    return priceDiffTableColumnOrder.filter(
      (col) => {
        if (!energyExported && [
          "Export Payment Difference",
          "Total savings including export payments",
          "Total savings including export payments and simulated EV charging"
        ].includes(col)) {
          return false;
        }
  
        if (!hasEV && [
          "Total savings including simulated EV charging",
          "Total savings including export payments and simulated EV charging"
        ].includes(col)) {
          return false;
        }
  
        // Always hide "Unit savings based on your smart meter usage data" and "EV charging savings"
        if (["Unit savings based on your smart meter usage data", "EV charging savings"].includes(col)) {
          return false;
        }
  
        return true;
      }
    );
  }, [energyExported, hasEV]); // Recalculate only if energyExported or hasEV changes
  


  /* Performance of your plan variables */
  const [priceVsUsage, setPriceVsUsage] = useState(false);
  const [weekPriceVsUsage, setWeekPriceVsUsage] = useState(false);
  const [weekPriceVsUsageAllPlans, setWeekPriceVsUsageAllPlans] = useState(false);
  const [selectedComparisonPlanLineGraphAllPlans, setSelectedComparisonPlanLineGraphAllPlans] = useState('');

  /* early bird invite */
  const [allInvitesSent, setAllInvitesSent] = useState(false);

  /* Date Range Pickers - mui v5 used so have to use these pickers */

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  /* show the date filter or not toggle */
  const [showDateFilter, setShowDateFilter] = useState(false);

  const toggleDateFilter = () => {
    setShowDateFilter(!showDateFilter);
  };

  // Line graph file check plot data on a new file select
  const [lineChartImportFilePlot, setLineChartImportFilePlot] = useState([]);
  const [lineChartExportFilePlot, setLineChartExportFilePlot] = useState(null);
  const [keyFileMetrics, setKeyFileMetrics] = useState([]);

  // Add new state variables for the initial dates
  const [initialStartDate, setInitialStartDate] = useState(null);
  const [initialEndDate, setInitialEndDate] = useState(null);

  /* Handle things like the 'upload NEW smart meter file' etc. */
  const [hasUploadedReportBefore, setHasUploadedReportBefore] = useState(false);
  // Dialog when it's an older report loaded!
  const [dialogOpen, setDialogOpen] = useState(false);

  // Mobile Check 
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    // Function to check if the screen size is less than 600 pixels (mobile)
    const checkMobileScreen = () => {
      const screenWidth = window.innerWidth;
      console.log('Screen width:', screenWidth);
      setIsMobile(window.innerWidth < 900);
    };

    // Check on initial load
    checkMobileScreen();

    // Add a resize event listener to update the layout when the screen size changes
    window.addEventListener("resize", checkMobileScreen);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", checkMobileScreen);
    };
  }, []);

  // This method handles the 'Generate Report' action from the dialog
  const handleGenerateReportFromDialog = () => {
    setShowNewPlanDialog(false); // Close the dialog
    // Call the function to regenerate the report here
    handleFileSummaryThenUpload(); // This assumes handleUpload is the function that regenerates the report
  };


  // console.log('Initial start date:', startDate);
  // console.log('Initial end date:', endDate);


  const handleStartDateChange = (date) => {
    // console.log('Start date changed:', date);
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    // console.log('End date changed:', date);
    setEndDate(date);
  };

  const [filteredDates, setFilteredDates] = useState({ start: null, end: null }); // initialize state

  /* Subscription Type */
  const [subscriptionType, setSubscriptionType] = useState("");
  const [subscriptionAccountCreationDate, setSubscriptionAccountCreationDate] = useState("");
  const [subscriptionPlanStartDate, setSubscriptionPlanStartDate] = useState("");
  const [subscriptionPlanEndDate, setSubscriptionPlanEndDate] = useState("");
  const [subscriptionUsername, setSubscriptionUsername] = useState("");

  /* Your account communication */
  // Define states with more descriptive names
  const [communicationsPreference1, setCommunicationsPreference1] = useState('yes');
  // Add a state for the second preference if needed
  const [communicationsPreference2, setCommunicationsPreference2] = useState('yes');

  // Handler for the first communications preference
  const handleCommunicationsPreference1Change = (event) => {
    setCommunicationsPreference1(event.target.value);
  };

  // Handler for the second communications preference
  // Define a similar handler for the second preference if needed
  const handleCommunicationsPreference2Change = (event) => {
    setCommunicationsPreference2(event.target.value);
  };

  const [subscribeSummaryCard, setSubscribeSummaryCard] = useState("false");

  const [summaryData, setSummaryData] = useState({
    numberOfCheaperPlans: 0,
    maxAnnualSavings: 0
  });


  

  

  /* EV menu state */
  const [showEVCards, setShowEVCards] = React.useState(false);

  /* active menu item or page */

  const [activePage, setActivePage] = React.useState("homepage");  // default to 'homepage'

  function handlePageChange(newPage) {
    setActivePage(newPage);
  }

  /* Share a friend variables */
  const shareText = "Check out this amazing app!";
  const shareUrl = "https://your-app-url.com";
  const emailShareUrl = `mailto:?subject=${encodeURIComponent(shareText)}&body=${encodeURIComponent(shareUrl)}`;
  const whatsappShareUrl = `https://wa.me/?text=${encodeURIComponent(shareText + " " + shareUrl)}`;
  const [shareFriendName, setShareFriendName] = useState('');
  const [shareFriendEmail, setShareFriendEmail] = useState('');

  /* Page Tab */
  const [tabValue, setTabValue] = useState(0);

  /* Price Comparison Tabs */
  const [selectedPriceComparisonTab, setSelectedPriceComparisonTab] = React.useState(0);

  const handlePriceComparisonTabChange = (event, newValue) => {
    setSelectedPriceComparisonTab(newValue);
  };

  /* Graph Type Tabs */

  const [selectedGraphComparisonTab, setSelectedGraphComparisonTab] = useState(0);

  const handleGraphComparisonTabChange = (event, newValue) => {
    setSelectedGraphComparisonTab(newValue);

    if (newValue === 0) {
      // Filter smart tariffs
      const smartTariffs = plans.filter((plan) => plan['Smart Tariff'] === 'Y');
      //setFilteredTariffs(smartTariffs);
    } else if (newValue === 1) {
      // Show all tariffs
      //setFilteredTariffs(plans);
    }
  };


  /* Smart tariff lists for using to filter tables and graphs */

  const [smartTariffs, setSmartTariffs] = useState([]);
  const [smartTariffsDuel, setSmartTariffsDuel] = useState([]);


  /* sort the price comparison table */
  const [sortedTariffPriceTableValues, setSortedTariffPriceTableValues] = useState([]);

  useEffect(() => {
    const savingsColumnIndex = dfCostDiffData.columns.indexOf(
      "Total savings including simulated EV charging"
    );
    const sortedValues = [...dfCostDiffData.values].sort(
      (a, b) => parseFloat(b[savingsColumnIndex]) - parseFloat(a[savingsColumnIndex])
    );
    setSortedTariffPriceTableValues(sortedValues);
  }, [dfCostDiffData.values]);


  /* STEPS */

  const smartMeterSteps = [
    "Select Plan Type",
    "Select Connection Classification",
    "Select Current Supplier & Plan",
    "Download Smart meter file from esbnetworks.ie & Upload",
    "Discover Your Savings",
  ];

  const smartMeterAndEVSteps = [
    "Select your current supplier and plan.",
    "Download smart meter file from esbnetworks.ie and upload.",
    "Select electric vehicle to simulate costs.",
    "Enjoy your reports.",
  ];

  const EVSteps = [
    "Select your current supplier and plan.",
    "Select electric vehicle to simulate costs.",
    "Enjoy your reports.",
  ];

  /* Scroll to report when loaded */
  const reportRef = useRef(null);
  const subscribeRef = useRef(null);

  /* mount the function on component mount  so we have the data on pageload */

  useEffect(() => {
    //fetchTariffs();
    // fetchLatestReport();
    fetchSubscription();
  }, []);

  const fetchSubscription = async () => {
    const userToken = localStorage.getItem('token');

    try {
      const response = await fetch(`${ApiUrl}/api/check-subscription`, {
        method: 'GET',
        headers: {
          'Authorization': 'Bearer ' + userToken,
        }
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const subscriptionData = await response.json();
      console.log('Subscription Data:', subscriptionData);

      if (subscriptionData) {
        // Update subscription type; default to 'free' if not specified
        setSubscriptionType(subscriptionData.type || 'free');

        // Update additional subscription details if available
        if (subscriptionData.created_date) {
          setSubscriptionAccountCreationDate(subscriptionData.created_date);
        }
        if (subscriptionData.subscription_start_date) {
          setSubscriptionPlanStartDate(subscriptionData.subscription_start_date);
        }
        if (subscriptionData.subscription_end_date) {
          setSubscriptionPlanEndDate(subscriptionData.subscription_end_date);
        }

        if (subscriptionData.username) {
          setSubscriptionUsername(subscriptionData.username);
        }

        // Additional logic for subscribers
        if (subscriptionData.type === 'subscriber') {

          fetchLatestReport();
          fetchLastFileData();
        }
      } else {
        console.error("No subscription data found");
      }
    } catch (error) {
      console.error("Error fetching subscription status:", error);
    }
  };


  const upgradeUserSubscription = async () => {
    // Get userToken from localStorage
    const userToken = localStorage.getItem('token');

    try {
      const response = await fetch(`${ApiUrl}/api/upgrade-subscription`, {
        method: 'POST',
        headers: {
          'Authorization': 'Bearer ' + userToken,
        }
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      console.log('Subscription upgrade response:', data);

      // Handle the success scenario
      setSubscriptionType("subscriber");
      handleUpload(true);
    } catch (error) {
      console.error("Error upgrading subscription:", error);
    }
  }


  // const fetchTariffs = async () => {
  //   try {
  //     const response = await fetch(`${ApiUrl}/api/get-tariffs/duel`); 
  //     // const response = await fetch(`${ApiUrl}/api/get-tariffs`);
  //     if (!response.ok) {
  //       throw new Error("Network response was not ok");
  //     }
  //     const data = await response.json();
  //     console.log("Response data:", data);

  //     const uniqueSuppliers = Array.from(new Set(data.map((plan) => plan.Supplier)));
  //     setSuppliers(uniqueSuppliers);
  //     setPlans(data);

  //     // Filter the tariffs data for smart tariffs (where 'Smart Tariff' is 'Y')
  //     const smartTariffs = data.filter((plan) => plan['Smart Tariff'] === 'Y');
  //     setSmartTariffs(smartTariffs);

  //   } catch (error) {
  //     console.error("Error fetching tariff data:", error);
  //   }
  // };

  const fetchTariffs = async (isDualFuel) => {
    console.log("fetchTariffs() top of");
    const endpoint = isDualFuel ? `${ApiUrl}/api/get-tariffs/duel` : `${ApiUrl}/api/get-tariffs`;
    try {
      const response = await fetch(endpoint);

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      console.log("fetchTariffs() Response data:", data);

      const uniqueSuppliers = Array.from(new Set(data.map((plan) => plan.Supplier)));
      setSuppliers(uniqueSuppliers);
      setPlans(data);

      // Filter the tariffs data for smart tariffs (where 'Smart Tariff' is 'Y')
      const smartTariffs = data.filter((plan) => plan['Smart Tariff'] === 'Y');
      setSmartTariffs(smartTariffs);

    } catch (error) {
      console.error("Error fetching tariff data:", error);
    }
  };


  const fetchLatestReport = async () => {
    // Get userToken from localStorage
    const userToken = localStorage.getItem('token');

    console.log("userToken from fetchLatestReport()", userToken);

    try {
      const response = await fetch(`${ApiUrl}/api/latest-report`, {
        method: 'GET',
        headers: {
          'Authorization': 'Bearer ' + userToken,  // The JWT for the logged-in user
        },
      });

      if (response.ok) {
        const latestReport = await response.json();
        // Handle the successful response and process the latest report
        console.log("In the fetchLatestReport and there is a latest report");
        // from the latest report get the supplier and plans for the selected supplier before populate dashboard

        fetchTariffs(latestReport.dfSelectedPlan.selected_plan_type);
        // console.log("In the fetchLatestReport 1");
        populateDashboard(latestReport);
        // console.log("In the fetchLatestReport 2");
        setHasUploadedReportBefore(true);
        console.log("In the fetchLatestReport 3");
        setDialogOpen(true); // Open the Dialog
        const fileData = latestReport.file_data;
        const fileName = latestReport.file_name;
        console.log("In the fetchLatestReport 3");
        console.log("In the fetchLatestReport - filename :", fileName);

        if (fileData) {
          console.log('WE HAVE LATEST FILE DATA!');
          setFile(new File([fileData], fileName));
        }
      } else if (response.status === 401) {
        // Handle 401 Unauthorized error
        throw new Error("Authentication failed: Invalid or expired token");
      } else {
        // Handle other error statuses
        throw new Error("Error: " + response.status);
      }
    } catch (error) {
      // Handle network or other errors
      console.error("Error fetching latest report:", error.message);
    }
  };



  const getSteps = () => {
    switch (tabValue) {
      case 0:
        return smartMeterSteps;
      case 1:
        return smartMeterAndEVSteps;
      case 2:
        return EVSteps;
      default:
        return [];
    }
  };

  /* Loading spinner */

  const StyledProgress = styled(CircularProgress)({
    position: "fixed",
    top: "50%",
    left: "50%",
    color: "#FE424D",
    transform: "translate(-50%, -50%)",
    zIndex: 9999,
  });

  /* start of EV FUNCTIONS */

  const handleSupplierChange = (e) => {
    const selectedSupplier = e.target.value;
    setSelectedSupplier(selectedSupplier);

    // Reset the selected plan and current discount
    setSelectedPlan('');
    setCurrentDiscount(0);

    // Filter the plans based on the selected supplier
    const supplierPlans = plans.filter((plan) => plan.Supplier === selectedSupplier);
    const supplierPlanOptions = supplierPlans.map((plan) => plan.Tariff);
    setPlanOptions(supplierPlanOptions);
  };


  // const handlePlanChange = (e) => {
  //   const selectedPlan = e.target.value;
  //   setSelectedPlan(selectedPlan);

  //   // Find the selected plan object
  //   const plan = plans.find(plan => plan.Tariff === selectedPlan);

  //   // Update the currentDiscount state
  //   if (plan) {
  //     setCurrentDiscount(plan['Discount %']);
  //   } else {
  //     setCurrentDiscount(0);
  //   }
  // };

  const handlePlanChange = (e) => {
    const newPlan = e.target.value;

    // Update the selected plan state always
    setSelectedPlan(newPlan);

    // Find the selected plan object and update the currentDiscount state
    const plan = plans.find(plan => plan.Tariff === newPlan);
    if (plan) {
      setCurrentDiscount(plan['Discount %']);
    } else {
      setCurrentDiscount(0);
    }

    // If dfData is set and a different plan is selected, trigger the dialog
    if (dfData && selectedPlan !== newPlan) {
      setShowNewPlanDialog(true); // Open the dialog to confirm the report regeneration
    }
  };




  const handleDashboardTabChange = (event, newValue) => {
    setTabValue(newValue);

    // Reset data when changing tabs
    setSelectedSupplier('');
    setSelectedPlan('');
    setCurrentDiscount(0);
  };


  const handleMakeChange = (event) => {
    setSelectedMake(event.target.value);
    setSelectedModel("");
    setEfficiency("");
  };

  const handleModelChange = (event) => {
    setSelectedModel(event.target.value);
    const selectedCar = carsData.find(
      (car) => car.make === selectedMake && car.model === event.target.value
    );
    if (selectedCar) {
      setEfficiency(selectedCar.efficiency);
    }
  };


  const handleElectricVehicleToggle = () => {
    setElectricVehicleToggle(!electricVehicleToggle);
  };

  const handleAddCar = () => {
    setCars([...cars, { make: "", model: "", efficiency: "", estimatedMileage: "" }]);
  };


  const handleCarChange = (index, field, value) => {
    const newCars = [...cars];
    newCars[index][field] = value;
    setCars(newCars);

    if (field === "model") {
      const selectedCar = carsData.find(
        (car) => car.make === newCars[index].make && car.model === value
      );
      if (selectedCar) {
        handleCarChange(index, "efficiency", selectedCar.efficiency);
      }
    }
  };


  const handleRemoveCar = (index) => {
    setCars((prevCars) => {
      return prevCars.filter((_, i) => i !== index);
    });
  };

  const handleViewAllPlans = () => {
    setShowAllPlans(!showAllPlans);
  };

  // Show Plans
  const handleViewAllSuppliers = () => {
    setShowAllSuppliers(!showAllSuppliers);
  };

  const handleViewAllImportPlans = () => {
    setShowAllImportPlans(!showAllImportPlans);
  };

  const handleViewAllExportPlans = () => {
    setShowAllExportPlans(!showAllExportPlans);
  };



  const handleApplyEVChargeEstimates = async () => {
    setLoading(true);
    const formData = new FormData();
    formData.append("file", file);
    formData.append("selectedSupplier", selectedSupplier);
    formData.append("selectedPlan", selectedPlan);
    formData.append("selectedDiscount", currentDiscount);
    formData.append("callType", "Apply EV Estimates");
    formData.append("planType", planTypeSelected);
    formData.append("connectionClassification", connectionClassificationSelected);

    const carsData = cars.map((car) => ({
      make: car.make,
      model: car.model,
      efficiency: car.efficiency,
      estimatedMileage: car.mileage,
    }));

    formData.append("cars", JSON.stringify(carsData));

    // Include current date filter start date and end date
    const fromDateString = startDate ? format(startDate, "yyyy-MM-dd'T'HH:mm") : null;
    const toDateString = endDate ? format(endDate, "yyyy-MM-dd'T'HH:mm") : null;

    if (fromDateString) {
      formData.append('from_date', fromDateString);
    }
    if (toDateString) {
      formData.append('to_date', toDateString);
    }

    console.log("DVO in handleApplyEVChargeEstimates");

    try {
      const response = await fetch(`${ApiUrl}/api/upload-csv`, {
        method: "POST",
        body: formData,
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,  // JWT Addition colin
        },
      });
      const data = await response.json();
      const dfData = { columns: [], values: [] };
      const dfDailyUsage = { columns: [], values: [] };
      const dfCostDiffData = { columns: [], values: [] };

      dfData.columns = Object.keys(data.dfResultsSorted[0]);
      dfData.values = data.dfResultsSorted.map((row) => Object.values(row));
      dfDailyUsage.columns = Object.keys(data.dfDailyLoadProfiles[0]);
      dfDailyUsage.values = data.dfDailyLoadProfiles.map((row) => Object.values(row));
      dfCostDiffData.columns = Object.keys(data.dfCostDifferencesSorted[0]);
      dfCostDiffData.values = data.dfCostDifferencesSorted.map((row) => Object.values(row));

      setDfData(dfData);
      setDfDailyUsage(dfDailyUsage);
      setDfCostDiffData(dfCostDiffData);
      setDfSelectedPlan(data.dfSelectedPlan);
      // setEnergyExported(true);
    } catch (error) {
      console.error(error);
    }

    setTimeout(() => {
      setLoading(false);
    }, 2000);
  };

  const handleEVCosts = async () => {
    setLoading(true);
    const formData = new FormData();
    formData.append("selectedSupplier", selectedSupplier);
    formData.append("selectedPlan", selectedPlan);
    formData.append("selectedDiscount", currentDiscount);
    formData.append("callType", "EV Costs");
    formData.append("connectionClassification", connectionClassificationSelected);

    const carsData = cars.map((car) => ({
      make: car.make,
      model: car.model,
      efficiency: car.efficiency,
      estimatedMileage: car.mileage,
    }));

    formData.append("cars", JSON.stringify(carsData));

    // Include current date filter start date and end date
    const fromDateString = startDate ? format(startDate, "yyyy-MM-dd'T'HH:mm") : null;
    const toDateString = endDate ? format(endDate, "yyyy-MM-dd'T'HH:mm") : null;

    if (fromDateString) {
      formData.append('from_date', fromDateString);
    }
    if (toDateString) {
      formData.append('to_date', toDateString);
    }

    console.log("DVO in handleEVCosts");

    try {
      const response = await fetch(`${ApiUrl}/api/ev-costs`, {
        method: "POST",
        body: formData,
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,  // JWT Addition colin
        },
      });
      const data = await response.json();

      console.log('Data received handleEVCosts:', data);

      const dfEVCostData = { columns: [], values: [] };

      // Extract columns from the first element 
      dfEVCostData.columns = Object.keys(data.dfEVCosts[0]);

      console.log("dfEVCostData.columns:", dfEVCostData.columns);

      // Extract values from all elements of dfCostDifferencesSorted
      dfEVCostData.values = data.dfEVCosts.map((row) =>
        Object.values(row)
      );

      console.log("dfEVCostData.values:", dfEVCostData.values);

      setEVCosts(dfEVCostData);
      setDfSelectedPlan(data.dfSelectedPlan);

      console.log("evCosts set to:", evCosts);

    } catch (error) {
      console.error(error);
    }

    setTimeout(() => {
      setLoading(false);
    }, 2000);
  };


  const handleShareSubmit = (e) => {
    e.preventDefault();

    fetch('http://localhost:5000/api/share', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ shareFriendName, shareFriendEmail }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === 'success') {
          alert('Invitation sent successfully!');
        } else {
          alert('Failed to send the invitation. Please try again.');
        }
      });
  };



  /* end of EV FUNCTIONS */

  const handleFileSelect = async (event) => {
    // setFile(event.target.files[0]);
    // Get the selected file from the event
    const selectedFile = event.target.files[0];

    // Update the file state
    setFile(selectedFile);

    if (file) {
      const formData = new FormData();
      formData.append("file", selectedFile);

      try {
        const response = await fetch(`${ApiUrl}/api/file-check`, {
          method: "POST",
          body: formData,
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,  // JWT Authorization
          },
        });

        const data = await response.json();

        console.log("API Response:", data);

        if (response.ok) {
          // console.log(data); // This should log 'boo' from your Flask rou
          const parsedData = JSON.parse(data.dfSelectedMeterDataImportPlot);
          setLineChartImportFilePlot(parsedData)

          // const parsedKeyFileMetrics = JSON.parse(data.keyFileMetrics);
          setKeyFileMetrics(data.keyFileMetrics)

          // Check if export data is present and set it
          if (data.dfSelectedMeterDataExportPlot) {
            const parsedExportData = JSON.parse(data.dfSelectedMeterDataExportPlot);
            setLineChartExportFilePlot(parsedExportData);
          } else {
            setLineChartExportFilePlot(null); // Reset export data if not present
          }

        } else {
          // Handle HTTP error response
          console.error('Server error:', data);
        }
      } catch (error) {
        console.error('Network error:', error);
      }
    }
  };

  const fetchLastFileData = async () => {
    // Get userToken from localStorage
    const userToken = localStorage.getItem('token');

    try {
      const response = await fetch(`${ApiUrl}/api/get-last-file`, {
        method: 'GET',
        headers: {
          'Authorization': 'Bearer ' + userToken,  // The JWT for the logged-in user
        },
      });

      const data = await response.json();

      console.log("API Response fetchLastFileData:", data);

      if (response.ok) {
        // console.log(data); // This should log 'boo' from your Flask rou
        const parsedData = JSON.parse(data.dfSelectedMeterDataImportPlot);
        setLineChartImportFilePlot(parsedData)

        // const parsedKeyFileMetrics = JSON.parse(data.keyFileMetrics);
        setKeyFileMetrics(data.keyFileMetrics)

        // Check if export data is present and set it
        if (data.dfSelectedMeterDataExportPlot) {
          const parsedExportData = JSON.parse(data.dfSelectedMeterDataExportPlot);
          setLineChartExportFilePlot(parsedExportData);
        } else {
          setLineChartExportFilePlot(null); // Reset export data if not present
        }

      } else {
        // Handle HTTP error response
        console.error('Server error:', data);
      }
    } catch (error) {
      console.error('Network error:', error);
    }
  };




  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleFileSummary = async () => {
    try {
      if (!file) {
        throw new Error("No file selected for summary processing.");
      }

      const formData = new FormData();
      formData.append('file', file);

      // Call the new file summary endpoint
      const response = await fetch(`${ApiUrl}/api/file-summary`, {
        method: 'POST',
        body: formData,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`, // Add the JWT token here
        },
      });

      if (response.ok) {
        const data = await response.json();
        const summary = data.file_summary;

        console.log('File summary received:', summary);

        // Construct summary lines directly inside handleFileSummary
        const summaryLines = [
          // `Meter data begins on: ${summary.MeterStartDate}`,
          // `Meter data ends on: ${summary.MeterEndDate}`,
          `Days of meter data available: ${summary.MeteredNumberOfDays}`,
          `Selected meter data begins on: ${summary.SelectedStartDate}`,
          `Selected meter data ends on: ${summary.SelectedEndDate}`,
          `Number of days that will be shown in your report: ${summary.SelectedNumberOfDays}`,
          `Energy imported during the selected period (kWh): ${summary.EnergyImportedSelectedPeriod}`,
          `Highest energy consumption recorded in a single day (kWh): ${summary.MaxImportDayEnergy}`,
          `This occurred on: ${summary.strMaxImportDate}`,
          `Night-time energy imported (kWh): ${summary.NightEnergyImported}`,
          `Percentage of energy used at night: ${summary.PercentageUsedAtNight}%`
        ];

        // Update the report lines using setReportLines function
        setReportLines(summaryLines);
        setLoadingReport(false); // If you want to hide the loading indicator

        setResetKey(prevKey => prevKey + 1);  // Increment the resetKey to trigger the typewriter reset
        setStartTyping(false);  // Stop typing
        setTimeout(() => {
          setStartTyping(true);  // Start typing again
        }, 100);  // Small timeout to ensure reset logic runs smoothly

        // setShowReportReadyButton(true); // Show the "View my savings!" button
      } else {
        console.error('Error fetching file summary:', response.statusText);
      }
    } catch (error) {
      console.error('Error during file summary processing:', error);
    }
  };

  const handleFileSummaryThenUpload = async () => {
    try {
      // Show the loading spinner and "Your report is being prepared" message
      setShowReportReadyLoading(true);
      setShowReportReadyButton(false); // Ensure the button is hidden initially

      // First call handleFileSummary to get the summary details
      await handleFileSummary(); // Ensure this completes before continuing

      // After file summary, call handleUpload to process the file
      await handleUpload(); // Await this to ensure it finishes before proceeding

      // Once both processes complete, show the "View my savings!" button and hide the spinner
      setShowReportReadyLoading(false); // Hide the spinner
      setShowReportReadyButton(true);   // Show the "View my savings!" button
    } catch (error) {
      console.error("Error occurred while processing file summary or uploading file:", error);
      setShowReportReadyLoading(false); // Hide the spinner on error
    }
  };






  const handleUpload = async (fromUpgrade = false) => {
    // setLoading(true);
    if (file) {
      // setLoading(true);
      if (!fromUpgrade) {
        setLoadingReport(true);
      }
      else {
        setLoadingReportNewSubscriber(true)
      }

      console.log('############### fromUpgrade');
      console.log(fromUpgrade);


      // console.log("Here in handleUpload", selectedPlan)

      const formData = new FormData();
      formData.append("file", file);
      formData.append("selectedSupplier", selectedSupplier);
      formData.append("selectedPlan", selectedPlan);
      formData.append("selectedDiscount", currentDiscount);
      formData.append("callType", "Upload File");  // Added this line
      formData.append("planType", planTypeSelected);
      formData.append("connectionClassification", connectionClassificationSelected);

      try {
        const response = await fetch(`${ApiUrl}/api/upload-csv`, {
          method: "POST",
          body: formData,
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,  // JWT Addition colin
          },
        });

        const data = await response.json();

        if (response.ok) {
          const dfData = { columns: [], values: [] };
          const dfDailyUsage = { columns: [], values: [] };
          const dfCostDiffData = { columns: [], values: [] };

          // dfImpExpDiffData
          const dfImpExpDiffData = { columns: [], values: [] };

          const dfMonthlyImportCostData = { columns: [], values: [] };
          const dfMonthlyExportPaymentsData = { columns: [], values: [] };

          // Set summary values
          if (data.dfSubscriptionType === "free") {
            setSummaryData({
              numberOfCheaperPlans: data.dfSummary.number_of_cheaper_plans,
              maxAnnualSavings: data.dfSummary.max_annual_savings
            });
          }

          // TYPEWRITE SHOULD START HERE REALLY
          console.log('data.dfFileMetadata');
          console.log(data.dfFileMetadata);



          // setStartTyping(true);
          // updateReportLines(data.dfFileMetadata);


          if (data.dfSubscriptionType === "subscriber") {
            if (!initialStartDate && !initialEndDate) {
              setInitialStartDate(new Date(data.dfDateRange.startDate));
              setInitialEndDate(new Date(data.dfDateRange.endDate));
            }

            // Extract columns from the first element of dfResultsSorted
            // dfData.columns = Object.keys(data.dfResultsSorted[0]);
            dfData.columns = Object.keys(data.dfImpExpResults[0]);

            // Extract columns from the first element of dfCostDifferencesSorted
            dfCostDiffData.columns = Object.keys(data.dfCostDifferencesSorted[0]);

            // Extract values from all elements of dfResultsSorted
            // dfData.values = data.dfResultsSorted.map((row) =>
            //   Object.values(row)
            // );
            dfData.values = data.dfImpExpResults.map((row) =>
              Object.values(row)
            );

            // Extract values from all elements of dfCostDifferencesSorted
            dfCostDiffData.values = data.dfCostDifferencesSorted.map((row) =>
              Object.values(row)
            );

            // Log the incoming data for dfImpExpDifferencesSorted
            console.log("dfImpExpDifferencesSorted raw data:", data.dfImpExpDifferencesSorted);

            if (data.dfImpExpDifferencesSorted.length > 0) {
              // Log the first row of data for debugging purposes
              console.log("First row of dfImpExpDifferencesSorted:", data.dfImpExpDifferencesSorted[0]);

              // Assign columns from the first row
              dfImpExpDiffData.columns = Object.keys(data.dfImpExpDifferencesSorted[0]);

              // Assign values by mapping through each row
              dfImpExpDiffData.values = data.dfImpExpDifferencesSorted.map((row) => Object.values(row));

              // Log the resulting dfImpExpDiffData for debugging
              console.log("dfImpExpDiffData after processing:", dfImpExpDiffData);
            } else {
              console.error("dfImpExpDifferencesSorted is empty!");
            }

            // Extract columns from the first element of dfDailyLoadProfiles
            dfDailyUsage.columns = Object.keys(data.dfDailyLoadProfiles[0]);

            // Extract values from all elements of dfDailyLoadProfiles
            dfDailyUsage.values = data.dfDailyLoadProfiles.map((row) =>
              Object.values(row)
            );

            setDfData(dfData);
            setDfDailyUsage(dfDailyUsage); // Set dfDailyUsage state

            // New averages 

            setDfAverageLoadByWeekday(data.dfAverageLoadByWeekday);
            setDfMonthlyEnergyUsage(data.dfMonthlyEnergyUsage);
            setDfCostDiffData(dfCostDiffData); // Set dfCostDiffData state
            setDfImpExpDiffData(dfImpExpDiffData);
            setDfSelectedPlan(data.dfSelectedPlan);
            setLoading(false);

            console.log("*** handleUpload dfDateRange Start Date:", data.dfDateRange.startDate);
            console.log("*** handleUpload dfDateRange End Date:", data.dfDateRange.endDate);

            // Set the start and end dates using dfDateRange from the response
            setStartDate(new Date(data.dfDateRange.startDate));
            setEndDate(new Date(data.dfDateRange.endDate));

            // console.log("Start Date:", new Date(data.dfDateRange.startDate).toLocaleDateString('en-GB'));
            // console.log("End Date:", new Date(data.dfDateRange.endDate).toLocaleDateString('en-GB'));

            // console.log("$$$$$$$ data.energyExported:", data.energyExported);

            setEnergyExported(data.energyExported);

            // console.log("energyExported value after setting in upload csv:", energyExported);

            // Log the dfPriceVsUsage data received from the response
            // console.log("Received dfPriceVsUsage data:", data.dfPriceVsUsage);

            setPriceVsUsage(data.dfPriceVsUsage);
            setWeekPriceVsUsage(data.dfWeekPriceVsUsage)
            // console.log("Received dfPricesVsLoadProfiles data:", data.dfPricesVsLoadProfiles);
            setWeekPriceVsUsageAllPlans(data.dfPricesVsLoadProfiles);

            // console.log('data.dfMonthlyImportCost');
            // console.log(data.dfMonthlyImportCost);

            // Check if dfMonthlyImportCost data is available in the response
            if (data.dfMonthlyImportCost && data.dfMonthlyImportCost.length > 0) {
              // Initialize an object to hold the columns and values

              // Extract columns from the first element of dfMonthlyImportCost
              dfMonthlyImportCostData.columns = Object.keys(data.dfMonthlyImportCost[0]);

              // console.log('HandleUpload dfMonthlyImportCostData.columns :');
              // console.log(dfMonthlyImportCostData.columns);

              // Extract values from all elements of dfMonthlyImportCost
              dfMonthlyImportCostData.values = data.dfMonthlyImportCost.map((row) =>
                Object.values(row)
              );

              // Now you can set the state with this structured data
              setDfMonthlyImportCost(dfMonthlyImportCostData);
            } else {
              // Handle the case where dfMonthlyImportCost data is not available
              setDfMonthlyImportCost(null);
            }

            // Check if dfMonthlyImportCost data is available in the response
            if (data.dfMonthlyExportPayments && data.dfMonthlyExportPayments.length > 0) {
              // Initialize an object to hold the columns and values


              // Extract columns from the first element of dfMonthlyImportCost
              dfMonthlyExportPaymentsData.columns = Object.keys(data.dfMonthlyExportPayments[0]);

              // console.log('HandleUpload dfMonthlyExportPaymentsData.columns :');
              // console.log(dfMonthlyExportPaymentsData.columns);

              // Extract values from all elements of dfMonthlyImportCost
              dfMonthlyExportPaymentsData.values = data.dfMonthlyExportPayments.map((row) =>
                Object.values(row)
              );

              // Now you can set the state with this structured data
              setDfMonthlyExportPayments(dfMonthlyExportPaymentsData);
            } else {
              // Handle the case where dfMonthlyImportCost data is not available
              setDfMonthlyExportPayments(null);
            }

            // console.log('HandleUpload dfMonthlyExportPayments :');
            // console.log(dfMonthlyExportPayments);

            // drop down for comparison line chart
            // console.log("priceVsUsage value after setting in upload csv:", priceVsUsage);



            // if (dfData) {
            //   reportRef.current.scrollIntoView({ behavior: 'smooth' });
            // }
          }
          else {
            setSubscribeSummaryCard("true");
            subscribeRef.current.scrollIntoView({ behavior: 'smooth' });
            // setStartTyping(true);
          }
          // console.log('handleupload setStartTyping');
          // setStartTyping(true);
        }
        else if (data.error) {
          // Open the error dialog if there's an error in the response
          setLoading(false);
          setFileErrorDialogOpen(true);
        }

      } catch (error) {
        console.error(error);
      }
    }
    setTimeout(() => {
      setLoading(false);
    }, 3000);

    if (fromUpgrade) {
      setLoadingReportNewSubscriber(false)
    }

    // Add this to print out the content of dfImpExpDiffData to console
    console.log("dfImpExpDiffData JSON content:", dfImpExpDiffData);
  };

  useEffect(() => {
    console.log('******UPDATED dfMonthlyImportCost:', dfMonthlyImportCost);
  }, [dfMonthlyImportCost]);

  useEffect(() => {
    console.log('Updated dfImpExpDiffData:', dfImpExpDiffData);
  }, [dfImpExpDiffData]);



  const handleDateFilter = async () => {
    // console.log('££££££££££ Date filter started');
    setLoading(true);

    const fromDateString = startDate ? format(startDate, "yyyy-MM-dd'T'HH:mm") : null;
    const toDateString = endDate ? format(endDate, "yyyy-MM-dd'T'HH:mm") : null;

    // console.log(`££££££££££ Formatted startDate: ${fromDateString}`); // Log the formatted start date
    // console.log(`££££££££££ Formatted endDate: ${toDateString}`); // Log the formatted end date

    const formData = new FormData();
    formData.append("file", file);
    formData.append("selectedSupplier", selectedSupplier);
    formData.append("selectedPlan", selectedPlan);
    formData.append("selectedDiscount", currentDiscount);
    formData.append("callType", "Date Filter");
    formData.append("planType", planTypeSelected);

    if (fromDateString) {
      formData.append('from_date', fromDateString);
    }
    if (toDateString) {
      formData.append('to_date', toDateString);
    }

    if (cars.length > 0 && cars[0].make) {
      const carsData = cars.map((car) => ({
        make: car.make,
        model: car.model,
        efficiency: car.efficiency,
        estimatedMileage: car.mileage,
      }));
      formData.append("cars", JSON.stringify(carsData));
    }

    // console.log('££££££££££ Sending request to server with formData:', formData); // Before sending the request


    try {
      const response = await fetch(`${ApiUrl}/api/upload-csv`, {
        method: "POST",
        body: formData,
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,  // JWT Addition colin
        },
      });

      const data = await response.json();

      // console.log('££££££££££ Response from server received', data);

      const dfData = { columns: [], values: [] };
      const dfDailyUsage = { columns: [], values: [] };
      const dfCostDiffData = { columns: [], values: [] };

      dfData.columns = Object.keys(data.dfResultsSorted[0]);
      dfCostDiffData.columns = Object.keys(data.dfCostDifferencesSorted[0]);
      dfData.values = data.dfResultsSorted.map((row) => Object.values(row));
      dfCostDiffData.values = data.dfCostDifferencesSorted.map((row) => Object.values(row));
      dfDailyUsage.columns = Object.keys(data.dfDailyLoadProfiles[0]);
      dfDailyUsage.values = data.dfDailyLoadProfiles.map((row) => Object.values(row));

      setDfData(dfData);
      setDfDailyUsage(dfDailyUsage);
      setDfAverageLoadByWeekday(data.dfAverageLoadByWeekday);
      setDfMonthlyEnergyUsage(data.dfMonthlyEnergyUsage);
      setDfCostDiffData(dfCostDiffData);
      setDfSelectedPlan(data.dfSelectedPlan);

      setFilteredDates({
        start: startDate,
        end: endDate
      });

      setLoading(false);

      console.log(`££££££££££ Date filter applied from ${startDate} to ${endDate}`);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const populateDashboard = (data) => {

    console.log("Plans when populating dashboard: ", plans);

    console.log("Distinct suppliers in plans:", [...new Set(plans.map((plan) => plan.Supplier))]);


    const dfData = { columns: [], values: [] };
    const dfDailyUsage = { columns: [], values: [] };
    const dfCostDiffData = { columns: [], values: [] };

    // dfImpExpDiffData
    const dfImpExpDiffData = { columns: [], values: [] };

    const dfMonthlyImportCostData = { columns: [], values: [] };
    const dfMonthlyExportPaymentsData = { columns: [], values: [] };

    // Extract columns from the first element of dfResultsSorted
    // dfData.columns = Object.keys(data.dfResultsSorted[0]);
    dfData.columns = Object.keys(data.dfImpExpResults[0]);

    // Extract columns from the first element of dfCostDifferencesSorted
    dfCostDiffData.columns = Object.keys(data.dfCostDifferencesSorted[0]);

    // Extract values from all elements of dfResultsSorted
    // dfData.values = data.dfResultsSorted.map((row) =>
    //   Object.values(row)
    // );

    dfData.values = data.dfImpExpResults.map((row) =>
      Object.values(row)
    );


    // Extract values from all elements of dfCostDifferencesSorted
    dfCostDiffData.values = data.dfCostDifferencesSorted.map((row) =>
      Object.values(row)
    );

    // Log the incoming data for dfImpExpDifferencesSorted
    console.log("dfImpExpDifferencesSorted raw data:", data.dfImpExpDifferencesSorted);

    if (data.dfImpExpDifferencesSorted.length > 0) {
      // Log the first row of data for debugging purposes
      console.log("First row of dfImpExpDifferencesSorted:", data.dfImpExpDifferencesSorted[0]);

      // Assign columns from the first row
      dfImpExpDiffData.columns = Object.keys(data.dfImpExpDifferencesSorted[0]);

      // Assign values by mapping through each row
      dfImpExpDiffData.values = data.dfImpExpDifferencesSorted.map((row) => Object.values(row));

      // Log the resulting dfImpExpDiffData for debugging
      console.log("dfImpExpDiffData after processing:", dfImpExpDiffData);
    } else {
      console.error("dfImpExpDifferencesSorted is empty!");
    }

    // Extract columns from the first element of dfDailyLoadProfiles
    dfDailyUsage.columns = Object.keys(data.dfDailyLoadProfiles[0]);

    // Extract values from all elements of dfDailyLoadProfiles
    dfDailyUsage.values = data.dfDailyLoadProfiles.map((row) =>
      Object.values(row)
    );

    // Duel fuel or electric only
    const selectedPlanType = data.dfSelectedPlan.selected_plan_type;

    handlePlanTypeSelection(selectedPlanType);

    const connectionClass = data.dfConnectionClassification;
    setConnectionClassificationSelected(connectionClass);

    // console.log("data.dfSelectedPlan : ", data.dfSelectedPlan);

    setDfData(dfData);

    // console.log("BLAHHHHHHH");
    // console.log(JSON.stringify(dfDailyUsage, null, 2));

    // console.log("dfDailyUsage");
    // console.log(dfDailyUsage);
    // console.log("************************************* before dfDailyUsage :");
    setDfDailyUsage(dfDailyUsage); // Set dfDailyUsage state
    setDfAverageLoadByWeekday(data.dfAverageLoadByWeekday);
    setDfMonthlyEnergyUsage(data.dfMonthlyEnergyUsage);

    // console.log("dfCostDiffData");
    // console.log(JSON.stringify(dfCostDiffData));

    setDfCostDiffData(dfCostDiffData); // Set dfCostDiffData state
    setDfImpExpDiffData(dfImpExpDiffData);
    setDfSelectedPlan(data.dfSelectedPlan);


    // Set energyExports
    // console.log("************************************* populateDashboard energyExported :");
    // console.log(energyExported);
    setEnergyExported(energyExported);


    // Inside the populateDashboard or relevant function
    console.log("*** populateDashboard dfDateRange Start Date:", data.dfDateRange.startDate);
    console.log("*** populateDashboard dfDateRange End Date:", data.dfDateRange.endDate);

    // Set the start and end dates using dfDateRange from the response
    setStartDate(new Date(data.dfDateRange.startDate));
    setEndDate(new Date(data.dfDateRange.endDate));

    // console.log("populateDashboard() Selected supplier from latest report: ", data.dfSelectedPlan.selected_supplier);
    // console.log("populateDashboard() Selected plan from latest report: ", data.dfSelectedPlan.selected_plan);

    // // This will log the current state of planOptions when populateDashboard is called
    // console.log("populateDashboard() Current planOptions state: ", planOptions);

    setSelectedSupplier(data.dfSelectedPlan.selected_supplier.trim());


    // Set the plan options for the selected supplier
    // setPlanOptionsForSupplier(data.dfSelectedPlan.selected_supplier);

    setSelectedPlan(data.dfSelectedPlan.selected_plan); // Set selectedPlan state
    setCurrentDiscount(data.dfSelectedPlan.selected_discount); // Set currentDiscount state
    setDualFuel(data.dfSelectedPlan.selected_plan_type);

    // setPlanOptionsForSupplier(data.dfSelectedPlan.selected_supplier.trim());

    // New Addition: Check if dfWeekPriceVsUsage exists in the response
    if (data.dfWeekPriceVsUsage) {
      // If it exists, set the state for WeekPriceVsUsage
      setWeekPriceVsUsage(data.dfWeekPriceVsUsage);
    }

    if (data.dfPricesVsLoadProfiles) {
      setWeekPriceVsUsageAllPlans(data.dfPricesVsLoadProfiles);
    }

    // Check if dfMonthlyImportCost data is available in the response
    if (data.dfMonthlyImportCost && data.dfMonthlyImportCost.length > 0) {
      // Initialize an object to hold the columns and values


      // Extract columns from the first element of dfMonthlyImportCost
      dfMonthlyImportCostData.columns = Object.keys(data.dfMonthlyImportCost[0]);

      // console.log('HandleUpload dfMonthlyImportCostData.columns :');
      // console.log(dfMonthlyImportCostData.columns);

      // Extract values from all elements of dfMonthlyImportCost
      dfMonthlyImportCostData.values = data.dfMonthlyImportCost.map((row) =>
        Object.values(row)
      );

      // Now you can set the state with this structured data
      setDfMonthlyImportCost(dfMonthlyImportCostData);
    } else {
      // Handle the case where dfMonthlyImportCost data is not available
      setDfMonthlyImportCost(null);
    }

    // Check for dfDateRangeSelected and update date range if it exists
    if (data.dfDateRangeSelected) {
      setStartDate(new Date(data.dfDateRangeSelected.startDate));
      setEndDate(new Date(data.dfDateRangeSelected.endDate));
    }

    // Check if car selection data exists and update EV card states
    if (data.dfCarSelection) {
      setCars(data.dfCarSelection.map(car => ({
        make: car.make,
        model: car.model,
        efficiency: car.efficiency,
        estimatedMileage: car.estimatedMileage
      })));
      setShowEVCard(true); // Ensure the EV card is visible if cars are present
    } else {
      setCars([]); // Reset cars if none are found
      setShowEVCard(false); // Hide the EV card if no cars are present
    }

    // Check if dfMonthlyImportCost data is available in the response
    if (data.dfMonthlyExportPayments && data.dfMonthlyExportPayments.length > 0) {
      // Initialize an object to hold the columns and values


      // Extract columns from the first element of dfMonthlyImportCost
      dfMonthlyExportPaymentsData.columns = Object.keys(data.dfMonthlyExportPayments[0]);

      // console.log('HandleUpload dfMonthlyExportPaymentsData.columns :');
      // console.log(dfMonthlyExportPaymentsData.columns);

      // Extract values from all elements of dfMonthlyImportCost
      dfMonthlyExportPaymentsData.values = data.dfMonthlyExportPayments.map((row) =>
        Object.values(row)
      );

      // Now you can set the state with this structured data
      setDfMonthlyExportPayments(dfMonthlyExportPaymentsData);

      console.log("In populateDashboard : end of function");
    } else {
      // Handle the case where dfMonthlyImportCost data is not available
      setDfMonthlyExportPayments(null);
    }

    // if (dfData) {
    //   reportRef.current.scrollIntoView({ behavior: 'smooth' });
    // }
  };

  useEffect(() => {
    console.log(" populateDashboard()  selectedPlan: ", selectedPlan);
  }, [selectedPlan]);

  useEffect(() => {
    console.log(" populateDashboard()  plans: ", plans);
  }, [plans]);

  // useEffect(() => {
  //   if (selectedSupplier) {
  //     console.log(" useEffect() setPlanOptionsForSupplier --- selectedSupplier: ", selectedSupplier);
  //     setPlanOptionsForSupplier(selectedSupplier);
  //   }
  // }, [selectedSupplier]);

  useEffect(() => {
    if (selectedSupplier && plans.length > 0) {
      console.log("useEffect() setPlanOptionsForSupplier --- selectedSupplier and plans are set, now calling setPlanOptionsForSupplier");
      setPlanOptionsForSupplier(selectedSupplier);
    } else {
      console.log("useEffect() setPlanOptionsForSupplier --- waiting for selectedSupplier and plans to be set");
    }
  }, [selectedSupplier, plans]);

  useEffect(() => {
    console.log("Fetching user data with token:", localStorage.getItem('token'));
    // Your fetch call here to get user data
  }, []);

  useEffect(() => {
    console.log('*** Current Start Date:', startDate);
    console.log('*** Current End Date:', endDate);
  }, [startDate, endDate]);

  const [currentLine, setCurrentLine] = useState(0);

  useEffect(() => {
    if (loadingReport && currentLine < typedLines.length) {
      const intervalId = setInterval(() => {
        setCurrentLine(prev => prev + 1);
      }, 300); // 1-second interval

      return () => clearInterval(intervalId); // Cleanup interval on unmount
    }
  }, [loadingReport, currentLine, typedLines]);



  const setPlanOptionsForSupplier = (supplier) => {
    console.log("BOO Supplier: ", supplier);
    console.log("BOO Plans: ", plans);
    // Filter the plans based on the selected supplier
    const supplierPlans = plans.filter((plan) => plan.Supplier === supplier);
    const supplierPlanOptions = supplierPlans.map((plan) => plan.Tariff);

    console.log("BOO supplierPlanOptions: ", supplierPlanOptions);
    setPlanOptions(supplierPlanOptions);
  };





  const columnTooltips = {
    "Supplier": "Energy supplier",
    "Tariff": "Smart meter plan name",
    "Discount %": "Standard discount as presented on Supplier website.",
    "Unit savings based on your smart meter usage data": "Savings of each plan compared to plan you are currently on as you selected in Step 1. A positive number indicates a plan that is cheaper than your current plan. A negative number indicates a plan that is more expensive than your current plan.",
    "EV charging savings": "Savings of each plan specifically to charging an EV as simulated in Section 3, if an EV is selected. A positive number indicates a plan that is cheaper than your current plan. A negative number indicates a plan that is more expensive than your current plan.",
    "Total savings based on your smart meter usage data": "Total savings taking standing charges, PSO Levy and VAT into account.",
    "Total savings including simulated EV charging": "Same as previous column but with EV charging taken into account, as simulated in Section 3.",
    "Export Payment Difference": "Difference in export payments between each plan and your current plan. A positive number indicates a plan that has higher export payments than your current plan. Export payments are credited to your bill when you are sending back power to the electricity grid.",
    "Total savings including export payments": "Total savings taking standing charges, PSO Levy and VAT into account, as well as any export payments. It does not include simulated EV charging costs.",
    "Total savings including export payments and simulated EV charging": "Total savings taking standing charges, PSO Levy and VAT into account, as well as any export payments. It also includes simulated EV charging costs.",
  };



  let dfDataColumnOrder = [
    "#",
    "Smart Tariff",
    "Supplier",
    "Tariff",
    "Total Cost",
    "Discount %",
    "EV Charging Cost",
    // "Unit Cost",
    "Standing Charge",
    "PSO Levy",
    "VAT",

  ];

  let dfMonthlyImportCostColumns = [
    "#",
    "Smart Tariff",
    "Supplier",
    "Tariff",
    "Discount %",
    "Net Cost Including Generation",
    "Net Cost Including Generation and EV Charging Simulation",
    "Total Cost with EV",
    "VAT with EV",


    // Add the month columns dynamically here
  ];

  let dfMonthlyExportPaymentsColumns = [
    "#",
    "Smart Tariff",
    "Supplier",
    "Tariff",
    "Discount %",
    "Net Cost Including Generation",
    "Net Cost Including Generation and EV Charging Simulation",
    "Total Earnings",
    // Add the month columns dynamically here
  ];


  const simulationColumnsOrder = ["Sim Total Cost", "Sim VAT"];

  function getOrderedMonthColumns(columns, orderlist) {
    const excludedColumns = new Set([...orderlist, ...simulationColumnsOrder]);
    const monthColumns = columns.filter((col) => !excludedColumns.has(col));

    // Create a month lookup to convert month name to number
    const monthLookup = {
      January: '01',
      February: '02',
      March: '03',
      April: '04',
      May: '05',
      June: '06',
      July: '07',
      August: '08',
      September: '09',
      October: '10',
      November: '11',
      December: '12'
    };

    const orderedMonthColumns = monthColumns
      .sort((a, b) => {
        // Split string into [month, year]
        const [monthA, yearA] = a.split(' ');
        const [monthB, yearB] = b.split(' ');

        // Create date strings in the format 'YYYY-MM-DD'
        const dateStrA = `${yearA}-${monthLookup[monthA]}-01`;
        const dateStrB = `${yearB}-${monthLookup[monthB]}-01`;

        // Parse date strings into date objects
        const dateA = new Date(dateStrA);
        const dateB = new Date(dateStrB);

        // Sort in descending order
        return dateB - dateA;
      });

    // Check if simulation columns exist in the data before adding them to the final column order
    const existingSimulationColumns = simulationColumnsOrder.filter((col) => columns.includes(col));

    // Split dfDataColumnOrder into two parts at the 'Total Cost' index, and place existing simulation columns in between
    const splitIndex = orderlist.indexOf('Total Cost') + 1;
    const firstPart = orderlist.slice(0, splitIndex);
    const secondPart = orderlist.slice(splitIndex);

    // Return the new column order
    return [...firstPart, ...existingSimulationColumns, ...secondPart, ...orderedMonthColumns];
  }

  // Update dfDataColumnOrder only when dfData is not null
  if (dfData) {
    // console.log('dfData structure:', dfData);
    // console.log('dfData columns:', dfData.columns);
    // console.log('dfData values:', dfData.values);
    dfDataColumnOrder = getOrderedMonthColumns(dfData.columns, dfDataColumnOrder);
  }

  // if (dfMonthlyImportCost) {
  //   dfMonthlyImportCostColumnOrder = getOrderedMonthColumns(dfMonthlyImportCost.columns);
  // }
  // let dfMonthlyImportCostColumns;
  let dfMonthlyImportCostColumnOrder;

  if (dfMonthlyImportCost) {
    // console.log('dfMonthlyImportCost structure:', dfMonthlyImportCost);
    // console.log('dfMonthlyImportCost columns:', dfMonthlyImportCost.columns);
    // console.log('dfMonthlyImportCost values:', dfMonthlyImportCost.values);
    // dfMonthlyImportCostColumns = Object.keys(dfMonthlyImportCost[0]);
    dfMonthlyImportCostColumnOrder = getOrderedMonthColumns(dfMonthlyImportCost.columns, dfMonthlyImportCostColumns);
    // console.log('dfMonthlyImportCostColumnOrder :');
    // console.log(dfMonthlyImportCostColumnOrder);
  }

  let dfMonthlyExportPaymentsColumnOrder;

  if (dfMonthlyExportPayments) {
    // console.log('dfMonthlyExportPayments structure:', dfMonthlyExportPayments);
    // console.log('dfMonthlyExportPayments columns:', dfMonthlyExportPayments.columns);
    // console.log('dfMonthlyExportPayments values:', dfMonthlyExportPayments.values);
    // dfMonthlyImportCostColumns = Object.keys(dfMonthlyImportCost[0]);
    dfMonthlyExportPaymentsColumnOrder = getOrderedMonthColumns(dfMonthlyExportPayments.columns, dfMonthlyExportPaymentsColumns);
    // console.log('dfMonthlyExportPaymentsColumnOrder :');
    // console.log(dfMonthlyExportPaymentsColumnOrder);
  }



  function processDailyUsageData(dfData) {
    if (!dfData || !Array.isArray(dfData.columns) || !Array.isArray(dfData.values)) return [];

    // console.log('Inside processDailyUsageData function');
    // console.log('dfData.columns');
    // console.log(dfData.columns);


    const columnIndices = {
      weekday: dfData.columns.indexOf('Weekday'),
      time: dfData.columns.indexOf('Time'),
      energy: dfData.columns.indexOf('Energy')
    };

    // Check if all columns were found
    if (Object.values(columnIndices).includes(-1)) {
      console.error('One or more required columns were not found in dfData.');
      return [];
    }

    const processedData = dfData.values.map(row => ({
      weekday: row[columnIndices.weekday],
      time: row[columnIndices.time],
      energy: row[columnIndices.energy]
    }));

    return processedData;
  }




  // console.log('dfSelectedPlan to see what it is set to @@@@@@@@@@@@@@@@@@@@');
  // console.log(dfSelectedPlan);

  const allPlans = Object.keys(weekPriceVsUsageAllPlans[0] || {})
    .filter(key => key.includes('|') && key !== `${dfSelectedPlan.selected_supplier} | ${dfSelectedPlan.selected_plan}}`);


  /*  SIM VAT - This column is the VAT on the metered electricity plus simulated EV charging. 
      SIM Total Cost - This column is the total cost of the metered electricity plus simulated EV charging cost. 
  
  
      EV tooltip - EV charging cost simulations are carried out using the following assumptions. 
      1. Car is charged each day. 
      2. Smart charging is utilised to ensure the car charges at the cheapest possible rate for each plan. 
      3. The efficiency of the charging process is assumed to be 90%. For example, if 10 units of electricity are used for EV charging, 8 units will end up in your EV.

  */
  function renderHomepage() {
    return (
      <div className="card-group-container" style={{ paddingTop: '90px', marginLeft: isMobile ? "20px" : "110px" }}>


        <Card variant="outlined" style={{ marginBottom: "20px", backgroundColor: "#DAECF3" }}>
          <CardContent>
            <Typography variant="h5" gutterBottom>
              Steps:
            </Typography>
            <Stepper activeStep={0} alternativeLabel sx={{ "& .MuiStepIcon-root.Mui-active": { color: "#FE424D" }, "& .MuiStepIcon-root": { color: "grey" }, "& .MuiStepLabel-label.Mui-active": { color: "#FE424D" }, "& .MuiStepLabel-label": { color: "grey" } }}>
              {getSteps().map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </CardContent>
        </Card>

        {(tabValue === 0 || tabValue === 1) && (
          <div id="conditionalCards">
            {/* Supplier and Plan selection */}

            {
              showPlanTypeCard && (
                <Card style={{ marginBottom: "20px", position: "relative", backgroundColor: "#DAECF3", padding: isMobile ? "10px" : "20px" }}>
                  {/* Circle with the step number */}
                  <div
                    style={{
                      position: "absolute",
                      top: "10px",
                      left: "10px",
                      width: "50px",
                      height: "50px",
                      border: "5px solid #FE424D",
                      borderRadius: "50%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      color: "#FE424D",
                      fontSize: "24px",
                    }}
                  >
                    1
                  </div>
                  {/* Card content */}
                  <CardContent style={{ marginTop: "60px", marginLeft: isMobile ? "0px" : "80px", display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                    <Typography variant="h6" component="h2" style={{ marginBottom: "10px" }}>
                      Please select your current plan type.
                    </Typography>
                    <div style={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', gap: '10px', width: isMobile ? '100%' : 'auto' }}>
                      <Button
                        variant="contained"
                        style={{
                          backgroundColor: planTypeSelected === 'electricOnly' ? "rgb(254, 66, 77)" : "grey",
                          color: "white",
                          width: isMobile ? '100%' : 'auto',
                        }}
                        onClick={() => handlePlanTypeSelection('electricOnly')}
                      >
                        Electricity Only
                      </Button>
                      <Button
                        variant="contained"
                        style={{
                          backgroundColor: planTypeSelected === 'dualFuel' ? "rgb(254, 66, 77)" : "grey",
                          color: "white",
                          width: isMobile ? '100%' : 'auto',
                        }}
                        onClick={() => handlePlanTypeSelection('dualFuel')}
                      >
                        Electricity and Gas
                      </Button>
                    </div>
                  </CardContent>
                </Card>
              )
            }

            {showSelectSupplier && (
              <Card style={{ marginBottom: "20px", position: "relative", backgroundColor: "#DAECF3", padding: isMobile ? "10px" : "20px" }}>
                {/* Circle with the step number */}
                <div
                  style={{
                    position: "absolute",
                    top: "10px",
                    left: "10px",
                    width: "50px",
                    height: "50px",
                    border: "5px solid #FE424D",
                    borderRadius: "50%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: "#FE424D",
                    fontSize: "24px",
                  }}
                >
                  2
                </div>
                {/* Card content */}
                <CardContent style={{ marginTop: "60px", marginLeft: isMobile ? "0px" : "80px", display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                  <Typography variant="h6" component="h2" style={{ marginBottom: "10px" }}>
                    Please select your connection classification.
                  </Typography>
                  <div style={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', gap: '10px', width: isMobile ? '100%' : 'auto' }}>
                    <Button
                      variant="contained"
                      style={{
                        backgroundColor: connectionClassificationSelected === 'urban' ? "rgb(254, 66, 77)" : "grey",
                        color: "white",
                        width: isMobile ? '100%' : 'auto',
                      }}
                      onClick={() => handleConnectionClassificationSelection('urban')}
                    >
                      Urban (DG1)
                    </Button>
                    <Button
                      variant="contained"
                      style={{
                        backgroundColor: connectionClassificationSelected === 'rural' ? "rgb(254, 66, 77)" : "grey",
                        color: "white",
                        width: isMobile ? '100%' : 'auto',
                      }}
                      onClick={() => handleConnectionClassificationSelection('rural')}
                    >
                      Rural (DG2)
                    </Button>
                  </div>
                </CardContent>
              </Card>

            )}

            {showSelectSupplier && (
              <Card style={{ marginBottom: "20px", position: "relative", backgroundColor: "#DAECF3" }}>
                <div
                  style={{
                    position: "absolute",
                    top: "10px",
                    left: "10px",
                    width: "50px",
                    height: "50px",
                    border: "5px solid #FE424D",
                    borderRadius: "50%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: "#FE424D",
                    fontSize: "24px",
                  }}
                >
                  3
                </div>
                <CardContent style={{ marginTop: "60px", marginLeft: isMobile ? "0" : "80px" }}>
                  <Typography variant="h6" component="h2">
                    Select your current supplier and plan.
                  </Typography>
                  <Typography variant="subtitle1" component="p">
                    This will allow you to compare your plan with the other plans available to you on the market.
                  </Typography>
                  <div
                    style={{
                      display: "flex",
                      marginBottom: "10px",
                      marginTop: "40px",
                      flexDirection: isMobile ? "column" : "row", // Change to "column" on mobile screens
                    }}
                  >
                    <FormControl fullWidth variant="outlined" style={{ marginRight: isMobile ? "0" : "10px", marginBottom: isMobile ? "10px" : "0" }}>
                      <InputLabel
                        sx={{
                          color: selectedSupplier ? "#FE424D" : "rgba(0, 0, 0, 0.54)",
                          "&.Mui-focused": {
                            color: "#FE424D",
                          },
                        }}
                      >
                        Energy Supplier
                      </InputLabel>
                      <Select
                        value={selectedSupplier}
                        label="Energy Supplier"
                        onChange={handleSupplierChange}
                        sx={{
                          "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: "#FE424D",
                          },
                          "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
                            color: "#FE424D",
                          },
                        }}
                      >
                        {suppliers.map((supplier, index) => (
                          <MenuItem key={index} value={supplier}>
                            {supplier}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    <FormControl fullWidth variant="outlined" style={{ marginRight: isMobile ? "0" : "10px", marginBottom: isMobile ? "10px" : "0" }}>
                      <InputLabel
                        sx={{
                          color: selectedSupplier ? "#FE424D" : "rgba(0, 0, 0, 0.54)",
                          "&.Mui-focused": {
                            color: "#FE424D",
                          },
                        }}
                      >
                        Your Current Plan
                      </InputLabel>
                      <Select
                        value={selectedPlan}
                        onChange={handlePlanChange}
                        label="Your Current Plan"
                        disabled={!selectedSupplier}
                        sx={{
                          "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: "#FE424D",
                          },
                          "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
                            color: "#FE424D",
                          },
                        }}
                      >
                        {selectedSupplier &&
                          planOptions.map((option, index) => (
                            <MenuItem key={index} value={option}>
                              {option}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>

                    <TextField
                      label="Your current discount"
                      variant="outlined"
                      fullWidth
                      type="number"
                      value={currentDiscount}
                      onChange={(e) => setCurrentDiscount(e.target.value)}
                      disabled={!selectedSupplier}
                      InputProps={{
                        inputProps: { min: 0 },
                        endAdornment: <InputAdornment position="end">%</InputAdornment>,
                        sx: {
                          "&.Mui-focused": {
                            color: "#FE424D",
                          },
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: selectedSupplier ? "#FE424D" : undefined,
                          },
                        },
                      }}
                      style={{ flexBasis: isMobile ? "100%" : "50%", marginRight: isMobile ? "0" : "10px" }}
                    />
                  </div>
                </CardContent>
              </Card>
            )
            }


            {selectedPlan && (
              <Card style={{ marginBottom: "20px", position: "relative", backgroundColor: "#DAECF3" }}>
                <div
                  style={{
                    position: "absolute",
                    top: "10px",
                    left: "10px",
                    width: "50px",
                    height: "50px",
                    border: "5px solid #FE424D",
                    borderRadius: "50%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: "#E74B18",
                    fontSize: "24px",
                  }}
                >
                  4
                </div>
                <CardContent style={{ marginTop: "60px", marginLeft: isMobile ? "0" : "80px" }}>
                  <Typography variant="h6" component="h2">
                    Upload your smart meter file
                  </Typography>
                  <Typography variant="body1" component="p" sx={{ fontFamily: 'sans-serif' }}>
                    This is your smart meter file and you will need to download this from the{' '}
                    <Link href="https://www.esbnetworks.ie/" target="_blank" rel="noopener noreferrer" style={{ color: 'rgb(254, 66, 77)' }}>
                      ESB Networks
                    </Link>
                    {' '}site. You can also see the steps on how to download this file on our{' '}
                    <Link component="button" variant="body1" onClick={() => setActivePage('howToGuide')} style={{ color: 'rgb(254, 66, 77)' }}>
                      help guide
                    </Link>.
                  </Typography>
                  <div style={{ display: "flex", flexDirection: isMobile ? "column" : "row", alignItems: "center", justifyContent: "center", marginTop: "60px" }}>
                    {/* Select file button */}
                    <label htmlFor="raised-button-file" style={{ marginBottom: isMobile ? "10px" : "0", marginRight: isMobile ? "0" : "10px" }}>
                      <Button
                        variant="contained"
                        component="span"
                        style={{ backgroundColor: "#022D41", color: "white" }}
                      >
                        {hasUploadedReportBefore ? 'SELECT NEW SMART METER FILE' : 'SELECT SMART METER FILE'}
                      </Button>
                      <input
                        accept=".csv"
                        style={{ display: "none" }}
                        id="raised-button-file"
                        multiple
                        type="file"
                        onChange={handleFileSelect}
                      />
                    </label>

                    {/* Display file name */}
                    {file && (
                      <TextField
                        value={file.name}
                        disabled
                        style={{ marginLeft: isMobile ? "0" : "10px", width: isMobile ? "100%" : "300px" }}
                      />
                    )}

                    {/* Generate Report button */}
                    <Button
                      variant="contained"
                      onClick={handleFileSummaryThenUpload}
                      style={{
                        backgroundColor: "#FE424D",
                        marginLeft: isMobile ? "0" : "10px",
                        color: "white",
                      }}
                    >
                      View Your Savings
                    </Button>
                    {loading && <StyledProgress size={100} />}
                  </div>
                  {lineChartImportFilePlot && lineChartImportFilePlot.length > 0 && (
                    <>
                      <div>
                        <LineChartImportFilePlot
                          importData={lineChartImportFilePlot}
                          exportData={lineChartExportFilePlot} // New prop for export data
                        />
                      </div>
                      <div>
                        <p style={{ color: 'rgb(254, 66, 77)' }}>
                          The number days in your smart meter file is <strong>{keyFileMetrics.SelectedNumberOfDays}</strong>.
                          {/* Energy Imported: {keyFileMetrics.EnergyImportedSelectedPeriod.toFixed(2)} kWh */}
                        </p>
                      </div>
                    </>
                  )}
                </CardContent>
              </Card>
            )}

            {
              ((subscriptionType === 'free') && (subscribeSummaryCard === 'true')) && (

                <Card ref={reportRef} style={{ marginBottom: "20px", position: "relative", backgroundColor: "#DAECF3" }}>
                  <div
                    style={{
                      position: "absolute",
                      top: "10px",
                      left: "10px",
                      width: "50px",
                      height: "50px",
                      border: "5px solid #FE424D",
                      borderRadius: "50%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      color: "#E74B18",
                      fontSize: "24px",
                    }}
                  >
                    <Info style={{ color: "#E74B18", fontSize: "24px" }} />
                  </div>
                  <CardContent style={{ marginTop: "20px", textAlign: 'center' }}>
                    <Typography ref={subscribeRef} variant="h4" component="h2" style={{ marginBottom: "20px" }} className="headline">
                      Share PowerToCompare.ie with friends to receive your complimentary report
                    </Typography>
                    {/* <Typography variant="h6" component="h3" style={{ marginBottom: "20px" }} className="subheadline">
                      Please use the following options to share and spread the savings. We appreciate your support.
                    </Typography> */}
                    {/* <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
                      <ShareCard url={shareWdigetUrl} title={shareWdigetTitle} />
                    </div> */}

                    <Typography variant="h4" component="h2" style={{ display: 'flex', justifyContent: 'center', alignItems: 'baseline', flexWrap: 'wrap' }}>
                      There are
                      <span style={{
                        color: "#FE424D",
                        marginLeft: "15px",
                        marginRight: "15px",
                        fontWeight: 'bold',
                        fontSize: '3rem' // This sets the size as it is currently styled for larger screens
                      }} onClick={upgradeUserSubscription}>
                        {summaryData.numberOfCheaperPlans}
                      </span>
                      smart plans better than yours available!
                    </Typography>

                    <Typography variant="h2" style={{ color: "#FE424D", marginTop: "10px", fontWeight: 'bold' }} className="savings-info">
                      €{summaryData.maxAnnualSavings} / year
                    </Typography>
                    <Typography variant="h6" component="h3" className="subheadline">
                      Savings on the best plan.
                    </Typography>
                    {/* <div style={{ marginTop: "30px" }}>
                      <Button variant="contained" color="primary" startIcon={<CheckCircleIcon />} style={{ backgroundColor: "#FE424D", color: "#FFFFFF" }} onClick={upgradeUserSubscription}>
                        Upgrade and Save Now!
                      </Button>
                    </div> */}

                    {/* Promo Benefits Section */}
                    <Typography variant="h5" component="h3" style={{ color: "#FE424D", marginTop: "20px" }} className="promo-section">
                      We save households on average more than 30% on their electricity unit cost.
                    </Typography>
                    {/* <Typography variant="subtitle1" style={{ marginTop: "15px", color: "#022D41" }} className="promo-section">
                      Subscribing helps us maintain this service and you get to check any plan price changes throughout the year.
                    </Typography> */}
                    <Typography variant="h6" component="h3" style={{ marginBottom: "20px" }} className="subheadline">
                      Please use the following options to share and spread the savings. We appreciate your support.
                    </Typography>
                    {/* <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                      <ul style={{ listStyleType: 'circle', textAlign: 'left', paddingInlineStart: '20px', maxWidth: '80%' }} className="benefits-list">
                        <li>Find the most suitable plan for your household.</li>
                        <li>Understand your energy usage.</li>
                        <li>Analyse the benefits of household behaviour change.</li>
                        <li>Detailed cost savings calculator.</li>
                        <li>Detailed monthly and weekly breakdowns with graphs.</li>
                        <li>EV charging cost calculations on all plans. *</li>
                        <li>All EVs on the market included.</li>
                        <li>All Suppliers compared *</li>
                      </ul>
                    </div> */}
                    {/* <InviteForm onAllInvitesSent={(status) => setAllInvitesSent(status)} /> */}
                    <InviteForm
                      apiUrl={ApiUrl}
                      onAllInvitesSent={(status) => setAllInvitesSent(status)}
                      senderName={senderFullName}
                      numberOfPlans={summaryData.numberOfCheaperPlans}  // Assuming these values are dynamically calculated or retrieved
                      savingsPerYear={summaryData.maxAnnualSavings}
                    />



                    {/* <Typography variant="caption" style={{ color: "#022D41" }} className="promo-section">
                      * Some exceptions may apply.
                    </Typography> */}


                    <Button
                      variant="contained"
                      color="primary"
                      style={{
                        backgroundColor: allInvitesSent ? "#FE424D" : "grey",
                        color: "white",
                        pointerEvents: allInvitesSent ? "auto" : "none",
                      }}
                      onClick={allInvitesSent ? upgradeUserSubscription : null} // Make sure `upgradeUserSubscription` is defined
                    >
                      View your free earlybird report!
                    </Button>
                  </CardContent>

                  <style>
                    {`
                      @media (max-width: 600px) {
                        .headline {
                          margin-top: 50px; // Increase top margin to prevent overlap with the info icon
                        }
                        .pricing-info, .savings-info {
                          white-space: nowrap; // Prevent text from wrapping
                          font-size: 1.25rem; // Further reduce font size for mobile
                          overflow: hidden; // Hide overflow if the text is too long
                          text-overflow: ellipsis; // Show an ellipsis if the text overflows
                        }
                        .benefits-list {
                          padding-inline-start: 15px;
                          max-width: 100%;
                        }
                      }
                    `}
                  </style>

                </Card>





              )
            }

            {/* <Card style={{ marginBottom: '20px', border: '1px solid red' }}>
            <CardContent>
              <DateRangePicker
                startText="Start Date"
                endText="End Date"
                value={dateRange}
                onChange={(newValue) => setDateRange(newValue)}
                renderInput={(startProps, endProps) => (
                  <>
                    <TextField {...startProps} inputFormat="MM/dd/yyyy" />
                    <TextField {...endProps} inputFormat="MM/dd/yyyy" />
                  </>
                )}
              />
            </CardContent>
          </Card> */}




            {dfData && dfData.values && (
              <>




                <Card style={{ marginBottom: "20px", position: "relative", backgroundColor: "#DAECF3" }}>
                  <div
                    style={{
                      position: "absolute",
                      top: "10px",
                      left: "10px",
                      width: "50px",
                      height: "50px",
                      border: "5px solid #FE424D",
                      borderRadius: "50%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      color: "#FE424D",
                      fontSize: "24px",
                    }}
                  >
                    EV
                  </div>
                  <CardContent style={{ marginTop: "60px", marginLeft: isMobile ? "0" : "80px" }}>
                    <Typography variant="h6" component="h2">
                      <strong>(Optional)</strong> Thinking of buying an electric vehicle (EV) ?
                    </Typography>
                    {!showEVCard && (
                      <Typography variant="body1" component="p" sx={{ fontFamily: 'sans-serif' }}>
                        We have added an optional EV cost calculator. It will help you see the impact adding an EV will have on the optimal plan for your household.
                      </Typography>
                    )}
                    <div style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
                      <Button
                        variant="contained"
                        onClick={handleToggleEVCard}
                        sx={{
                          bgcolor: '#FE424D',
                          '&:hover': {
                            backgroundColor: '#FE424D', // on hover, the color stays the same
                          },
                        }}
                      >
                        {showEVCard ? 'Hide EV Options' : 'Yes please, show me the EV options.'}
                      </Button>
                    </div>
                    {showEVCard && (
                      <>
                        <Typography variant="body1" component="p" sx={{ fontFamily: 'sans-serif' }}>
                          You can use the following feature to estimate what the likely cost of adding an EV to your house may cost on the different plans from the various energy providers. Simply select your make and model and provide the estimated annual mileage. You can also select the charge power but if you do not know what this is you can leave it at the default value.
                        </Typography>

                        {cars.map((car, index) => (
                          <div key={index} style={{ display: "flex", marginBottom: "10px", marginTop: "40px", flexDirection: isMobile ? "column" : "row" }}>
                            <FormControl fullWidth variant="outlined" style={{ flexBasis: isMobile ? "100%" : "25%", marginRight: isMobile ? "0" : "10px", marginBottom: isMobile ? "10px" : "0" }}>
                              <InputLabel>Car Make</InputLabel>
                              <Select
                                value={car.make}
                                onChange={(e) => handleCarChange(index, "make", e.target.value)}
                                label="Car Make"
                              >
                                {Array.from(new Set(carsData.map((car) => car.make))).map((make) => (
                                  <MenuItem key={make} value={make}>
                                    {make}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                            <FormControl fullWidth variant="outlined" style={{ flexBasis: isMobile ? "100%" : "25%", marginRight: isMobile ? "0" : "10px", marginBottom: isMobile ? "10px" : "0" }}>
                              <InputLabel>Car Model</InputLabel>
                              <Select
                                value={car.model}
                                onChange={(e) => handleCarChange(index, "model", e.target.value)}
                                label="Car Model"
                                disabled={!car.make}
                              >
                                {carsData
                                  .filter((c) => c.make === car.make)
                                  .map((filteredCar) => (
                                    <MenuItem key={filteredCar.model} value={filteredCar.model}>
                                      {filteredCar.model}
                                    </MenuItem>
                                  ))}
                              </Select>
                            </FormControl>
                            <TextField
                              label="Estimated Annual Mileage"
                              variant="outlined"
                              fullWidth
                              type="number"
                              style={{ flexBasis: isMobile ? "100%" : "25%", marginRight: isMobile ? "0" : "10px", marginBottom: isMobile ? "10px" : "0" }}
                              value={car.mileage}
                              onChange={(e) => handleCarChange(index, "mileage", e.target.value)}
                              InputProps={{ endAdornment: <InputAdornment position="end">km</InputAdornment> }}
                            />

                            <TextField
                              label="Charger Power"
                              variant="outlined"
                              fullWidth
                              type="number"
                              style={{ flexBasis: isMobile ? "100%" : "25%", marginRight: isMobile ? "0" : "10px", marginBottom: isMobile ? "10px" : "0" }}
                              value={car.chargerPower || 7.2} // setting default value to 7.2 kW
                              onChange={(e) => handleCarChange(index, "chargerPower", e.target.value)}
                              InputProps={{ endAdornment: <InputAdornment position="end">kW</InputAdornment> }}
                            />

                            <TextField
                              label="Charging Efficiency"
                              variant="outlined"
                              fullWidth
                              type="number"
                              style={{ flexBasis: isMobile ? "100%" : "25%", marginRight: isMobile ? "0" : "10px", marginBottom: isMobile ? "10px" : "0" }}
                              value={car.chargingEfficiency ? car.chargingEfficiency : 90}
                              onChange={(e) => handleCarChange(index, "chargingEfficiency", e.target.value)}
                              InputProps={{ endAdornment: <InputAdornment position="end">%</InputAdornment> }}
                            />

                            <TextField
                              label="Car Efficiency"
                              value={car.efficiency ? `${car.efficiency} Wh/km` : ''}
                              InputProps={{
                                readOnly: true,
                              }}
                              variant="outlined"
                              style={{
                                flexBasis: isMobile ? "100%" : "25%",
                                alignSelf: "center",
                                marginRight: isMobile ? "0" : "10px",
                                marginBottom: isMobile ? "10px" : "0",
                              }}
                            />

                            <IconButton
                              onClick={() => handleRemoveCar(index)}
                              sx={{ color: "#FE424D" }}
                              style={{ alignSelf: "center", marginLeft: isMobile ? "0" : "10px" }}
                            >
                              <RemoveCircleOutlineIcon />
                            </IconButton>
                          </div>
                        ))}

                        <IconButton onClick={handleAddCar} sx={{ color: "#FE424D" }}>
                          <AddCircleOutlineIcon fontSize="large" />
                        </IconButton>
                        <div style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
                          <Button variant="contained" onClick={handleApplyEVChargeEstimates} sx={{
                            bgcolor: '#FE424D',
                            '&:hover': {
                              backgroundColor: '#FE424D', // on hover, the color stays the same
                            },
                          }}>
                            Apply EV Charge Estimates
                          </Button>
                        </div>
                      </>
                    )}
                  </CardContent>
                </Card>



                {/* <Typography
                  ref={reportRef}
                  variant="h3"
                  align="center"
                  style={{ marginBottom: "20px", marginTop: "20px", color: "#1AA6B7" }}
                >
                  Your Report
                </Typography> */}

                {isMobile && (
                  <Alert severity="warning">You are viewing your report on a mobile device. To receive more detailed monthly breakdowns and data tables, you can log in from your laptop or tablet.</Alert>
                )}

                <Tooltip title="Filter Date Range">
                  <IconButton
                    onClick={toggleDateFilter}
                    style={{ marginTop: "20px", color: "#FE424D" }}
                    size="large" // Adjust the size as needed
                  >
                    <DateRangeIcon fontSize="inherit" /> {/* fontSize set to inherit to respect the size of the IconButton */}
                  </IconButton>
                </Tooltip>


                <ShareCard url={shareWdigetUrl} title={shareWdigetTitle} />


                {/* New date picker card */}
                {showDateFilter && (
                  <Card style={{ marginTop: "20px", marginBottom: "20px", backgroundColor: "#DAECF3" }}>
                    <CardContent style={{ marginLeft: isMobile ? "0" : "80px" }}>
                      <Typography variant="h6" component="h2">
                        Filter By Date
                      </Typography>
                      <Typography variant="body1" component="p" sx={{ fontFamily: 'sans-serif' }}>
                        Set your date range for specific period of interest. The default is for your last year of meter data.
                      </Typography>
                      <LocalizationProvider dateAdapter={AdapterDateFns} locale={enGB}>
                        <Box display="flex" flexDirection={isMobile ? "column" : "row"} justifyContent={isMobile ? "flex-start" : "center"} style={{ marginTop: "20px" }}>
                          <DatePicker
                            label="Start Date"
                            inputFormat="dd/MM/yyyy"
                            value={startDate}
                            onChange={handleStartDateChange}
                            renderInput={(params) => <TextField {...params} />}
                            style={isMobile ? { marginBottom: "10px" } : { marginRight: "10px", marginTop: "10px" }} // Corrected the marginTop value here
                          />
                          <DatePicker
                            label="End Date"
                            inputFormat="dd/MM/yyyy"
                            value={endDate}
                            onChange={handleEndDateChange}
                            renderInput={(params) => <TextField {...params} />}
                            style={isMobile ? { marginBottom: "10px" } : { marginRight: "10px", marginTop: "10px" }} // Corrected the marginTop value here
                          />
                          <Button variant="contained" onClick={handleDateFilter} sx={{
                            bgcolor: '#FE424D',
                            '&:hover': {
                              backgroundColor: '#FE424D', // on hover, the color stays the same
                            },
                            marginTop: isMobile ? "10px" : "0", // Add marginTop only for mobile view
                          }}>
                            Apply Date Filter
                          </Button>
                        </Box>
                      </LocalizationProvider>
                    </CardContent>
                  </Card>
                )}




                {!isMobile && (
                  <Box ref={reportRef} sx={{ width: '100%', marginBottom: "20px", paddingTop: "20px", backgroundColor: "#DAECF3" }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                      <Tabs
                        value={selectedPriceComparisonTab}
                        onChange={handlePriceComparisonTabChange}
                        aria-label="basic tabs example"
                        TabIndicatorProps={{ style: { background: "#FE424D" } }}
                      >
                        <Tab
                          label="Savings on each plan for you"
                          sx={{
                            textTransform: "capitalize",
                            '&.Mui-selected': {
                              color: '#FE424D',
                            },
                          }}
                        />
                        <Tab
                          label="Cost of each plan"
                          sx={{
                            textTransform: "capitalize",
                            '&.Mui-selected': {
                              color: '#FE424D',
                            },
                          }}
                        />
                        <Tab
                          label="Monthly Import Breakdown"
                          sx={{
                            textTransform: "capitalize",
                            '&.Mui-selected': {
                              color: '#FE424D',
                            },
                          }}
                        />
                        {dfMonthlyExportPayments && (
                          <Tab
                            label="Monthly Export Breakdown"
                            sx={{
                              textTransform: "capitalize",
                              '&.Mui-selected': {
                                color: '#FE424D',
                              },
                            }}
                          />
                        )}
                      </Tabs>
                    </Box>

                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: "20px", marginBottom: "-20px" }}>
                      <Typography variant="body1" sx={{ backgroundColor: '#FFF8E1', padding: '10px', borderRadius: '4px', textAlign: 'center', boxShadow: 1 }}>
                        Savings shown are for the period {format(startDate, 'dd/MM/yyyy')} to {format(endDate, 'dd/MM/yyyy')} ({Math.round((endDate - startDate) / (1000 * 60 * 60 * 24 * 30.44))} months).
                      </Typography>
                    </Box>


                    <TabPanel value={selectedPriceComparisonTab} index={0} sx={{ padding: '0px' }}>
                      <Card style={{ marginTop: "0px", backgroundColor: "#DAECF3" }}>
                        <CardContent>
                          <Typography
                            variant="h5"
                            component="h2"
                            style={{ marginBottom: "20px", display: "flex", alignItems: "center" }}
                          >
                            Savings on each plan for you
                            <Tooltip
                              title="This table compares your plan with all the other available plans on our platform. Your plan is highlighted in yellow to make it easier for you to see where your plan ranks relative to the other plans."
                              style={{ marginLeft: "8px" }}
                            >
                              <Info fontSize="small" />
                            </Tooltip>
                          </Typography>
                          <TableContainer
                            ref={tableContainerRef}
                            sx={{
                              maxHeight: "650px", // Keep the scroll height consistent
                              overflowY: "auto",
                              width: "100%",
                            }}
                          >
                            <Table stickyHeader>
                              <TableHead>
                                <TableRow style={{ backgroundColor: "#1AA6B7" }}>
                                  <TableCell
                                    style={{
                                      fontWeight: "bold",
                                      textTransform: "capitalize",
                                      color: "white",
                                      minWidth: "50px",
                                      position: "sticky",
                                      top: 0,
                                      zIndex: 3,
                                      backgroundColor: "#1AA6B7",
                                    }}
                                  >
                                    #
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      fontWeight: "bold",
                                      textTransform: "capitalize",
                                      color: "white",
                                      minWidth: "100px",
                                      position: "sticky",
                                      top: 0,
                                      zIndex: 3,
                                      backgroundColor: "#1AA6B7",
                                    }}
                                  >
                                    Smart
                                  </TableCell>
                                  {filteredPriceDiffTableColumnOrder.map((col) => (
                                    <TableCell
                                      key={col}
                                      style={{
                                        fontWeight: "bold",
                                        textTransform: "capitalize",
                                        color: "white",
                                        minWidth: "150px",
                                        position: "sticky",
                                        top: 0,
                                        zIndex: 3,
                                        backgroundColor: "#1AA6B7",
                                      }}
                                    >
                                      {col}
                                      <Tooltip title={columnTooltips[col] || ""} arrow>
                                        <IconButton
                                          size="small"
                                          style={{ marginLeft: "4px", padding: 0, color: "white" }}
                                        >
                                          <Info fontSize="inherit" />
                                        </IconButton>
                                      </Tooltip>
                                    </TableCell>
                                  ))}
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {dfImpExpDiffData.values.map((row, index) => {
                                  const isSelectedPlan =
                                    row[dfImpExpDiffData.columns.indexOf("Supplier")] === dfSelectedPlan.selected_supplier &&
                                    row[dfImpExpDiffData.columns.indexOf("Tariff")] === dfSelectedPlan.selected_plan;
                                  const isSmartTariff = smartTariffs.some(
                                    (plan) =>
                                      plan.Supplier === row[dfImpExpDiffData.columns.indexOf("Supplier")] &&
                                      plan.Tariff === row[dfImpExpDiffData.columns.indexOf("Tariff")]
                                  );
                                  return (
                                    <TableRow
                                      key={index}
                                      style={
                                        isSelectedPlan
                                          ? { backgroundColor: "#FFEB3B" }
                                          : index % 2 === 0
                                            ? { backgroundColor: "#E8F1F7" }
                                            : {}
                                      }
                                    >
                                      <TableCell>{index + 1}</TableCell>
                                      <TableCell>{isSmartTariff ? <CheckCircleIcon style={{ color: "green" }} /> : ""}</TableCell>
                                      {filteredPriceDiffTableColumnOrder.map((col, cellIndex) => {
                                        const columnIndex = dfImpExpDiffData.columns.indexOf(col);
                                        if (columnIndex === -1) {
                                          return (
                                            <TableCell key={cellIndex} style={{ textAlign: "center" }}>
                                              -
                                            </TableCell>
                                          );
                                        }
                                        const cell = row[columnIndex];
                                        const isNumber = !isNaN(parseFloat(cell));
                                        const cellStyle = { textAlign: isNumber ? "right" : "left" };
                                        const formattedCell = isNumber ? parseFloat(cell).toFixed(2) : cell;
                                        return (
                                          <TableCell key={cellIndex} style={cellStyle}>
                                            {formattedCell}
                                          </TableCell>
                                        );
                                      })}
                                    </TableRow>
                                  );
                                })}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </CardContent>
                      </Card>
                    </TabPanel>










                    <TabPanel value={selectedPriceComparisonTab} index={1}>
                      <Card style={{ backgroundColor: "#DAECF3", marginTop: "20px" }}>
                        <Typography
                          variant="h5"
                          component="h2"
                          style={{ marginBottom: "20px", display: "flex", alignItems: "center" }}
                        >
                          Cost of each plan
                          <Tooltip
                            title="This table shows the total costs of all plans for your meter data for your selected date range..."
                            style={{ marginLeft: "8px" }}
                          >
                            <Info fontSize="small" />
                          </Tooltip>
                        </Typography>
                        <CardContent>
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <IconButton
                              onClick={scrollLeft}
                              sx={{
                                backgroundColor: "#fa4251",
                                color: "white",
                                '&:hover': { backgroundColor: "#d93642" },
                                borderRadius: "50%",
                                padding: "10px",
                              }}
                            >
                              <ArrowBackIos />
                            </IconButton>
                            <div
                              style={{
                                overflowX: "auto",
                                display: "flex",
                                flexDirection: "column",
                                flexGrow: 1,
                                maxHeight: "650px", // Adjusted height
                              }}
                            >
                              <div
                                style={{
                                  overflowY: "auto",
                                  height: showAllPlans ? "auto" : "100%",
                                }}
                              >
                                <TableContainer
                                  ref={tableContainerRef}
                                  sx={{
                                    maxHeight: "650px", // Ensure this matches the height above
                                    overflowY: "auto",
                                    width: "100%",
                                  }}
                                >
                                  <Table stickyHeader>
                                    <TableHead>
                                      <TableRow style={{ backgroundColor: "#1AA6B7" }}>
                                        {dfDataColumnOrder.map((col, colIndex) => {
                                          // Exclude the "Unit Cost" column
                                          if (
                                            col === "Unit Cost" || // Skip the "Unit Cost" column
                                            col === "Discount %" || // Skip the "Discount %" column
                                            (col === "EV Charging Cost" || col === "Total Cost with EV" || col === "VAT with EV") && !hasEV
                                          ) {
                                            return null;
                                          }
                                          if ((col === "Net Cost Including Generation" || col === "Net Cost Including Generation and EV Charging Simulation") && !dfMonthlyExportPayments) {
                                            return null; // Hide this column if no export data is available
                                          }
                                          if (col === "Generation Payments" && !dfMonthlyExportPayments) {
                                            return null; // Hide this column if no generation data is available
                                          }

                                          const isSticky = colIndex < 5; // First five columns are sticky
                                          let stickyLeft;

                                          let minWidth;
                                          switch (colIndex) {
                                            case 0: // "#" column
                                              minWidth = "50px";
                                              stickyLeft = 0;
                                              break;
                                            case 1: // "Smart Tariff" column
                                              minWidth = "100px";
                                              stickyLeft = 50;
                                              break;
                                            case 2: // "Supplier" column
                                              minWidth = "150px";
                                              stickyLeft = 150;
                                              break;
                                            case 3: // "Tariff" column
                                              minWidth = "150px";
                                              stickyLeft = 300;
                                              break;
                                            case 4: // "Total Cost" column
                                              minWidth = "150px";
                                              stickyLeft = 450;
                                              break;
                                            default:
                                              minWidth = "150px";
                                              stickyLeft = undefined;
                                          }

                                          return (
                                            <TableCell
                                              key={col}
                                              sx={{
                                                fontWeight: "bold",
                                                textTransform: "capitalize",
                                                color: "white",
                                                position: "sticky",
                                                top: 0,
                                                left: stickyLeft,
                                                zIndex: isSticky ? 3 : 2,
                                                backgroundColor: "#1AA6B7",
                                                minWidth: minWidth,
                                              }}
                                            >
                                              {col}
                                            </TableCell>
                                          );
                                        })}
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {dfData.values.map((row, rowIndex) => {
                                        const isSelectedPlan =
                                          dfSelectedPlan &&
                                          row[dfData.columns.indexOf("Supplier")] === dfSelectedPlan.selected_supplier &&
                                          row[dfData.columns.indexOf("Tariff")] === dfSelectedPlan.selected_plan;

                                        return (
                                          <TableRow
                                            key={rowIndex}
                                            style={
                                              isSelectedPlan
                                                ? { backgroundColor: "#FFEB3B" }
                                                : rowIndex % 2 === 0
                                                  ? { backgroundColor: "#E8F1F7" }
                                                  : {}
                                            }
                                          >
                                            {dfDataColumnOrder.map((col, colIndex) => {
                                              // Skip the "Unit Cost" column in the body as well
                                              if (
                                                col === "Unit Cost" || // Skip the "Unit Cost" column
                                                col === "Discount %" || // Skip the "Discount %" column
                                                (col === "EV Charging Cost" || col === "Total Cost with EV" || col === "VAT with EV") && !hasEV
                                              ) {
                                                return null;
                                              }
                                              if ((col === "Net Cost Including Generation" || col === "Net Cost Including Generation and EV Charging Simulation") && !dfMonthlyExportPayments) {
                                                return null; // Skip this column if no export data is available
                                              }
                                              if (col === "Generation Payments" && !dfMonthlyExportPayments) {
                                                return null; // Skip this column if no generation data is available
                                              }

                                              let cellValue =
                                                col === "#" ? rowIndex + 1 : row[dfData.columns.indexOf(col)];
                                              const isSticky = colIndex < 5;
                                              const stickyStyles = isSticky
                                                ? {
                                                  position: "sticky",
                                                  left: colIndex === 0 ? 0 : colIndex === 1 ? 50 : colIndex === 2 ? 150 : colIndex === 3 ? 300 : 450,
                                                  zIndex: 2,
                                                  backgroundColor: isSelectedPlan
                                                    ? "#FFEB3B"
                                                    : rowIndex % 2 === 0
                                                      ? "#E8F1F7"
                                                      : "#DAECF3",
                                                }
                                                : { zIndex: 1 };

                                              if (col === "Standing Charge") {
                                                cellValue = parseFloat(cellValue).toFixed(2);
                                              }

                                              if (col === "Total Cost" || colIndex >= dfData.columns.indexOf("Total Cost")) {
                                                cellValue = parseFloat(cellValue).toFixed(2);
                                              }

                                              const isSmartTariff =
                                                col === "Smart Tariff" &&
                                                smartTariffs.some(
                                                  (plan) =>
                                                    plan.Supplier === row[dfData.columns.indexOf("Supplier")] &&
                                                    plan.Tariff === row[dfData.columns.indexOf("Tariff")]
                                                );

                                              const smartTariffIcon = isSmartTariff ? (
                                                <CheckCircleIcon style={{ color: "green" }} />
                                              ) : (
                                                ""
                                              );

                                              return (
                                                <TableCell
                                                  key={colIndex}
                                                  style={isSticky ? stickyStyles : { backgroundColor: "#abdaed", color: "black" }}
                                                >
                                                  {col === "Smart Tariff" ? smartTariffIcon : cellValue}
                                                </TableCell>
                                              );
                                            })}
                                          </TableRow>
                                        );
                                      })}
                                    </TableBody>
                                  </Table>
                                </TableContainer>
                              </div>
                            </div>
                            <IconButton
                              onClick={scrollRight}
                              sx={{
                                backgroundColor: "#fa4251",
                                color: "white",
                                '&:hover': { backgroundColor: "#d93642" },
                                borderRadius: "50%",
                                padding: "10px",
                              }}
                            >
                              <ArrowForwardIos />
                            </IconButton>
                          </Box>
                        </CardContent>
                      </Card>
                    </TabPanel>


                    {
                      dfMonthlyImportCost && (

                        <TabPanel value={selectedPriceComparisonTab} index={2}>
                          <Card style={{ backgroundColor: "#DAECF3", marginTop: "20px" }}>
                            <Typography
                              variant="h5"
                              component="h2"
                              style={{ marginBottom: "20px", display: "flex", alignItems: "center" }}
                            >
                              Monthly Import Breakdown
                              <Tooltip
                                title="This table shows the monthly import breakdown for your meter data for your selected date range..."
                                style={{ marginLeft: "8px" }}
                              >
                                <Info fontSize="small" />
                              </Tooltip>
                            </Typography>
                            <CardContent>
                              <Box sx={{ display: "flex", alignItems: "center" }}>
                                <IconButton
                                  onClick={scrollLeft}
                                  sx={{
                                    backgroundColor: "#fa4251",
                                    color: "white",
                                    '&:hover': { backgroundColor: "#d93642" },
                                    borderRadius: "50%",
                                    padding: "10px",
                                  }}
                                >
                                  <ArrowBackIos />
                                </IconButton>
                                <div
                                  style={{
                                    overflowX: "auto",
                                    display: "flex",
                                    flexDirection: "column",
                                    flexGrow: 1,
                                    maxHeight: "650px",
                                  }}
                                >
                                  <div
                                    style={{
                                      overflowY: "auto",
                                      height: showAllImportPlans ? "auto" : "100%",
                                    }}
                                  >
                                    <TableContainer
                                      ref={tableContainerRef}
                                      sx={{
                                        maxHeight: "650px",
                                        overflowY: "auto",
                                        width: "100%",
                                      }}
                                    >
                                      <Table stickyHeader>
                                        <TableHead>
                                          <TableRow style={{ backgroundColor: "#1AA6B7" }}>
                                            {dfMonthlyImportCostColumnOrder.map((col, colIndex) => {
                                              // Always hide "Discount %" column and EV related columns
                                              if (col === "Discount %" || col === "Total Cost with EV" || col === "VAT with EV") {
                                                return null;
                                              }
                                              // Conditionally hide "Net Cost Including Generation" if no generation data is present
                                              if (col === "Net Cost Including Generation" && !dfMonthlyExportPayments) {
                                                return null;
                                              }
                                              // Conditionally hide "Net Cost Including Generation and EV Charging Simulation" if no EV
                                              if (col === "Net Cost Including Generation and EV Charging Simulation" && !hasEV) {
                                                return null;
                                              }
                                              const isSticky = colIndex < 6; // First six columns are sticky
                                              let stickyLeft;

                                              let minWidth;
                                              switch (colIndex) {
                                                case 0: // "#" column
                                                  minWidth = "50px";
                                                  stickyLeft = 0;
                                                  break;
                                                case 1: // "Smart Tariff" column
                                                  minWidth = "100px";
                                                  stickyLeft = 50;
                                                  break;
                                                case 2: // "Supplier" column
                                                  minWidth = "150px";
                                                  stickyLeft = 150;
                                                  break;
                                                case 3: // "Tariff" column
                                                  minWidth = "150px";
                                                  stickyLeft = 300;
                                                  break;
                                                case 5: // "Net Cost Including Generation" column
                                                  minWidth = "150px";
                                                  stickyLeft = 600;
                                                  break;
                                                default:
                                                  minWidth = "150px";
                                                  stickyLeft = undefined;
                                              }

                                              return (
                                                <TableCell
                                                  key={col}
                                                  sx={{
                                                    fontWeight: "bold",
                                                    textTransform: "capitalize",
                                                    color: "white",
                                                    position: "sticky",
                                                    top: 0,
                                                    left: stickyLeft,
                                                    zIndex: isSticky ? 3 : 2,
                                                    backgroundColor: "#1AA6B7",
                                                    minWidth: minWidth,
                                                  }}
                                                >
                                                  {col}
                                                </TableCell>
                                              );
                                            })}
                                          </TableRow>
                                        </TableHead>
                                        <TableBody>
                                          {dfMonthlyImportCost.values
                                            .sort((a, b) => {
                                              const netCostIndex = dfMonthlyImportCost.columns.indexOf("Net Cost Including Generation");
                                              return parseFloat(a[netCostIndex]) - parseFloat(b[netCostIndex]);
                                            })
                                            .map((row, rowIndex) => {
                                              const isSelectedPlan =
                                                dfSelectedPlan &&
                                                row[dfMonthlyImportCost.columns.indexOf("Supplier")] === dfSelectedPlan.selected_supplier &&
                                                row[dfMonthlyImportCost.columns.indexOf("Tariff")] === dfSelectedPlan.selected_plan;

                                              return (
                                                <TableRow
                                                  key={rowIndex}
                                                  style={
                                                    isSelectedPlan
                                                      ? { backgroundColor: "#FFEB3B" }
                                                      : rowIndex % 2 === 0
                                                        ? { backgroundColor: "#E8F1F7" }
                                                        : {}
                                                  }
                                                >
                                                  {dfMonthlyImportCostColumnOrder.map((col, colIndex) => {
                                                    // Always hide "Discount %" column and EV-related columns
                                                    if (col === "Discount %" || col === "Total Cost with EV" || col === "VAT with EV") {
                                                      return null;
                                                    }
                                                    // Conditionally hide "Net Cost Including Generation" if no generation data is present
                                                    if (col === "Net Cost Including Generation" && !dfMonthlyExportPayments) {
                                                      return null;
                                                    }
                                                    // Conditionally hide "Net Cost Including Generation and EV Charging Simulation" if no EV
                                                    if (col === "Net Cost Including Generation and EV Charging Simulation" && !hasEV) {
                                                      return null;
                                                    }

                                                    let cellValue = col === "#" ? rowIndex + 1 : row[dfMonthlyImportCost.columns.indexOf(col)];
                                                    const isSticky = colIndex < 6;
                                                    const stickyStyles = isSticky
                                                      ? {
                                                        position: "sticky",
                                                        left:
                                                          colIndex === 0
                                                            ? 0
                                                            : colIndex === 1
                                                              ? 50
                                                              : colIndex === 2
                                                                ? 150
                                                                : colIndex === 3
                                                                  ? 300
                                                                  : colIndex === 5
                                                                    ? 600
                                                                    : undefined,
                                                        zIndex: 2,
                                                        backgroundColor: isSelectedPlan
                                                          ? "#FFEB3B"
                                                          : rowIndex % 2 === 0
                                                            ? "#E8F1F7"
                                                            : "#DAECF3",
                                                      }
                                                      : { zIndex: 1 };

                                                    if (
                                                      col === "Net Cost Including Generation" ||
                                                      colIndex >= dfMonthlyImportCost.columns.indexOf("Net Cost Including Generation")
                                                    ) {
                                                      cellValue = parseFloat(cellValue).toFixed(2);
                                                    }

                                                    const isSmartTariff =
                                                      col === "Smart Tariff" &&
                                                      smartTariffs.some(
                                                        (plan) =>
                                                          plan.Supplier === row[dfMonthlyImportCost.columns.indexOf("Supplier")] &&
                                                          plan.Tariff === row[dfMonthlyImportCost.columns.indexOf("Tariff")]
                                                      );

                                                    const smartTariffIcon = isSmartTariff ? (
                                                      <CheckCircleIcon style={{ color: "green" }} />
                                                    ) : (
                                                      ""
                                                    );

                                                    return (
                                                      <TableCell
                                                        key={colIndex}
                                                        style={isSticky ? stickyStyles : { backgroundColor: "#abdaed", color: "black" }}
                                                      >
                                                        {col === "Smart Tariff" ? smartTariffIcon : cellValue}
                                                      </TableCell>
                                                    );
                                                  })}
                                                </TableRow>
                                              );
                                            })}
                                        </TableBody>
                                      </Table>
                                    </TableContainer>
                                  </div>
                                </div>
                                <IconButton
                                  onClick={scrollRight}
                                  sx={{
                                    backgroundColor: "#fa4251",
                                    color: "white",
                                    '&:hover': { backgroundColor: "#d93642" },
                                    borderRadius: "50%",
                                    padding: "10px",
                                  }}
                                >
                                  <ArrowForwardIos />
                                </IconButton>
                              </Box>
                              <Button
                                onClick={() => setShowAllImportPlans(!showAllImportPlans)}
                                style={{
                                  alignSelf: "center",
                                  marginTop: "10px",
                                  backgroundColor: "#1AA6B7",
                                  color: "white",
                                  '&:hover': {
                                    backgroundColor: "#13919A",
                                  },
                                }}
                              >
                                {showAllImportPlans ? "Show less" : "Show all import plans"}
                              </Button>
                            </CardContent>
                          </Card>
                        </TabPanel>






                      )
                    }

                    {
                      dfMonthlyExportPayments && (
                        <TabPanel value={selectedPriceComparisonTab} index={3}>
                          <Card style={{ backgroundColor: "#DAECF3", marginTop: "20px" }}>
                            <Typography
                              variant="h5"
                              component="h2"
                              style={{ marginBottom: "20px", display: "flex", alignItems: "center" }}
                            >
                              Monthly Export Payments
                              <Tooltip
                                title="This table shows the monthly export payments for your meter data for your selected date range..."
                                style={{ marginLeft: "8px" }}
                              >
                                <Info fontSize="small" />
                              </Tooltip>
                            </Typography>
                            <CardContent>
                              <Box sx={{ display: "flex", alignItems: "center" }}>
                                <IconButton
                                  onClick={scrollLeft}
                                  sx={{
                                    backgroundColor: "#fa4251",
                                    color: "white",
                                    '&:hover': { backgroundColor: "#d93642" },
                                    borderRadius: "50%",
                                    padding: "10px",
                                  }}
                                >
                                  <ArrowBackIos />
                                </IconButton>
                                <div
                                  style={{
                                    overflowX: "auto",
                                    display: "flex",
                                    flexDirection: "column",
                                    flexGrow: 1,
                                    maxHeight: "650px",
                                  }}
                                >
                                  <div
                                    style={{
                                      overflowY: "auto",
                                      height: showAllExportPlans ? "auto" : "100%",
                                    }}
                                  >
                                    <TableContainer
                                      ref={tableContainerRef}
                                      sx={{
                                        maxHeight: "650px",
                                        overflowY: "auto",
                                        width: "100%",
                                      }}
                                    >
                                      <Table stickyHeader>
                                        <TableHead>
                                          <TableRow style={{ backgroundColor: "#1AA6B7" }}>
                                            {dfMonthlyExportPaymentsColumnOrder.map((col, colIndex) => {
                                              // Skip "Discount %" column for all users
                                              if (col === "Discount %") {
                                                return null;
                                              }

                                              // Conditionally hide "Net Cost Including Generation and EV Charging Simulation"
                                              if (col === "Net Cost Including Generation and EV Charging Simulation" && !hasEV) {
                                                return null;
                                              }

                                              const isSticky = col === "#" || col === "Smart Tariff" || col === "Supplier" || col === "Tariff" || col === "Net Cost Including Generation" || col === "Total Earnings";
                                              let stickyLeft;

                                              let minWidth;
                                              switch (col) {
                                                case "#": // "#" column
                                                  minWidth = "50px";
                                                  stickyLeft = 0;
                                                  break;
                                                case "Smart Tariff": // "Smart Tariff" column
                                                  minWidth = "100px";
                                                  stickyLeft = 50;
                                                  break;
                                                case "Supplier": // "Supplier" column
                                                  minWidth = "150px";
                                                  stickyLeft = 150;
                                                  break;
                                                case "Tariff": // "Tariff" column
                                                  minWidth = "150px";
                                                  stickyLeft = 300;
                                                  break;
                                                case "Net Cost Including Generation": // "Net Cost Including Generation" column
                                                  minWidth = "150px";
                                                  stickyLeft = 450;
                                                  break;
                                                case "Total Earnings": // "Total Earnings" column
                                                  minWidth = "150px";
                                                  stickyLeft = 600;
                                                  break;
                                                default:
                                                  minWidth = "150px";
                                                  stickyLeft = undefined;
                                              }

                                              return (
                                                <TableCell
                                                  key={col}
                                                  sx={{
                                                    fontWeight: "bold",
                                                    textTransform: "capitalize",
                                                    color: "white",
                                                    position: "sticky",
                                                    top: 0,
                                                    left: stickyLeft,
                                                    zIndex: isSticky ? 3 : 2,
                                                    backgroundColor: "#1AA6B7",
                                                    minWidth: minWidth,
                                                  }}
                                                >
                                                  {col}
                                                </TableCell>
                                              );
                                            })}
                                          </TableRow>
                                        </TableHead>
                                        <TableBody>
                                          {dfMonthlyExportPayments.values.map((row, rowIndex) => {
                                            const isSelectedPlan =
                                              dfSelectedPlan &&
                                              row[dfMonthlyExportPayments.columns.indexOf("Supplier")] === dfSelectedPlan.selected_supplier &&
                                              row[dfMonthlyExportPayments.columns.indexOf("Tariff")] === dfSelectedPlan.selected_plan;

                                            return (
                                              <TableRow
                                                key={rowIndex}
                                                style={
                                                  isSelectedPlan
                                                    ? { backgroundColor: "#FFEB3B" }
                                                    : rowIndex % 2 === 0
                                                      ? { backgroundColor: "#E8F1F7" }
                                                      : {}
                                                }
                                              >
                                                {dfMonthlyExportPaymentsColumnOrder.map((col, colIndex) => {
                                                  // Skip "Discount %" column for all users
                                                  if (col === "Discount %") {
                                                    return null;
                                                  }

                                                  if (col === "Net Cost Including Generation and EV Charging Simulation" && !hasEV) {
                                                    return null;
                                                  }

                                                  let cellValue =
                                                    col === "#" ? rowIndex + 1 : row[dfMonthlyExportPayments.columns.indexOf(col)];
                                                  const isSticky = col === "#" || col === "Smart Tariff" || col === "Supplier" || col === "Tariff" || col === "Net Cost Including Generation" || col === "Total Earnings";
                                                  const stickyStyles = isSticky
                                                    ? {
                                                      position: "sticky",
                                                      left: col === "#" ? 0 : col === "Smart Tariff" ? 50 : col === "Supplier" ? 150 : col === "Tariff" ? 300 : col === "Net Cost Including Generation" ? 450 : 600,
                                                      zIndex: 2,
                                                      backgroundColor: isSelectedPlan
                                                        ? "#FFEB3B"
                                                        : rowIndex % 2 === 0
                                                          ? "#E8F1F7"
                                                          : "#DAECF3",
                                                    }
                                                    : { zIndex: 1, position: "sticky", top: 0 };

                                                  if (col === "Net Cost Including Generation") {
                                                    cellValue = parseFloat(cellValue).toFixed(2); // Ensure 2 decimal places
                                                  }

                                                  const isSmartTariff =
                                                    col === "Smart Tariff" &&
                                                    smartTariffs.some(
                                                      (plan) =>
                                                        plan.Supplier === row[dfMonthlyExportPayments.columns.indexOf("Supplier")] &&
                                                        plan.Tariff === row[dfMonthlyExportPayments.columns.indexOf("Tariff")]
                                                    );

                                                  const smartTariffIcon = isSmartTariff ? (
                                                    <CheckCircleIcon style={{ color: "green" }} />
                                                  ) : (
                                                    ""
                                                  );

                                                  return (
                                                    <TableCell
                                                      key={colIndex}
                                                      style={isSticky ? stickyStyles : { backgroundColor: "#abdaed", color: "black", position: "sticky", top: 0 }}
                                                    >
                                                      {col === "Smart Tariff" ? smartTariffIcon : cellValue}
                                                    </TableCell>
                                                  );
                                                })}
                                              </TableRow>
                                            );
                                          })}
                                        </TableBody>
                                      </Table>
                                    </TableContainer>
                                  </div>
                                </div>
                                <IconButton
                                  onClick={scrollRight}
                                  sx={{
                                    backgroundColor: "#fa4251",
                                    color: "white",
                                    '&:hover': { backgroundColor: "#d93642" },
                                    borderRadius: "50%",
                                    padding: "10px",
                                  }}
                                >
                                  <ArrowForwardIos />
                                </IconButton>
                              </Box>
                              <Button
                                onClick={() => setShowAllExportPlans(!showAllExportPlans)}
                                style={{
                                  alignSelf: "center",
                                  marginTop: "10px",
                                  backgroundColor: "#1AA6B7",
                                  color: "white",
                                  '&:hover': {
                                    backgroundColor: "#13919A",
                                  },
                                }}
                              >
                                {showAllExportPlans ? "Show less" : "Show all export plans"}
                              </Button>
                            </CardContent>
                          </Card>
                        </TabPanel>






                      )
                    }


                  </Box>
                )}

                {isMobile && (
                  <ComparisonTableMobile 
                    data={dfImpExpDiffData} 
                    dfSelectedPlan={dfSelectedPlan} 
                    smartTariffs={smartTariffs} 
                    reportRef={reportRef} 
                    hasEV={hasEV} 
                    energyExported={energyExported}  // Pass energyExported here
                  />
                )}


                {/* <Card style={{ marginBottom: "20px", backgroundColor: "#DAECF3" }}>
                  <CardContent>
                    <Typography variant="h5" component="h2" style={{ marginBottom: "20px" }}>
                      Which plan gives you the most savings?
                    </Typography>

                    <BarChartComponent
                      data={{ columns: dfCostDiffData.columns, values: dfCostDiffData.values }}
                      margin={{ top: 5, right: 30, left: 100, bottom: 5 }}
                      currentPlan={dfSelectedPlan.selected_plan}
                      currentSupplier={dfSelectedPlan.selected_supplier}
                    />
                  </CardContent>
                </Card> */}

                {!isMobile && (

                  <Box sx={{ width: '100%', marginBottom: "20px", paddingTop: "20px", backgroundColor: "#DAECF3" }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                      <Tabs
                        value={selectedGraphComparisonTab}
                        onChange={handleGraphComparisonTabChange}
                        aria-label="basic tabs example"
                        TabIndicatorProps={{ style: { background: "#FE424D" } }}
                      >
                        <Tab
                          label="Smart Plans Only"
                          sx={{
                            textTransform: "capitalize",
                            '&.Mui-selected': {
                              color: '#FE424D',
                            },
                            minWidth: "auto", // Adjust the width of the tab
                          }}
                        />
                        <Tab
                          label="All Plans"
                          sx={{
                            textTransform: "capitalize",
                            '&.Mui-selected': {
                              color: '#FE424D',
                            },
                          }}
                        />
                      </Tabs>
                    </Box>
                    {selectedGraphComparisonTab === 0 && (
                      <Card style={{ marginTop: "20px", backgroundColor: "#DAECF3" }}>
                        <CardContent>
                          <Typography
                            variant="h5"
                            component="h2"
                            style={{ marginBottom: "20px", display: "flex", alignItems: "center" }}
                          >
                            Which plan gives you the most savings? (Smart Plans Only)
                            <Tooltip title="This table compares your plan with all the other available smart plans on our platform. Your plan is highlighted in yellow to make it easier for you to see where your plan ranks relative to the other plans. If your plan is ranked lower down the table you may have to select the show all plans option to see your highlighted plan." style={{ marginLeft: "8px" }}>
                              <Info fontSize="small" />
                            </Tooltip>
                          </Typography>
                          <BarChartComponent
                            data={{ columns: dfImpExpDiffData.columns, values: dfImpExpDiffData.values }}
                            margin={{ top: 5, right: 30, left: 100, bottom: 5 }}
                            currentPlan={dfSelectedPlan.selected_plan}
                            currentSupplier={dfSelectedPlan.selected_supplier}
                            smartTariffs={smartTariffs}
                            selectedGraphComparisonTab={selectedGraphComparisonTab}
                            energyExported={energyExported}
                            hasEV={hasEV}  // <-- Pass the hasEV state here
                          />

                        </CardContent>
                      </Card>
                    )}
                    {selectedGraphComparisonTab === 1 && (
                      <Card style={{ backgroundColor: "#DAECF3", marginTop: "20px" }}>
                      <Typography
                        variant="h5"
                        component="h2"
                        style={{ marginBottom: "20px", display: "flex", alignItems: "center" }}
                      >
                        Which plan gives you the most savings? (All)
                        <Tooltip title="This table compares your plan with all the other available plans on our platform. Your plan is highlighted in yellow to make it easier for you to see where your plan ranks relative to the other plans. If your plan is ranked lower down the table you may have to select the show all plans option to see your highlighted plan." style={{ marginLeft: "8px" }}>
                          <Info fontSize="small" />
                        </Tooltip>
                      </Typography>
                  
                      {/* Use the same logic as for Smart Plans Only graph */}
                      <BarChartComponent
                        data={{
                          columns: energyExported || hasEV ? dfImpExpDiffData.columns : dfCostDiffData.columns,
                          values: energyExported || hasEV ? dfImpExpDiffData.values : dfCostDiffData.values
                        }}
                        margin={{ top: 5, right: 30, left: 100, bottom: 5 }}
                        currentPlan={dfSelectedPlan.selected_plan}
                        currentSupplier={dfSelectedPlan.selected_supplier}
                        selectedGraphComparisonTab={selectedGraphComparisonTab}
                        energyExported={energyExported}
                        hasEV={hasEV}  // Pass the hasEV state here
                      />
                    </Card>
                    )}
                  </Box>
                )}

                {/* {
                  priceVsUsage !== false && (
                    <Card style={{ marginTop: "20px", marginBottom: "20px", paddingTop: "20px", backgroundColor: "#DAECF3" }}>
                      <CardContent>
                        <Typography variant="h5" component="h2" style={{ marginBottom: "20px" }}>
                          The performance of your current plan
                        </Typography>
                        <LineChartPriceSchedule data={priceVsUsage} />
                      </CardContent>
                    </Card>
                  )
                } */}

                {/* {
                  weekPriceVsUsage !== false && (
                    <Card style={{ marginTop: "20px", marginBottom: "20px", paddingTop: "20px", backgroundColor: "#DAECF3" }}>
                      <CardContent>
                        <Typography variant="h5" component="h2" style={{ marginBottom: "20px" }}>
                          Your Weekly Plan Performance
                        </Typography>
                        <LineChartWeekPriceSchedule data={weekPriceVsUsage} />
                      </CardContent>
                    </Card>
                  )
                } */}

                {
                  weekPriceVsUsageAllPlans !== false && (
                    <Card style={{ marginTop: "20px", marginBottom: "20px", paddingTop: "20px", backgroundColor: "#DAECF3" }}>
                      <CardContent>
                        <Typography variant="h5" component="h2" style={{ marginBottom: "20px" }}>
                          Compare your current plan with any other plan
                        </Typography>

                        <Box display="flex" alignItems="center" style={{ marginBottom: '20px' }}>
                          <Typography variant="subtitle1" style={{ marginRight: "10px" }}>
                            Select any plan to compare with yours:
                          </Typography>
                          <FormControl variant="outlined" style={{ width: '400px' }}>  {/* Adjusted width here */}
                            <InputLabel id="comparison-plan-select-label">Compare Plans</InputLabel>
                            <Select
                              labelId="comparison-plan-select-label"
                              value={selectedComparisonPlanLineGraphAllPlans}
                              onChange={(e) => setSelectedComparisonPlanLineGraphAllPlans(e.target.value)}
                              label="Compare Plans"
                            >
                              <MenuItem value="">
                                <em>None</em>
                              </MenuItem>
                              {allPlans.map(plan => (
                                <MenuItem key={plan} value={plan}>{plan}</MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Box>

                        <LineChartWeekPriceScheduleAllPlans
                          weekPriceVsUsageAllPlans={weekPriceVsUsageAllPlans}
                          currentSupplier={dfSelectedPlan.selected_supplier}
                          currentPlan={dfSelectedPlan.selected_plan}
                          selectedPlan={selectedComparisonPlanLineGraphAllPlans}
                        />
                      </CardContent>
                    </Card>

                  )
                }

                {/* <Box
                  sx={{ width: "100%", marginBottom: "20px", paddingTop: "20px", backgroundColor: "#DAECF3" }}
                >
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs
                      value={selectedPriceComparisonTab}
                      onChange={handlePriceComparisonTabChange}
                      aria-label="basic tabs example"
                      TabIndicatorProps={{ style: { background: "#FE424D" } }}
                    >
                      <Tab
                        label="Price Comparison with your Current Plan"
                        sx={{
                          textTransform: "capitalize",
                          "&.Mui-selected": {
                            color: "#FE424D",
                          },
                        }}
                      />
                      <Tab
                        label="Detailed Monthly Breakdown"
                        sx={{
                          textTransform: "capitalize",
                          "&.Mui-selected": {
                            color: "#FE424D",
                          },
                        }}
                      />
                    </Tabs>
                  </Box>
                  <TabPanel value={selectedPriceComparisonTab} index={0}>
                    <PriceComparisonDataTable
                      showAllSuppliers={showAllSuppliers}
                      sortedTariffPriceTableValues={sortedTariffPriceTableValues}
                      priceDiffTableColumnOrder={priceDiffTableColumnOrder}
                      columnTooltips={columnTooltips}
                      handleViewAllSuppliers={handleViewAllSuppliers}
                      dfCostDiffData={dfCostDiffData}
                      dfSelectedPlan={dfSelectedPlan}
                      smartTariffs={smartTariffs} // Add the smartTariffs prop here
                      dfDataColumnOrder={dfDataColumnOrder} // Pass the dfDataColumnOrder prop
                      activeTab={selectedPriceComparisonTab} // Pass the active tab index as a prop
                    />
                  </TabPanel>
                  <TabPanel value={selectedPriceComparisonTab} index={1}>
                    <PriceComparisonDataTable
                      showAllSuppliers={showAllSuppliers}
                      sortedTariffPriceTableValues={sortedTariffPriceTableValues}
                      priceDiffTableColumnOrder={priceDiffTableColumnOrder}
                      columnTooltips={columnTooltips}
                      handleViewAllSuppliers={handleViewAllSuppliers}
                      dfCostDiffData={dfCostDiffData}
                      dfSelectedPlan={dfSelectedPlan}
                      smartTariffs={smartTariffs} // Add the smartTariffs prop here
                      dfDataColumnOrder={dfDataColumnOrder} // Pass the dfDataColumnOrder prop
                      activeTab={selectedPriceComparisonTab} // Pass the active tab index as a prop
                    />
                  </TabPanel>
                </Box> */}


                <Card style={{ marginTop: "20px", backgroundColor: "#DAECF3" }}>
                  <CardContent>
                    <Typography variant="h5" component="h2" style={{ marginBottom: "20px" }}>
                      Monthly Energy Usage
                    </Typography>
                    <MonthlyEnergyUsageBarChart data={dfMonthlyEnergyUsage} />
                  </CardContent>
                </Card>

                <Card style={{ marginTop: "20px", backgroundColor: "#DAECF3" }}>
                  <CardContent>
                    {!loading && dfDailyUsage && (
                      <Card style={{ marginTop: "20px", backgroundColor: "#DAECF3" }}>
                        <CardContent>
                          <Typography variant="h5" component="h2">
                            Your Daily Load Profile
                          </Typography>
                          {isMobile ? (
                            // Render a dropdown menu for mobile users
                            <FormControl fullWidth>
                              <Select
                                value={selectedTab}
                                onChange={(event) => handleTabChange(event, event.target.value)}
                                displayEmpty
                              >
                                <MenuItem value={0}>Monday</MenuItem>
                                <MenuItem value={1}>Tuesday</MenuItem>
                                <MenuItem value={2}>Wednesday</MenuItem>
                                <MenuItem value={3}>Thursday</MenuItem>
                                <MenuItem value={4}>Friday</MenuItem>
                                <MenuItem value={5}>Saturday</MenuItem>
                                <MenuItem value={6}>Sunday</MenuItem>
                              </Select>
                            </FormControl>
                          ) : (
                            // Render tabs for non-mobile users
                            <Tabs value={selectedTab} onChange={handleTabChange}>
                              <Tab label="Monday" />
                              <Tab label="Tuesday" />
                              <Tab label="Wednesday" />
                              <Tab label="Thursday" />
                              <Tab label="Friday" />
                              <Tab label="Saturday" />
                              <Tab label="Sunday" />
                            </Tabs>
                          )}
                          {/* {console.log('selectedTab:', selectedTab)}
                          {console.log('dfDailyUsage:', dfDailyUsage)} */}
                          {selectedTab === 0 && <DailyEnergyGraph data={processDailyUsageData(dfDailyUsage)} day="Monday" />}
                          {selectedTab === 1 && <DailyEnergyGraph data={processDailyUsageData(dfDailyUsage)} day="Tuesday" />}
                          {selectedTab === 2 && <DailyEnergyGraph data={processDailyUsageData(dfDailyUsage)} day="Wednesday" />}
                          {selectedTab === 3 && <DailyEnergyGraph data={processDailyUsageData(dfDailyUsage)} day="Thursday" />}
                          {selectedTab === 4 && <DailyEnergyGraph data={processDailyUsageData(dfDailyUsage)} day="Friday" />}
                          {selectedTab === 5 && <DailyEnergyGraph data={processDailyUsageData(dfDailyUsage)} day="Saturday" />}
                          {selectedTab === 6 && <DailyEnergyGraph data={processDailyUsageData(dfDailyUsage)} day="Sunday" />}
                        </CardContent>
                      </Card>
                    )}
                  </CardContent>
                </Card>


                {dfAverageLoadByWeekday && (
                  <Card style={{ marginTop: "20px", backgroundColor: "#DAECF3" }}>
                    <CardContent>
                      <Typography variant="h5" component="h2" style={{ marginBottom: "20px" }}>
                        Average Load By Weekday
                      </Typography>
                      <AverageLoadByWeekdayBarChart data={dfAverageLoadByWeekday} />
                    </CardContent>
                  </Card>
                )}

                {/* {dfDailyUsage && (
                  <Card style={{ marginTop: "20px", backgroundColor: "#DAECF3" }}>
                    <CardContent>
                      <Typography variant="h5" component="h2">
                        Your Weekly Load Profile
                      </Typography>
                      <WeeklyEnergyGraphBarChart data={processDailyUsageData(dfDailyUsage)} />
                    </CardContent>
                  </Card>
                )} */}


                <Card style={{ marginTop: "20px", backgroundColor: "#DAECF3", marginBottom: "100px" }}>
                  <CardContent>
                    <ShareCard url={shareWdigetUrl} title={shareWdigetTitle} />
                  </CardContent>
                </Card>




              </>
            )}

            {/* {dfData && (
                <Card style={{ marginTop: "20px" }}>
                  <CardContent>
                    <Typography variant="h5" component="h2">
                      Line Chart
                    </Typography>
                    <LineChart
                      data={dfData.values.map((row) => ({
                        name: row[0],
                        data: row.slice(3),
                      }))}
                    />
                  </CardContent>
                </Card>
              )} */}

            <Dialog open={loadingReport} aria-labelledby="report-dialog-title" fullWidth maxWidth="md">
              <DialogContent style={{ padding: '20px', backgroundColor: 'rgb(218, 236, 243)' }}>
                <Typography variant="h5" gutterBottom>Here's a quick summary of your electricity usage</Typography>

                <Typography variant="body1" component="div" style={{ marginTop: '20px' }}>
                  {/* List the summary details with delay */}
                  <ul style={{ listStyleType: 'circle', paddingInlineStart: '20px' }}>
                    {typedLines.slice(0, currentLine).map((line, index) => (
                      <li key={index} style={{ marginBottom: '10px' }}>
                        <strong>{line}</strong>
                      </li>
                    ))}
                  </ul>

                  {/* Spinner and "Your report is being prepared" message */}
                  {showReportReadyLoading && (
                    <div style={{ textAlign: 'center', marginTop: '20px' }}>
                      <CircularProgress />
                      <Typography variant="body1" component="div" style={{ marginTop: '10px' }}>
                        Your report is being prepared!
                      </Typography>
                    </div>
                  )}

                  {/* Show the button only after both processes are completed */}
                  {showReportReadyButton && (
                    <div style={{ display: 'flex', justifyContent: 'center', width: '100%', marginTop: '20px' }}>
                      <Button onClick={handleClose} color="primary" variant="contained" style={{ backgroundColor: '#FE424D' }}>
                        View my savings!
                      </Button>
                    </div>
                  )}
                </Typography>
              </DialogContent>
            </Dialog>

            {/* loadingReportNewSubscriber */}

            <Dialog open={loadingReportNewSubscriber} aria-labelledby="report-dialog-title" fullWidth maxWidth="md">
              <DialogContent style={{ padding: '20px', backgroundColor: 'rgb(218, 236, 243)' }}>
                <Typography variant="h5" gutterBottom>Please wait while we prepare your free report ...</Typography>

                <Typography variant="body1" component="div" style={{ marginTop: '20px' }}>
                  {/* List the summary details with delay */}
                  <ul style={{ listStyleType: 'circle', paddingInlineStart: '20px' }}>
                    {typedLines.slice(0, currentLine).map((line, index) => (
                      <li key={index} style={{ marginBottom: '10px' }}>
                        <strong>{line}</strong>
                      </li>
                    ))}
                  </ul>

                  {/* Spinner and "Your report is being prepared" message */}
                  <div style={{ textAlign: 'center', marginTop: '20px' }}>
                    <CircularProgress />
                    {/* <Typography variant="body1" component="div" style={{ marginTop: '10px' }}>
            Your report is being prepared!
          </Typography> */}
                  </div>
                </Typography>
              </DialogContent>
            </Dialog>




            <Dialog
              open={dialogOpen}
              onClose={() => setDialogOpen(false)}
            >
              <DialogTitle style={{ backgroundColor: "#DAECF3" }}>
                {"Welcome Back!"}
              </DialogTitle>
              <DialogContent style={{ backgroundColor: "#DAECF3" }}>  {/* Set background color here */}
                <DialogContentText>
                  {"We have loaded your results from the last time you logged in. If you would like to upload a new smart meter file form ESB Networks, you can do so in Section 3 of this page."}
                </DialogContentText>
              </DialogContent>
              <DialogActions style={{ backgroundColor: "#DAECF3" }}>
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#FE424D", color: "white" }}
                  onClick={() => setDialogOpen(false)}
                >
                  Continue
                </Button>
              </DialogActions>
            </Dialog>

            <Dialog
              open={fileErrorDialogOpen}
              onClose={() => setFileErrorDialogOpen(false)}
            >
              <DialogTitle style={{ backgroundColor: "#DAECF3" }}>
                {"Your file is not valid!"}
              </DialogTitle>
              <DialogContent style={{ backgroundColor: "#DAECF3" }}>
                <DialogContentText style={{ marginBottom: '20px' }}>
                  {"Do not worry, this often happens when you have opened the file and excel has saved and corrupted it or it is the file from your energy provider not the ESB."}
                </DialogContentText>
                <DialogContentText style={{ marginBottom: '20px' }}>
                  {"Please visit the "}
                  <a
                    href="https://www.esbnetworks.ie/"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      color: '#FE424D', // Set the color directly here
                      textDecoration: 'none' // Optional, removes the underline
                    }}
                  >
                    www.esbnetworks.ie
                  </a>
                  {" and download a new file. Upload it here without opening and you should have no issue."}
                </DialogContentText>
                <DialogContentText style={{ marginBottom: '20px' }}>
                  {"If you continue to have an issue please contact "}
                  <span style={{ fontWeight: 'bold' }}>team@reducemybill.ie</span>
                  {". Thank you!"}
                </DialogContentText>
              </DialogContent>
              <DialogActions style={{ backgroundColor: "#DAECF3", justifyContent: "center" }}>
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#FE424D", color: "white" }}
                  onClick={() => {
                    setFileErrorDialogOpen(false);
                    handlePageChange('howToGuide'); // This will close the dialog and change the page
                  }}
                >
                  Take me to the Help Guide
                </Button>
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#FE424D", color: "white" }}
                  onClick={() => setFileErrorDialogOpen(false)}
                >
                  Close
                </Button>
              </DialogActions>
            </Dialog>


            <Dialog
              open={jwtExpiryDialogOpen}
              onClose={() => setJwtExpiryDialogOpen(false)}
            >
              <DialogTitle style={{ backgroundColor: "#DAECF3" }}>
                {"Your Web Session Has Expired!"}
              </DialogTitle>
              <DialogContent style={{ backgroundColor: "#DAECF3" }}>  {/* Set background color here */}
                <DialogContentText>
                  {"To continue using PowerToCompare.ie please log in again. Thank you."}
                </DialogContentText>
              </DialogContent>
              <DialogActions style={{ backgroundColor: "#DAECF3" }}>
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#FE424D", color: "white" }}
                  onClick={() => {
                    // Close the dialog
                    setJwtExpiryDialogOpen(false);
                    // Redirect to the login page
                    window.location.href = '/';
                  }}
                >
                  Log me back in!
                </Button>
              </DialogActions>
            </Dialog>

            <Dialog open={showNewPlanDialog} onClose={() => setShowNewPlanDialog(false)}>
              <DialogTitle style={{ backgroundColor: "#DAECF3" }}>
                {"New Supplier & Plan Selected"}
              </DialogTitle>
              <DialogContent style={{ backgroundColor: "#DAECF3" }}>
                <DialogContentText>
                  {"You have re-selected your current supplier and plan. Do you want us to crunch the numbers again to see your updated savings?"}
                </DialogContentText>
              </DialogContent>
              <DialogActions style={{ backgroundColor: "#DAECF3" }}>
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#FE424D", color: "white" }}
                  onClick={handleGenerateReportFromDialog}
                >
                  View Your Savings
                </Button>
                <Button
                  onClick={() => setShowNewPlanDialog(false)}
                >
                  Cancel
                </Button>
              </DialogActions>
            </Dialog>

          </div>
        )}

        {/* end of default view */}

      </div>
    );
  }

  // =============================================
  //
  // EV COST CALCULATOR VARIABLES  
  //
  // =============================================


  const [currentStep, setCurrentStepEVPage] = useState(0); // default is the first step

  const goToNextStep = () => {
    setCurrentStepEVPage((prevStep) => prevStep + 1);
  };

  const goToPreviousStep = () => {
    setCurrentStepEVPage((prevStep) => prevStep - 1);
  };


  function renderEVPage() {

    return (
      <div className="card-group-container" style={{ paddingTop: '90px' }}>
        <Card style={{ marginBottom: "20px", position: "relative", backgroundColor: "#DAECF3" }}>
          <div
            style={{
              position: "absolute",
              top: "10px",
              left: "10px",
              width: "50px",
              height: "50px",
              border: "5px solid #FE424D",
              borderRadius: "50%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: "#E74B18",
              fontSize: "24px",
            }}
          >
            EV
          </div>
          <CardContent style={{ marginTop: "60px", marginLeft: isMobile ? "0" : "80px" }}>
            <Typography variant="h6" component="h2">
              EV Cost Calculator
            </Typography>
            <Typography variant="body1" component="p" sx={{ fontFamily: 'sans-serif' }}>
              This feature allows you to select any electric vehicle (EV) on the market and work out the costs on your current plan versus other plans.
            </Typography>
            <div style={{ display: "flex", flexDirection: isMobile ? "column" : "row", alignItems: "center", justifyContent: "center", marginTop: "60px" }}>
              <Button
                variant="contained"
                style={{ backgroundColor: "#022D41", color: "white" }}
                onClick={goToNextStep} // Move to the next card when this button is clicked
              >
                Begin
              </Button>
            </div>
          </CardContent>
        </Card>

        {currentStep >= 1 && (
          <Card style={{ marginBottom: "20px", position: "relative", backgroundColor: "#DAECF3" }}>
            <div
              style={{
                position: "absolute",
                top: "10px",
                left: "10px",
                width: "50px",
                height: "50px",
                border: "5px solid #FE424D",
                borderRadius: "50%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: "#FE424D",
                fontSize: "24px",
              }}
            >
              1
            </div>
            <CardContent style={{ marginTop: "60px", marginLeft: isMobile ? "0" : "80px" }}>
              <Typography variant="h6" component="h2">
                Select your current energy plan
              </Typography>
              <Typography variant="subtitle1" component="p">
                Select an energy supplier and smart meter plan to compare costs.
              </Typography>
              <div
                style={{
                  display: "flex",
                  marginBottom: "10px",
                  marginTop: "40px",
                  flexDirection: isMobile ? "column" : "row", // Change to "column" on mobile screens
                }}
              >
                <FormControl fullWidth variant="outlined" style={{ marginRight: isMobile ? "0" : "10px", marginBottom: isMobile ? "10px" : "0" }}>
                  <InputLabel
                    sx={{
                      color: selectedSupplier ? "#FE424D" : "rgba(0, 0, 0, 0.54)",
                      "&.Mui-focused": {
                        color: "#FE424D",
                      },
                    }}
                  >
                    Energy Supplier
                  </InputLabel>
                  <Select
                    value={selectedSupplier}
                    label="Energy Supplier"
                    onChange={handleSupplierChange}
                    sx={{
                      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#FE424D",
                      },
                      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
                        color: "#FE424D",
                      },
                    }}
                  >
                    {suppliers.map((supplier, index) => (
                      <MenuItem key={index} value={supplier}>
                        {supplier}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <FormControl fullWidth variant="outlined" style={{ marginRight: isMobile ? "0" : "10px", marginBottom: isMobile ? "10px" : "0" }}>
                  <InputLabel
                    sx={{
                      color: selectedSupplier ? "#FE424D" : "rgba(0, 0, 0, 0.54)",
                      "&.Mui-focused": {
                        color: "#FE424D",
                      },
                    }}
                  >
                    Smart Meter Plan
                  </InputLabel>
                  <Select
                    value={selectedPlan}
                    onChange={handlePlanChange}
                    label="Smart Meter Plan"
                    disabled={!selectedSupplier}
                    sx={{
                      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#FE424D",
                      },
                      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
                        color: "#FE424D",
                      },
                    }}
                  >
                    {selectedSupplier &&
                      planOptions.map((option, index) => (
                        <MenuItem key={index} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>

                <TextField
                  label="Your current discount"
                  variant="outlined"
                  fullWidth
                  type="number"
                  value={currentDiscount}
                  onChange={(e) => setCurrentDiscount(e.target.value)}
                  disabled={!selectedSupplier}
                  InputProps={{
                    inputProps: { min: 0 },
                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                    sx: {
                      "&.Mui-focused": {
                        color: "#FE424D",
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: selectedSupplier ? "#FE424D" : undefined,
                      },
                    },
                  }}
                  style={{ flexBasis: isMobile ? "100%" : "50%", marginRight: isMobile ? "0" : "10px" }}
                />
              </div>
              <div style={{ display: "flex", flexDirection: isMobile ? "column" : "row", alignItems: "center", justifyContent: "center", marginTop: "60px" }}>
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#022D41", color: "white" }}
                  onClick={goToNextStep} // Move to the next card when this button is clicked
                >
                  Next
                </Button>
              </div>
            </CardContent>
          </Card>

        )}

        {/* EV PAGE CAR CHOICES */}


        {currentStep >= 2 && (
          <Card style={{ marginBottom: "20px", position: "relative", backgroundColor: "#DAECF3" }}>
            <div
              style={{
                position: "absolute",
                top: "10px",
                left: "10px",
                width: "50px",
                height: "50px",
                border: "5px solid #FE424D",
                borderRadius: "50%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: "#FE424D",
                fontSize: "24px",
              }}
            >
              2
            </div>
            <CardContent style={{ marginTop: "60px", marginLeft: isMobile ? "0" : "80px" }}>
              <Typography variant="h6" component="h2">
                Select an electric vehicle(s)
              </Typography>
              <Typography variant="body1" component="p" sx={{ fontFamily: 'sans-serif' }}>
                You can use the following feature to estimate what the likely cost of adding an EV to your house may cost on the different plans from the various energy providers. Simply select your make and model and provide the estimated annual mileage. You can also select the charge power but if you do not know what this is you can leave it at the defauly value.
              </Typography>

              {cars.map((car, index) => (
                <div key={index} style={{ display: "flex", marginBottom: "10px", marginTop: "40px", flexDirection: isMobile ? "column" : "row" }}>
                  <FormControl fullWidth variant="outlined" style={{ flexBasis: isMobile ? "100%" : "25%", marginRight: isMobile ? "0" : "10px", marginBottom: isMobile ? "10px" : "0" }}>
                    <InputLabel>Car Make</InputLabel>
                    <Select
                      value={car.make}
                      onChange={(e) => handleCarChange(index, "make", e.target.value)}
                      label="Car Make"
                    >
                      {Array.from(new Set(carsData.map((car) => car.make))).map((make) => (
                        <MenuItem key={make} value={make}>
                          {make}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl fullWidth variant="outlined" style={{ flexBasis: isMobile ? "100%" : "25%", marginRight: isMobile ? "0" : "10px", marginBottom: isMobile ? "10px" : "0" }}>
                    <InputLabel>Car Model</InputLabel>
                    <Select
                      value={car.model}
                      onChange={(e) => handleCarChange(index, "model", e.target.value)}
                      label="Car Model"
                      disabled={!car.make}
                    >
                      {carsData
                        .filter((c) => c.make === car.make)
                        .map((filteredCar) => (
                          <MenuItem key={filteredCar.model} value={filteredCar.model}>
                            {filteredCar.model}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                  <TextField
                    label="Estimated Annual Mileage"
                    variant="outlined"
                    fullWidth
                    type="number"
                    style={{ flexBasis: isMobile ? "100%" : "25%", marginRight: isMobile ? "0" : "10px", marginBottom: isMobile ? "10px" : "0" }}
                    value={car.mileage}
                    onChange={(e) => handleCarChange(index, "mileage", e.target.value)}
                  />

                  <TextField
                    label="Charger Power"
                    variant="outlined"
                    fullWidth
                    type="number"
                    style={{ flexBasis: isMobile ? "100%" : "25%", marginRight: isMobile ? "0" : "10px", marginBottom: isMobile ? "10px" : "0" }}
                    value={car.chargerPower || 7.2} // setting default value to 7.2 kW
                    onChange={(e) => handleCarChange(index, "chargerPower", e.target.value)}
                  />

                  <TextField
                    label="Charging Efficiency"
                    variant="outlined"
                    fullWidth
                    type="number"
                    style={{ flexBasis: isMobile ? "100%" : "25%", marginRight: isMobile ? "0" : "10px", marginBottom: isMobile ? "10px" : "0" }}
                    value={car.chargingEfficiency ? car.chargingEfficiency : 90}
                    onChange={(e) => handleCarChange(index, "chargingEfficiency", e.target.value)}
                    InputProps={{ endAdornment: <InputAdornment position="end">%</InputAdornment> }}
                  />

                  <TextField
                    label="Car Efficiency"
                    value={car.efficiency ? `${car.efficiency} Wh/km` : ''}
                    InputProps={{
                      readOnly: true,
                    }}
                    variant="outlined"
                    style={{
                      flexBasis: isMobile ? "100%" : "25%",
                      alignSelf: "center",
                      marginRight: isMobile ? "0" : "10px",
                      marginBottom: isMobile ? "10px" : "0",
                    }}
                  />

                  {/* {cars.length > 1 && ( */}
                  <IconButton
                    onClick={() => handleRemoveCar(index)}
                    sx={{ color: "#FE424D" }}
                    style={{ alignSelf: "center", marginLeft: isMobile ? "0" : "10px" }}
                  >
                    <RemoveCircleOutlineIcon />
                  </IconButton>
                  {/* )} */}
                </div>
              ))}

              <IconButton onClick={handleAddCar} sx={{ color: "#FE424D" }}>
                <AddCircleOutlineIcon fontSize="large" />
              </IconButton>
              <div style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
                <Button variant="contained" onClick={handleEVCosts} sx={{
                  bgcolor: '#FE424D',
                  '&:hover': {
                    backgroundColor: '#FE424D', // on hover, the color stays the same
                  },
                }}>
                  Calculate costs
                </Button>
              </div>
            </CardContent>
          </Card>
        )}

        {console.log("evCosts:", evCosts)}

        {evCosts && evCosts.columns && evCosts.columns.length > 0 &&
          <BarChartEVCosts
            data={{ columns: evCosts.columns, values: evCosts.values }}
            // margin={{ top: 5, right: 30, left: 100, bottom: 5 }}
            currentPlan={dfSelectedPlan.selected_plan}
            currentSupplier={dfSelectedPlan.selected_supplier}
            smartTariffs={smartTariffs}
            selectedGraphComparisonTab={selectedGraphComparisonTab}
          />
        }

      </div>
    )
  }



  function renderHowToPage() {
    // Define style objects for different text elements
    const headerStyle = isMobile ? { fontSize: '1.5rem', marginTop: '10px', marginBottom: '10px' } : { fontSize: '2rem', marginTop: '20px' };
    const bodyTextStyle = isMobile ? { fontSize: '1rem', marginBottom: '10px' } : { fontSize: '1.2rem', marginBottom: '10px' };
    const imageStyle = { width: '100%', maxWidth: '1200px', height: 'auto', cursor: 'pointer' };

    return (
      <div className="card-group-container" style={{ paddingTop: '90px' }}>
        <Card style={{ marginBottom: "20px", position: "relative", backgroundColor: "#DAECF3" }}>
          <div
            style={{
              position: "absolute",
              top: "10px",
              left: "10px",
              width: "50px",
              height: "50px",
              border: "5px solid #FE424D",
              borderRadius: "50%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: "#E74B18",
              fontSize: "24px",
            }}
          >
            ?
          </div>
          <CardContent style={{ marginTop: "60px", marginLeft: isMobile ? "0" : "80px" }}>
            <Typography variant="h3" component="h1" style={headerStyle}>
              How do I download my smart meter file from ESB Networks?
            </Typography>
            <Typography variant="body1" component="p" style={bodyTextStyle}>
              In a few simple steps you can download your smart meter file from ESB Networks. Here is how to do this.
            </Typography>

            <Typography variant="h4" component="h1" style={headerStyle}>
              Step 1
            </Typography>
            <Typography variant="body1" component="p" style={bodyTextStyle}>
              Visit <a href="https://www.esbnetworks.ie" target="_blank" rel="noopener noreferrer" style={{ color: 'black', fontWeight: 'bold' }}>www.esbnetworks.ie</a>
            </Typography>
            <img
              src={how_to_step_1_screen}
              alt="Step 1"
              style={imageStyle}
            />

            <Typography variant="h4" component="h1" style={headerStyle}>
              Step 2
            </Typography>
            <Typography variant="body1" component="p" style={bodyTextStyle}>
              If you have an account <b>log in</b>. If you do not, follow the simple steps to create your account for ESB Networks.
            </Typography>
            <img
              src={how_to_step_2_screen}
              alt="Step 2"
              style={imageStyle}
            />

            <Typography variant="h4" component="h1" style={headerStyle}>
              Step 3
            </Typography>
            <Typography variant="body1" component="p" style={bodyTextStyle}>
              Once you are logged in, select the <b>My Energy Consumption</b> option from the homepage.
            </Typography>
            <img
              src={how_to_step_3_screen}
              alt="Step 3"
              style={imageStyle}
            />

            <Typography variant="h4" component="h1" style={headerStyle}>
              Step 4
            </Typography>
            <Typography variant="body1" component="p" style={bodyTextStyle}>
              On the My Energy Consumption page, select the <b>Downloads</b> tab at the top of the page to go to the downloads page.
            </Typography>
            <img
              src={how_to_step_4_new_screen}
              alt="Step 4"
              style={imageStyle}
            />

            <Typography variant="h4" component="h1" style={headerStyle}>
              Step 5
            </Typography>
            <Typography variant="body1" component="p" style={bodyTextStyle}>
              Once on the Downloads panel, select the first <b>Download (CSV)</b> option on this page ( <b>30-minute readings in kW</b> ).
            </Typography>
            <img
              src={how_to_step_4_new_b_screen}
              alt="Step 4"
              style={imageStyle}
            />

            <Typography variant="h5" component="h1" style={headerStyle}>
              Now upload this file and find out if you can save and how much each year.
            </Typography>
            <img
              src={how_to_step_5_screen}
              alt="Final Step"
              style={imageStyle}
            />
          </CardContent>
        </Card>
      </div>
    );
  }

  // New state for managing invoices and loading state
  const [invoices, setInvoices] = useState([]);
  const [loadingInvoices, setLoadingInvoices] = useState(false); // Updated to loadingInvoices
  const [showInvoices, setShowInvoices] = useState(false); // State to control the visibility of invoices

  const fetchInvoices = async () => {
    setLoadingInvoices(true); // Use updated state name
    try {
      const token = localStorage.getItem('token'); // Assuming token is stored in localStorage
      const response = await fetch(`${ApiUrl}/api/get-invoices`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      if (!response.ok) throw new Error('Network response was not ok');
      const data = await response.json();
      setInvoices(data.invoices);
      setShowInvoices(true); // Set to true to display invoices and hide the button
    } catch (error) {
      console.error('Error fetching invoices:', error);
    } finally {
      setLoadingInvoices(false); // Use updated state name
    }
  };

  // Render function for invoices
  const renderInvoices = () => (
    <List>
      {invoices.map((invoice, index) => (
        <ListItem key={index}>
          <ListItemText
            primary={`Invoice #${invoice.number}`}
            secondary={`Date: ${new Date(invoice.date * 1000).toLocaleDateString('en-US')}`}
          />
          <Button variant="outlined" href={invoice.pdf_url} target="_blank">
            Download Invoice
          </Button>
        </ListItem>
      ))}
    </List>
  );

  function renderAccountPage() {
    // Define style objects for different text elements
    const headerStyle = isMobile ? { fontSize: '1.5rem', marginTop: '10px', marginBottom: '10px' } : { fontSize: '2rem', marginTop: '20px' };
    const bodyTextStyle = isMobile ? { fontSize: '1rem', marginBottom: '10px' } : { fontSize: '1.2rem', marginBottom: '10px' };
    const imageStyle = { width: '100%', maxWidth: '1200px', height: 'auto', cursor: 'pointer' };
    const chipStyles = {
      tertiary: {
        backgroundColor: '#FFD700', // Example: Gold color for background
        color: '#000000' // Example: Black color for text
      },
      success: {
        backgroundColor: '#A0F5D1', // Example: Green color for background
        color: '#000000' // Example: White color for text
      }
    };

    function renderAccountDetails() {
      return (
        <>
          <Grid container spacing={2} alignItems="center" style={{ ...bodyTextStyle, marginTop: '20px', marginBottom: '20px' }}>
            {/* Account Email */}
            <Grid item xs={12} sm={2}>
              <Typography variant="body1" component="p" style={{ fontWeight: 'bold' }}>Username:</Typography>
            </Grid>
            <Grid item xs={12} sm={2}>
              <Typography variant="body1" component="p">{subscriptionUsername}</Typography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <Typography variant="body1" component="p">
                {/* (<Link href="/change-password" style={{ textDecoration: 'none' }}>Change Email</Link>) */}
              </Typography>
            </Grid>

            {/* Current Password (Placeholder) */}
            <Grid item xs={6} sm={2}>
              <Typography variant="body1" component="p" style={{ fontWeight: 'bold' }}>Current Password:</Typography>
            </Grid>
            <Grid item xs={6} sm={2}>
              <Typography variant="body1" component="p">********</Typography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <Typography variant="body1" component="p">
                {/* (<Link href="/change-password" style={{ textDecoration: 'none' }}>Change Password</Link>) */}
              </Typography>
            </Grid>

            <Grid item xs={6} sm={2}>
              <Typography variant="body1" component="p" style={{ fontWeight: 'bold' }}>Account Plan:</Typography>
            </Grid>
            <Grid item xs={6} sm={2}>
              <Typography variant="body1" component="p">
                You are on the <Chip
                  label="Early Bird"
                  style={subscriptionType === 'free' ? chipStyles.tertiary : chipStyles.success}
                  size="medium"
                /> plan.
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <Typography variant="body1" component="p">
                {/* (<Link href="/change-password" style={{ textDecoration: 'none' }}>Change Password</Link>) */}
              </Typography>
            </Grid>
          </Grid>
        </>
      );
    }

    function renderSubscriptionInfo() {
      const planLabel = subscriptionType === 'free' ? 'FREE' : 'Annual';

      const formatDate = (dateString) => {
        if (!dateString) return '';
        const date = new Date(dateString);
        return date.toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' });
      };

      return (
        <>
          <Grid container spacing={2} alignItems="center" style={{ ...bodyTextStyle, marginTop: '20px', marginBottom: '20px' }}>
            {/* Current Plan */}
            <Grid item xs={12} sm={2}>
              <Typography variant="body1" component="p" style={{ fontWeight: 'bold' }}>Current Plan:</Typography>
            </Grid>
            <Grid item xs={12} sm={10}>
              <Typography variant="body1" component="p">
                You are on the <Chip
                  label={planLabel}
                  style={subscriptionType === 'free' ? chipStyles.tertiary : chipStyles.success}
                  size="medium"
                /> plan.

              </Typography>
            </Grid>

            {/* Account Creation Date */}
            <Grid item xs={12} sm={2}>
              <Typography variant="body1" component="p" style={{ fontWeight: 'bold' }}>Account Holder Since:</Typography>
            </Grid>
            <Grid item xs={12} sm={10}>
              <Typography variant="body1" component="p">{formatDate(subscriptionAccountCreationDate)}</Typography>
            </Grid>

            {/* Conditional Rendering for Subscription Start and End Dates */}
            {subscriptionType !== 'free' && (
              <>
                <Grid item xs={12} sm={2}>
                  <Typography variant="body1" component="p" style={{ fontWeight: 'bold' }}>Subscription Start Date:</Typography>
                </Grid>
                <Grid item xs={12} sm={10}>
                  <Typography variant="body1" component="p">{formatDate(subscriptionPlanStartDate)}</Typography>
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography variant="body1" component="p" style={{ fontWeight: 'bold' }}>Subscription End Date:</Typography>
                </Grid>
                <Grid item xs={12} sm={10}>
                  <Typography variant="body1" component="p">{formatDate(subscriptionPlanEndDate)}</Typography>
                </Grid>
                {/* Automatic Renewal Information */}
                <Grid item xs={12}>
                  <Typography variant="body1" component="p">Your subscription is set to automatically renew on this {formatDate(subscriptionPlanEndDate)}.</Typography>
                </Grid>
              </>
            )}
          </Grid>
        </>
      );
    }




    function renderCommunicationPreferences() {



      return (
        <>
          <Typography variant="h2" component="h1" style={headerStyle}>
            Communication Preferences
          </Typography>
          <Grid container spacing={2} alignItems="center" style={{ ...bodyTextStyle, marginTop: '20px', marginBottom: '20px' }}>
            {/* Placeholder for a communication preference */}
            <Grid item xs={12} sm={2}>
              <Typography variant="body1" component="p" style={{ fontWeight: 'bold' }}>Email Newsletter:</Typography>
            </Grid>
            <Grid item xs={12} sm={10}>
              <FormControl component="fieldset">
                <RadioGroup row aria-label="communicationsPreference1" name="communicationsPreference1" value={communicationsPreference1} onChange={handleCommunicationsPreference1Change}>
                  <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                  <FormControlLabel value="no" control={<Radio />} label="No" />
                </RadioGroup>
              </FormControl>
            </Grid>

            {/* Another placeholder */}
            <Grid item xs={12} sm={2}>
              <Typography variant="body1" component="p" style={{ fontWeight: 'bold' }}>Plan Price Notifications:</Typography>
            </Grid>
            <Grid item xs={12} sm={10}>
              <FormControl component="fieldset">
                <RadioGroup row aria-label="communicationsPreference2" name="communicationsPreference2" value={communicationsPreference2} onChange={handleCommunicationsPreference2Change}>
                  <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                  <FormControlLabel value="no" control={<Radio />} label="No" />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
        </>
      );
    }

    function renderDeleteAccountSection() {
      return (
        <>
          <Typography variant="h2" component="h1" style={headerStyle}>
            Delete Your Account
          </Typography>
          <Grid container spacing={2} alignItems="center" style={{ ...bodyTextStyle, marginTop: '20px', marginBottom: '20px' }}>
            <Grid item xs={12}>
              <Typography variant="body1" component="p">
                Begin the process of deleting your account.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Button variant="contained" style={{ backgroundColor: 'navy', color: 'white' }} href="/delete-account">
                Get Started
              </Button>
            </Grid>
          </Grid>
        </>
      );
    }

    return (
      <div className="card-group-container" style={{ paddingTop: '90px' }}>
        <Card style={{ marginBottom: "20px", position: "relative", backgroundColor: "#DAECF3" }}>
          <div
            style={{
              position: "absolute",
              top: "10px",
              left: "10px",
              width: "50px",
              height: "50px",
              border: "5px solid #FE424D",
              borderRadius: "50%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: "#E74B18",
              fontSize: "24px",
            }}
          >
            <AccountCircleIcon style={{ fontSize: "24px" }} />
          </div>

          <CardContent style={{ marginTop: "60px", marginLeft: isMobile ? "0" : "80px", marginRight: isMobile ? "0" : "80px" }}>
            <Typography variant="h3" component="h1" style={{ marginBottom: "30px" }}>
              Your Account
            </Typography>
            <hr />
            {/* <Typography variant="body1" component="p" style={bodyTextStyle}>
              In a few simple steps you can download your smart meter file from ESB Networks. Here is how to do this.
            </Typography> */}
            <Typography variant="h2" component="h1" style={headerStyle}>
              Login Details
            </Typography>
            {renderAccountDetails()}
            <hr style={{ marginBottom: "30px" }} />
            {/* Include the PropertyDetails component */}
            <Typography variant="h2" component="h1" style={headerStyle}>
              Questionnaire Details
            </Typography>
            {token && <PropertyDetails ApiUrl={ApiUrl} token={token} />}
            <hr style={{ marginBottom: "30px" }} />
            {/* <Typography variant="h2" component="h1" style={headerStyle}>
              Subscription Details
            </Typography>
            {renderSubscriptionInfo()}
            <hr style={{ marginBottom: "30px" }} />
            <Typography variant="h2" component="h1" style={headerStyle}>
              Payment History
            </Typography> */}
            {/* <Suspense fallback={<div>Loading Payment History...</div>}>
              <RenderSubscriptionPaymentHistory ApiUrl={ApiUrl} token={localStorage.getItem('token')} />
            </Suspense> */}

            {/* {!showInvoices && (
              <Button onClick={fetchInvoices} variant="contained" color="primary">
                View Invoices
              </Button>
            )}
            {loadingInvoices && <div>Loading...</div>}
            {showInvoices && renderInvoices()}

            <hr style={{ marginBottom: "30px" }} /> */}
            {renderCommunicationPreferences()}
            <hr style={{ marginBottom: "30px" }} />
            {renderDeleteAccountSection()}
          </CardContent>
        </Card>
      </div>
    );
  }



  const navigationItems = [
    { label: "Home", icon: <HomeIcon sx={{ color: 'white' }} />, page: 'homepage' },
    // { label: "Plan Comparison", icon: <CompareIcon sx={{ color: 'white' }} />, page: 'planComparison' },
    // { label: "EV Cost Estimator", icon: <DirectionsCarIcon sx={{ color: 'white' }} />, page: 'ev' },
    { label: "How To Guide", icon: <HelpIcon sx={{ color: 'white' }} />, page: 'howToGuide' },
    { label: "Your Account", icon: <AccountCircleIcon sx={{ color: 'white' }} />, page: 'yourAccount' },
    { label: "Log out", icon: <LogoutIcon sx={{ color: 'white' }} />, action: logout }
  ];

  function logout() {
    // Remove the token from localStorage
    localStorage.removeItem('token');

    // Redirect to home page
    window.location.href = '/';
  }


  return (
    <>
      {/* Show MiniDrawer on medium and up screens */}
      <Hidden mdDown implementation="css">
        <MiniDrawer
          onNavigation={handlePageChange}
          open={open}
          handleDrawerOpen={handleDrawerOpen}
          handleDrawerClose={handleDrawerClose}
        />
      </Hidden>

      <Hidden lgUp implementation="css">
        <AppBar position="fixed" style={{ backgroundColor: '#022D41' }}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
            >
              <MenuIcon />
            </IconButton>
            <img src={logo} alt="PowerToCompare Logo" style={{ maxWidth: '200px', maxHeight: '40px' }} />
          </Toolbar>
        </AppBar>

        {/* Show responsive drawer on small screens */}
        <Drawer
          anchor="left"
          open={open}
          onClose={handleDrawerClose}
          variant="temporary"
          PaperProps={{ style: { backgroundColor: '#022D41' } }}
        >
          <List>
            {navigationItems.map((item) => (
              <ListItem disablePadding key={item.label}>
                <ListItemButton onClick={() => {
                  if (item.action) {
                    item.action();
                  } else {
                    console.log(`Navigating to: ${item.page}`);
                    handlePageChange(item.page);  // Using handlePageChange directly
                  }
                  handleDrawerClose();  // Close the drawer when a menu item is selected
                }}>
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText primary={item.label} sx={{ color: 'white', fontFamily: 'YOUR_FONT_FAMILY', fontWeight: 'YOUR_FONT_WEIGHT' }} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>

        </Drawer>
      </Hidden>

      {(() => {
        switch (activePage) {
          case 'homepage':
            return renderHomepage();
          case 'ev':
            return renderEVPage();
          case 'howToGuide':
            return renderHowToPage();
          // Add more cases as needed
          case 'yourAccount':
            return renderAccountPage();
          // Add more cases as needed
          default:
            return null;
        }
      })()}
    </>
  );
}

export default Dashboard;
