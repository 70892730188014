import React from "react";
import { BarChart, Bar, CartesianGrid, XAxis, YAxis, Tooltip, ResponsiveContainer, Label } from "recharts";

const MonthlyEnergyUsageBarChart = ({ data }) => {
  // Function to convert month names to numbers for sorting
  const monthToNumber = (monthName) => {
    const date = new Date(`${monthName} 1`);
    return date.getMonth() + 1;
  };

  // Sort data by year and then by month
  const sortedData = data.sort((a, b) => {
    return a.Year === b.Year ? monthToNumber(a.Month) - monthToNumber(b.Month) : a.Year - b.Year;
  });

  // Format the sorted data for the BarChart
  const chartData = sortedData.map(item => ({
    month: `${item.Month} ${item.Year}`,
    energy: item.Energy
  }));

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip" style={{ backgroundColor: '#fff', padding: '5px', border: '1px solid #ccc' }}>
          <p className="label">{`${label} : ${payload[0].value.toFixed(2)} kWh`}</p>
        </div>
      );
    }
    return null;
  };

  return (
    <div style={{ width: "100%", height: 550 }}>
      <ResponsiveContainer>
        <BarChart data={chartData} margin={{ top: 5, right: 20, bottom: 25, left: 25 }}>
          <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
          <XAxis dataKey="month">
            <Label value="Kilowatt hours (kWh)" offset={-15} position="insideBottom" style={{ fill: '#FE424D', fontWeight: "bold"}} />
          </XAxis>
          <YAxis>
            <Label value="Energy Consumed (kWh)" angle={-90} position="insideLeft" style={{ textAnchor: "middle", fontWeight: "bold", fill: '#FE424D' }} />
          </YAxis>
          <Tooltip content={<CustomTooltip />} />
          <Bar dataKey="energy" fill="#FE424D" />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default MonthlyEnergyUsageBarChart;
