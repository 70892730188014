import React from "react";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from "recharts";

// Custom Tooltip Component
const CustomTooltip = ({ active, payload, label, day }) => {
  if (active && payload && payload.length) {
    const time = label; // Assuming 'time' is formatted as 'HH:mm:ss'
    const timeFormatted = time.substring(0, 5); // Trim to 'HH:mm'
    const energy = payload[0].value.toFixed(2); // Two decimal places for energy

    return (
      <div className="custom-tooltip" style={{ backgroundColor: '#fff', padding: '5px', border: '1px solid #ccc' }}>
        <p><strong>{day}</strong></p> {/* Bold the day */}
        <p>Time: {timeFormatted}</p>
        <p>Energy: {energy} kWh</p>
      </div>
    );
  }

  return null;
};

const DailyEnergyGraph = ({ data, day }) => {
  const filteredData = data.filter((entry) => entry?.weekday?.toLowerCase() === day.toLowerCase());

  return (
    <div style={{ width: "100%", height: 500 }}>
      <ResponsiveContainer>
        <BarChart data={filteredData} margin={{ top: 25, right: 30, left: 20, bottom: 5 }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="time" />
          <YAxis label={{ value: 'Energy Consumed (kWh)', angle: -90, position: 'insideLeft', fill: '#1AA6B7' }} />
          <Tooltip content={<CustomTooltip day={day} />} />
          <Legend />
          <Bar dataKey="energy" fill="#1AA6B7" />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default DailyEnergyGraph;
