// ShareCard.js
import React from 'react';
import { Card, CardContent, Typography, Grid } from '@mui/material';
import {
  FacebookShareButton,
  WhatsappShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  EmailShareButton,
  TelegramShareButton,
  FacebookIcon,
  WhatsappIcon,
  TwitterIcon,
  LinkedinIcon,
  EmailIcon,
  TelegramIcon,
} from 'react-share';


const ShareCard = ({ url, title }) => {
  return (
    <Card  sx={{ margin: 2 }}>
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          Share with your friends! 
        </Typography>
        <Grid container spacing={2}>
        <Grid item>
            <WhatsappShareButton url={url} title={title}>
              <WhatsappIcon size={40} round />
            </WhatsappShareButton>
          </Grid>
          <Grid item>
            <FacebookShareButton url={url} quote={title}>
              <FacebookIcon size={40} round />
            </FacebookShareButton>
          </Grid>
          
          <Grid item>
            <TwitterShareButton url={url} title={title}>
              <TwitterIcon size={40} round />
            </TwitterShareButton>
          </Grid>
          <Grid item>
            <LinkedinShareButton url={url}>
              <LinkedinIcon size={40} round />
            </LinkedinShareButton>
          </Grid>
          <Grid item>
            <EmailShareButton url={url}>
              <EmailIcon size={40} round />
            </EmailShareButton>
          </Grid>
          <Grid item>
            <TelegramShareButton url={url}>
              <TelegramIcon size={40} round />
            </TelegramShareButton>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ShareCard;
