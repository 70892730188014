import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { loadStripe } from '@stripe/stripe-js';
import { CardElement, Elements, useStripe, useElements } from '@stripe/react-stripe-js';
import { Button, Card, CardContent, TextField, Typography } from '@mui/material';
import CardInput from './CardInput';
import ApiUrl from "./ApiConfig";

const useStyles = makeStyles({
    root: {
      maxWidth: 500,
      margin: '35vh auto',
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      alignContent: 'flex-start',
    },
    div: {
      display: 'flex',
      flexDirection: 'row',
      alignContent: 'flex-start',
      justifyContent: 'space-between',
    },
    button: {
      margin: '2em auto 1em',
    },
  });

// Load your publishable key from Stripe
const stripePromise = loadStripe('pk_test_51OP6TBCcR6ZIEKhElhlXlrbAakPYWlCXrokCs8o5ah0GM5jV1W7Vupk2v6ny42Hx6mCSe4aMGJACM18Nf49fXCMJ00Y7ublAzy');

const CheckoutForm = () => {

    const classes = useStyles();

    // State
    const [email, setEmail] = useState('');
    const [status, setStatus] = useState('');
    const [clientSecret, setClientSecret] = useState('');
    const [coupon, setCoupon] = useState('');

    const stripe = useStripe();
    const elements = useElements();

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!stripe || !elements) {
            return;
        }

        const cardElement = elements.getElement(CardElement);
        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
        });

        if (error) {
            console.log('[error]', error);
        } else {
            console.log('[PaymentMethod]', paymentMethod);
            // TODO: send paymentMethod.id and coupon code to your server for processing
        }
    };

    const handleSubmitSub = async (event) => {
        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Disable form submission until Stripe.js has loaded.
            return;
        }

        const userToken = localStorage.getItem('token'); // Retrieve the JWT token from local storage

        if (!userToken) {
            console.error('User token not found');
            return;
        }

        if (status !== '') {
            // ... existing Stripe payment confirmation logic ...
        } else {
            const result = await stripe.createPaymentMethod({
                type: 'card',
                card: elements.getElement(CardElement),
                billing_details: {
                    email: email,
                },
            });

            if (result.error) {
                console.log(result.error.message);
                // Show error in payment form
            } else {
                const payload = {
                    email: email,
                    payment_method: result.paymentMethod.id,
                };
                // Send paymentMethod.id to your server
                try {
                    const response = await fetch(`${ApiUrl}/sub`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${userToken}`,
                        },
                        body: JSON.stringify(payload),
                    });

                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }

                    const data = await response.json();
                    const { client_secret, status } = data;

                    if (status === 'requires_action') {
                        setStatus(status);
                        setClientSecret(client_secret);
                        stripe.confirmCardPayment(client_secret).then(function (result) {
                            if (result.error) {
                                // Display error message in your UI.
                                console.log(result.error.message);
                            } else {
                                // Show a success message to your customer
                                console.log('Hell yea, you got that sub money!');
                            }
                        });
                    } else {
                        console.log('Hell yea, you got that sub money!');
                    }
                } catch (error) {
                    console.error('Error:', error);
                }
            }
        }
    };


    return (
        <Card >
            <CardContent>
                <TextField
                    label='Email'
                    id='outlined-email-input'
                    helperText={`Email you'll recive updates and receipts on`}
                    margin='normal'
                    variant='outlined'
                    type='email'
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    fullWidth
                />
                <CardInput />
                <div className={classes.div}>
                    <Button variant="contained" color="primary" className={classes.button} onClick={handleSubmitSub}>
                        Pay
                    </Button>
                    <Button variant="contained" color="primary" className={classes.button} onClick={handleSubmitSub}>
                        Subscription
                    </Button>
                </div>
            </CardContent>
        </Card>
    );
};

const StripePaymentForm = () => {
    return (
        <Elements stripe={stripePromise}>
            <CheckoutForm />
        </Elements>
    );
};

export default StripePaymentForm;
