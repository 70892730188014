import React from 'react';
import StripePaymentForm from './StripePaymentForm';
import { styled } from '@mui/system';
import { Paper, Typography, Container } from '@mui/material';

import logo from './images/logo_white_large.png';

const Background = styled('div')({
  backgroundColor: '#DAECF3',
  height: '100vh',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  elevation: 3,
}));

const Subscribe = () => {
  return (
    <Background>
      <Container component="main" maxWidth="sm">
        <StyledPaper>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <img src={logo} alt="Logo" style={{ width: '307px', height: '44px', marginTop: '26px' }} />
            <Typography component="h1" variant="h5" style={{ marginTop: '26px' }}>
              Subscribe
            </Typography>
          </div>
          <StripePaymentForm />
        </StyledPaper>
      </Container>
    </Background>
  );
};

export default Subscribe;
