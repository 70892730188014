import React, { useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { TextField, Button, Card, CardContent, Typography, Container, Grid, Alert } from '@mui/material';
import ApiUrl from './ApiConfig';
import logo from './images/title4.png'; // import the logo image

const UpdatePassword = () => {
  const [newPassword, setNewPassword] = useState('');
  const [repeatNewPassword, setRepeatNewPassword] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const { token } = useParams();

  const checkTokenExpiry = async () => {
    try {
      const response = await fetch(`${ApiUrl}/api/token-expiry`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token }),
      });
      return response.ok;
    } catch (error) {
      console.error('Error:', error);
      return false;
    }
  };

  const updatePassword = async () => {
    try {
      const response = await fetch(`${ApiUrl}/api/update-password`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token, newPassword }),
      });
      return response.ok;
    } catch (error) {
      console.error('Error:', error);
      return false;
    }
  };

  const handlePasswordUpdate = async (event) => {
    event.preventDefault();
    setMessage('');
    setError('');

    if (newPassword !== repeatNewPassword) {
      setError('Passwords do not match.');
      return;
    }

    if (await checkTokenExpiry()) {
      if (await updatePassword()) {
        setMessage('Your password has been updated. Click here to sign in.');
      } else {
        setError('Failed to update password.');
      }
    } else {
      setError('Your reset link has expired. Please request a new reset link from the forgotten password page.');
    }
  };

  return (
    <>
    <header className="header">
        <div className="logo-container" style={{ display: "flex", justifyContent: "center" }}>
          <img src={logo} alt="Logo" className="logo" />
        </div>
    </header>
    <Container maxWidth="sm">
      <Card style={{ marginTop: "20px", backgroundColor: "#DAECF3" }}>
        <CardContent>
          <Typography variant="h5" style={{ marginBottom: "20px", textAlign:"center" }}>Update Password</Typography>
          <form onSubmit={handlePasswordUpdate}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  label="New Password"
                  type="password"
                  fullWidth
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Repeat New Password"
                  type="password"
                  fullWidth
                  value={repeatNewPassword}
                  onChange={(e) => setRepeatNewPassword(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  type="submit"
                  variant="contained"
                  fullWidth
                  sx={{ backgroundColor: "#FE424D", color: "white" }}
                >
                  Update Password
                </Button>
              </Grid>
            </Grid>
            {message && <Alert severity="success" style={{ marginTop: "20px" }}>
              {message} <Link to="/">Sign In</Link>
            </Alert>}
            {error && <Alert severity="error" style={{ marginTop: "20px" }}>{error}</Alert>}
          </form>
        </CardContent>
      </Card>
    </Container>
    </>
  );
};

export default UpdatePassword;
