import React, { useEffect, useState } from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

// Custom tooltip formatter
const tooltipFormatter = (value, name, props) => {
  let label = name;
  let formattedValue = value;

  if (name === 'currentPlanValue') {
    label = `${props.payload.currentSupplierPlanName}`;
  } else if (name === 'selectedPlanValue') {
    label = `${props.payload.selectedSupplierPlanName}`;
  }

  // Ensure the value is a number and format it to two decimal places
  if (!isNaN(parseFloat(value))) {
    formattedValue = parseFloat(value).toFixed(2);
  }

  return [formattedValue, label];
};

const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];


const formatXAxis = (tickItem, index) => {
  // Define the index that corresponds to the middle of each day (12:00 PM)
  const middayIndex = 24;

  // Calculate the index for the start of each day
  const dayStartIndex = Math.floor(index / 48) * 48;

  // Check if the current index is at the middle of a day
  if (index - dayStartIndex === middayIndex) {
    // Calculate the day of the week
    const dayOfWeek = days[Math.floor(index / 48) % 7];
    return dayOfWeek;
  }

  return '';
};

const LineChartWeekPriceScheduleAllPlans = ({ weekPriceVsUsageAllPlans, currentSupplier, currentPlan, selectedPlan }) => {
  const [formattedData, setFormattedData] = useState([]);

  useEffect(() => {
    if (weekPriceVsUsageAllPlans && currentSupplier && currentPlan) {
      const newData = weekPriceVsUsageAllPlans.map((entry) => {
        return {
          ...entry,
          currentSupplierPlanName: `${currentSupplier} | ${currentPlan} Price`,
          selectedSupplierPlanName: selectedPlan ? `${selectedPlan} Price` : '',
        };
      });
      setFormattedData(newData);
    }
  }, [weekPriceVsUsageAllPlans, currentSupplier, currentPlan, selectedPlan]);

  return (
    <ResponsiveContainer width="100%" height={400}>
      <LineChart
        data={formattedData}
        margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
      >
        <CartesianGrid stroke="none" />

        <XAxis dataKey="Time" tickFormatter={formatXAxis} />
        <YAxis yAxisId="left" orientation="left" stroke="#FF0000"
            label={{ value: 'Price (cents)', angle: -90, position: 'insideLeft', fill: '#FF0000', offset: 20, dy: 40 }} />
        <YAxis yAxisId="right" orientation="right" stroke="rgb(26, 166, 183)"
            label={{ value: 'Energy used (kWh)', angle: -90, position: 'insideLeft', fill: 'rgb(26, 166, 183)', offset: 50, dy: 140 }} />
        <Tooltip formatter={tooltipFormatter} />
        <Legend />
        <Line yAxisId="left" type="monotone" dataKey={`${currentSupplier} | ${currentPlan}`} stroke="#FF0000" dot={false} />
        <Line yAxisId="right" type="monotone" dataKey="Energy" stroke="rgb(26, 166, 183)" dot={false} />
        {selectedPlan && (
          <Line yAxisId="left" type="monotone" dataKey={selectedPlan} stroke="#8884d8" dot={false} />
        )}
      </LineChart>
    </ResponsiveContainer>
  );
};

export default LineChartWeekPriceScheduleAllPlans;
