import React, { useEffect, useState } from 'react';
import ApiUrl from './ApiConfig';
import {
  AppBar,
  Toolbar,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Pagination,
  Box,
  Container,
  IconButton,
} from '@mui/material';
import LoginIcon from '@mui/icons-material/Login';

function Admin() {
  const [adminData, setAdminData] = useState([]);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);

  useEffect(() => {
    const token = localStorage.getItem('token');

    if (!token) {
      console.error("Admin.js: No token found in localStorage");
      setError("No token found. Please log in.");
      return;
    }

    console.log("Admin.js: Making request to admin endpoint with token:", token);

    fetch(`${ApiUrl}/admin`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
    .then(response => {
      if (!response.ok) {
        console.error(`Admin.js: HTTP error! Status: ${response.status}`);
        setError(`Error fetching admin data: ${response.statusText}`);
        return;
      }
      return response.json();
    })
    .then(data => {
      console.log("Admin.js: Received admin data:", data);
      setAdminData(data);
    })
    .catch(err => {
      console.error("Admin.js: Error fetching admin data:", err);
      setError("Error fetching admin data.");
    });
  }, []);

  // Get current users for pagination
  const indexOfLastUser = currentPage * itemsPerPage;
  const indexOfFirstUser = indexOfLastUser - itemsPerPage;
  const currentUsers = adminData.slice(indexOfFirstUser, indexOfLastUser);

  // Change page
  const paginate = (event, value) => setCurrentPage(value);

  const handleMasquerade = async (userId) => {
    try {
      console.log("Attempting to masquerade as user with ID:", userId); // Log the user ID
      const response = await fetch(`${ApiUrl}/admin/masquerade`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ user_id: userId }),
      });

      if (response.ok) {
        const data = await response.json();
        console.log("Masquerade successful. New token:", data.token); // Log the new token
        localStorage.setItem('token', data.token);  // Store the new token
        window.location.href = '/dashboard';  // Open the user's dashboard in the same window
      } else {
        alert("Failed to log in as user. Please check the console for errors.");
        console.error("Error details:", await response.text());
      }
    } catch (error) {
      console.error("Network or other error during masquerade:", error);
    }
  };

  return (
    <Box sx={{ bgcolor: '#f5f5f5', minHeight: '100vh', paddingTop: 3 }}>
      <Container>
        <AppBar position="static">
          <Toolbar>
            <Typography variant="h6">
              Admin Dashboard
            </Typography>
          </Toolbar>
        </AppBar>
        <Box mt={2}>
          {error && <Typography color="error">{error}</Typography>}
          {!adminData.length && !error && <Typography>Loading...</Typography>}
          {adminData.length > 0 && (
            <>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Username</TableCell>
                      <TableCell>First Name</TableCell>
                      <TableCell>Last Name</TableCell>
                      <TableCell>Created Date</TableCell>
                      <TableCell>Login as User</TableCell> {/* New Column for Login Icon */}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {currentUsers.map(user => (
                      <TableRow key={user._id}>
                        <TableCell>{user.username}</TableCell>
                        <TableCell>{user.first_name}</TableCell>
                        <TableCell>{user.last_name}</TableCell>
                        <TableCell>{new Date(user.created_date).toLocaleString()}</TableCell>
                        <TableCell>
                          <IconButton onClick={() => handleMasquerade(user._id)} aria-label="login as user" color="primary">
                            <LoginIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <Box mt={2} display="flex" justifyContent="center">
                <Pagination
                  count={Math.ceil(adminData.length / itemsPerPage)}
                  page={currentPage}
                  onChange={paginate}
                  color="primary"
                />
              </Box>
            </>
          )}
        </Box>
      </Container>
    </Box>
  );
}

export default Admin;
